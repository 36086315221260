import { axios } from "@/utils/requestCarFinance";

/**
 * 获取公钥
 * @param {*} params
 */

export function getPublickey(params = {}) {
  return axios({
    url: "/publickey.do",
    method: "get",
    params,
  });
}

/**
 * 登录
 * @param {*} data mobile ,pssword
 */

export function login(data) {
  return axios({
    url: "/login.do",
    method: "post",
    data,
  });
}

/**
 * 更新密码
 * @param {*} data
 */
export function updatePassword(data = {}) {
  return axios({
    url: "/updatePassword.do",
    method: "post",
    data,
  });
}

/**
 * 退出登录
 * @param {*} data
 */
export function logout(data = {}) {
  return axios({
    url: "/logout.do",
    method: "post",
    data,
  });
}

/**
 * 首页
 * @param {*} params
 */
export function centerIndex(params = {}) {
  return axios({
    url: "/car/operatorCenter/centerIndex.do",
    method: "get",
    params,
  });
}

/*********** 提交订单页 Start ********* */

/**
 * 获取车商接口
 * @param {*} params
 */
export function getCarDealers(params = {}) {
  return axios({
    url: "/car/operatorCenter/getCarDealers.do",
    method: "get",
    params,
  });
}

/**
 * 继续订单 获取提交详情
 * @param {*} params
 */

export function reSubmitOrder(params = {}) {
  return axios({
    url: "/car/operatorCenter/reSubmitOrder.do",
    method: "get",
    params,
  });
}

/**
 * 提交订单
 * @param {*} data
 */
export function submitOrder(data = {}) {
  return axios({
    url: "/car/operatorCenter/submitOrder.do",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

/*********** 提交订单页 End ********* */

/**
 * 查询下拉框
 * @param {*} params
 */
export function getSelectOption(params = {}) {
  return axios({
    url: "/common/getSelectOption.do",
    method: "get",
    params,
  });
}

/**
 * 继续订单合同详情
 * @param {*} params
 */
export function reSupplementOrder(params = {}) {
  return axios({
    url: "/car/operatorCenter/reSupplementOrder.do",
    method: "get",
    params,
  });
}

/**
 * 补充订单资料
 * @param {*} data
 */
export function supplementOrder(data = {}) {
  return axios({
    url: "/car/operatorCenter/supplementOrder.do",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

/**
 * 统计数据
 * @param {*} params
 */
export function statistics(params = {}) {
  return axios({
    url: "/car/operatorCenter/statistics.do",
    method: "get",
    params,
  });
}

/**
 * 全部订单
 * @param {*} params
 */
export function allOrders(params = {}) {
  return axios({
    url: "/car/operatorCenter/allOrders.do",
    method: "get",
    params,
  });
}

/**
 * 订单详情
 * @param {*} params
 */
export function orderDetail(params = {}) {
  return axios({
    url: "/car/operatorCenter/orderDetail.do",
    method: "get",
    params,
  });
}

/**
 * 请求法大大页面
 *
 * @param data 
 * @returns {*}
 */
export function getFddPersonVerifyUrl(data = {}) {
  return axios({
    url: "/fadada/getPersonVerifyUrl.do",
    method: "post",
    data
  });
}

/**
 * 请求法大大页面
 *
 * @param data 
 * @returns {*}
 */
 export function getAuthUserStatus(data = {}) {
  return axios({
    url: "/fadada/getAuthUserStatus.do",
    method: "post",
    data
  });
}
