<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-09-29 17:07:19
 * @LastEditors: CZK
 * @LastEditTime: 2024-10-11 14:32:41
-->
<template>
  <div class="container">
    <img :src="bg" alt="" class="bg">
    <p class="tips" v-if="type==1">正在进行审批，预计需要{{ms}}秒，请稍等…</p>
    <div v-if="type==2">
      <p class="title">综合评估不符合</p>
      <p class="desc">很遗憾！您本次申请未获得通过。<br/> 请保持良好信用记录，30天后可再次申请。</p>
    </div>

    <div v-if="type==3">
      <p class="title">资料已提交</p>
      <p class="desc">请添加官方企业微信，<br/> 以便核实申请信息以及获取专属服务等</p>
      <img src="../../assets/images/img/code_border.png" class="code"/>
      <div class="btn" @click="back">返回首页</div>
    </div>

    <img src="../../assets/images/img/beian.png" class="beian" :class="{'fid':type<3}"/>
  </div>
</template>
<script>
  export default {
    name: '',
    data () {
      return {
        bg:'',
        type:0,
        ms:60
      }
    },
    methods: {
      countTime(){
        if(this.ms>0){
          this.ms--;
          setTimeout(() => {
            this.countTime()
          }, 1000)
        }
      },
      back(){
        this.$router.replace({name:'xaIndex'})
      }
    },
    created () {
      // let type = 2;
      const{type} = this.$route.query;
      this.title = ['','审核中','审批结果','资料已提交']

      let bg = ['',
        require("../../assets/images/img/watting.png"),
        require("../../assets/images/img/result.png"),
        require("../../assets/images/img/commit.png")
      ]
      this.bg = bg[type]
      this.type = type
      if(type==1) this.countTime()
      document.title = this.title[type]
    }

  }
</script>
<style lang="less" scoped>
.bg{
  width: 100%;
  height: auto;
  object-fit: contain;
}
.tips{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 18px;
  text-align: center;
  margin-top: 20px;
}
.title{
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
  text-align: center;
  margin: 20px auto 17px;
  line-height: 19px;
}
.desc{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 22px;
  text-align: center;
  }
.code{
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 16px auto 0;
  display: block;
}
.btn{
  width: 321px;
  height: 42px;
  background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
  border-radius: 30px 30px 30px 30px;
  margin: 0 auto;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 42px;
  text-align: center;
  margin-top: 44px;
}

.beian{
  width: 198px;
  height: auto;
  display: block;
  margin: 20px auto;
  &.fid{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>