var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "info-faren-main" }, [
      _vm._m(1),
      _c("div", { staticClass: "info-faren-content" }, [
        _c("div", { staticClass: "faren-form" }, [
          _c(
            "div",
            {
              staticClass: "form-item border-bottom-1px r",
              on: { click: _vm.selectTap }
            },
            [
              _c("label", [_vm._v("电商平台")]),
              _c("span", [
                _vm._v(_vm._s(_vm.info.platformName || "请选择电商平台"))
              ])
            ]
          ),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("店铺全称")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.info.name,
                  expression: "info.name"
                }
              ],
              attrs: { type: "text", placeholder: "请填写店铺全称" },
              domProps: { value: _vm.info.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.info, "name", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("店铺ID")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.info.platformShopId,
                  expression: "info.platformShopId"
                }
              ],
              attrs: { type: "text", placeholder: "请填写店铺ID" },
              domProps: { value: _vm.info.platformShopId },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.info, "platformShopId", $event.target.value)
                }
              }
            })
          ]),
          _c(
            "div",
            {
              staticClass: "form-item border-bottom-1px r",
              on: { click: _vm.selectTap1 }
            },
            [
              _c("label", [_vm._v("店铺类型")]),
              _c("span", [
                _vm._v(_vm._s(_vm.info.shopTypeName || "请选择店铺类型"))
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "form-item border-bottom-1px",
              on: { click: _vm.showDatePicker }
            },
            [
              _c("label", [_vm._v("开店时间")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.info.foundTime,
                    expression: "info.foundTime"
                  }
                ],
                attrs: {
                  type: "text",
                  readonly: "",
                  placeholder: "请填写开店时间"
                },
                domProps: { value: _vm.info.foundTime },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.info, "foundTime", $event.target.value)
                  }
                }
              })
            ]
          ),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("主营类目")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.info.businessType,
                  expression: "info.businessType"
                }
              ],
              attrs: { type: "text", placeholder: "请填写主营类目" },
              domProps: { value: _vm.info.businessType },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.info, "businessType", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("店铺主账号ID")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.info.shopMasterAccountId,
                  expression: "info.shopMasterAccountId"
                }
              ],
              attrs: { type: "text", placeholder: "请填写店铺主账号ID" },
              domProps: { value: _vm.info.shopMasterAccountId },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.info, "shopMasterAccountId", $event.target.value)
                }
              }
            })
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "add-btn on", on: { click: _vm.commit } },
        [
          _vm.commiting ? _c("cube-loading") : _vm._e(),
          _c("span", [_vm._v("下一步")])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("店铺信息")]),
      _c("p", { staticClass: "desc" }, [_vm._v("Hi，请填写以下资料吧~")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-faren-title-box" }, [
      _c("p", { staticClass: "info-faren-title" }, [_vm._v("店铺信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }