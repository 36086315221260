<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-10-29 13:58:38
 * @LastEditors: CZK
 * @LastEditTime: 2024-10-30 10:11:59
-->
<template>
  <div class="container" v-if='info'>
    <div class="list-container">
      <div class="item">
        <div class="title"> <img :src="bankImg[info.receivingAccount.bankName]?bankImg[info.receivingAccount.bankName]:bankImg['默认']"/>{{info.receivingAccount.accountName	}}</div>
        <p class="num">{{formatNumber(info.receivingAccount.bankNo)}}</p>
        <p class="desc">{{info.receivingAccount.openBranchName}}</p>
      </div>

      <div class="card" v-for="(item,index) in info.receivedRecordGroupList" :key="index">
        <div class="title border-bottom-1px" v-if="index===0">
          收款记录
        </div>
        <p class="date">{{item.receivedDate	}}</p>

        <div class="card-item border-bottom-1px" v-for="(jtem,jndex) in item.receivedRecordList" :key="jndex" @click="toMenu('transactionDetails',jtem.spbId)">
          <div class="line">
            <span class="title1">{{jtem.sourceName}}</span><span class="val">+{{jtem.payTotal||0}}元<img src="../../assets/images/img/right_icon.png" alt=""></span>
          </div>
          <p class="line-date">{{jtem.successTime}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {bankImg} from "@/utils/bankImg"
import{getReceivingAccountDetail} from '@/api/user'

  export default {
    name: "receivingAccountDetail",
    data() {
      return {
        bankImg,
        info:null
      }
    },
    methods:{
      formatNumber(numStr) {
          return numStr.replace(/^(\d{4})(\d+)(\d{4})$/,"$1" + "*".repeat(numStr.length - 8) + "$3");
      },
      toMenu(name,spbId){
        this.$router.push({name,query:{spbId}})
      },
      async getInfo(){
        const accountId = this.$route.query.accountId
        let res = await getReceivingAccountDetail({token:'',accountId})
        if(res.success) this.info = res.data
      }
    },
    mounted(){
      this.getInfo()
    }
  }
</script>
<style lang="less" scoped>
  .container {
    width: 100%;
    background: #F5F7FB url(../../assets/images/img/apply_bg.png) no-repeat top center;
    .list-container{
      margin:22px auto 0;
      width: 343px;
      .item{
        width: 343px;
        height: 120px;
        background: #FFFFFF;
        box-shadow: 0px 3 8px 0px rgba(51,51,51,0.1);
        border-radius: 8px 8px 8px 8px;
        padding: 14px 24px;
        margin-bottom: 16px;
        .title{
          display: flex;
          align-items:center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          line-height: 20px;

          img{
            width:30px;
            height:30px;
            object-fit: contain;
            margin-right:8px;
          }
        }
        .num{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
          line-height: 23px;
          margin-top:7px;
          letter-spacing: 3px;
        }
        .desc{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          margin-top: 11px;
          line-height: 18px;
        }
      }

      .card{
        width: 343px;
        margin-top: 16px;
        padding: 0 11px;
        background: #FFFFFF;
        box-shadow: 0px 3 8px 0px rgba(35,96,170,0.1);
        border-radius: 8px 8px 8px 8px;
        .title{
          padding:0 6px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          height:46px;
          line-height: 46px;
        }
        .date{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #1C2023;
          line-height: 18px;
          margin-top: 15px;
          margin-left: 6px;
        }
        .card-item{
          width: 100%;
          height: 70px;
          display: flex;
          padding:  0 6px;
          flex-direction:column;
          justify-content: center;
          .line-date{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 18px;
            margin-top:8px;
          }
          .line{
            display:flex;
            justify-content: space-between;
            align-items: center;
            .title1{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
            }
            .val{
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              display:flex;
              align-items:center;
              img{
                width:24px;
                height:24px;
                object-fit:contain;
              }
            }
          }
        }
      }
    }
  }
</style>