var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "flex-1 flex" }, [
      _c("div", { staticClass: "flex-1 help-item-info" }, [
        _c("div", { staticClass: "help-item-title" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/images/icons/question_01.png"),
              alt: ""
            }
          }),
          _vm._v("  " + _vm._s(this.title))
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "content-box" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/images/icons/question_02.png"),
              alt: ""
            }
          }),
          _c("div", {
            staticClass: "help-item-content",
            domProps: { innerHTML: _vm._s(this.content) }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }