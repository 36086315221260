var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container", class: { on: _vm.classType } },
    [
      _c(
        "div",
        { staticClass: "upload-box-main", class: { on: _vm.classType } },
        [
          _c(
            "div",
            { staticClass: "upload-box-btn-main" },
            [
              _c(
                "Upload",
                {
                  ref: "upload",
                  staticClass: "upload-box",
                  attrs: {
                    action: _vm.action,
                    "process-file": _vm.processFile,
                    type: _vm.type,
                    successBg: _vm.successBg
                  },
                  on: {
                    "files-added": _vm.addedHandler,
                    "file-error": _vm.errHandler,
                    "file-success": _vm.successHandler
                  },
                  model: {
                    value: _vm.files,
                    callback: function($$v) {
                      _vm.files = $$v
                    },
                    expression: "files"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "clear-fix upload-box-wrapper" },
                    [
                      _vm._l(_vm.files, function(file, i) {
                        return _c("UploadFile", {
                          key: i,
                          class: [
                            { "multiple-upload-file": _vm.multiple },
                            "upload-box-file"
                          ],
                          attrs: {
                            file: file,
                            type: _vm.type,
                            successBg: _vm.successBg
                          }
                        })
                      }),
                      _c(
                        "UploadBtn",
                        {
                          staticClass: "upload-box-btn",
                          style: {
                            "background-image":
                              "url(" +
                              require("@/assets/images/img/" +
                                _vm.backgroundImgName) +
                              ")",
                            "background-size": "contain"
                          },
                          attrs: {
                            multiple: _vm.multiple,
                            simultaneousUploads: _vm.simultaneousUploads,
                            accept: _vm.accept,
                            capture: _vm.capture
                          }
                        },
                        [_c("div")]
                      )
                    ],
                    2
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "uploading-tips", class: { on: _vm.need == 1 } },
            [_vm._v(_vm._s(_vm.title))]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }