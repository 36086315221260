import axios from "axios";
import router from "@/router";
import store from "@/store";

import {
  transformRequestFn,
  paramsSerializer,
  handleErrorMsg,
} from "@/utils/request";

import { Toast } from "cube-ui";

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000,
  withCredentials: false, // 允许携带cookie   "Content-Type": "application/json;charset=UTF-8"
  transformRequest: [transformRequestFn],
  paramsSerializer,
});

// FIXME 用以维护
const err = (error) => {
  if (error.response) {
    // eslint-disable-next-line
    const statusText = error.response.statusText;

    if (error.response.status === 401 || error.response.status === 403) {
      Toast.$create({
        txt: "登录超时，请重新登录",
        type: "error",
        time: 2200,
      }).show();
      window.localStorage.setItem("token", "");
      store.commit('SET_ISLOGIN', false);
      router.replace({
        name: "login",
      });
    } else if (error.response.status === 404) {
      Toast.$create({
        txt: "404 Not Found",
        type: "error",
        time: 1200,
      }).show();
    } else if (error.response.status === 500) {
      Toast.$create({
        txt: "服务器错误",
        type: "error",
        time: 1200,
      }).show();
    } else {
      let tipText = (statusText=='null'||statusText==null)?'操作失败':statusText
      Toast.$create({
        txt: "error",
        type: `error ${tipText}`,
        time: 1200,
      }).show();
    }
  } else {
    Toast.$create({
      txt: "error",
      type: `error`,
      time: 1200,
    }).show();
  }
  return Promise.reject(error);
};

// request interceptor
service.interceptors.request.use((config) => {
  return config;
}, err);

// response interceptor
service.interceptors.response.use((response) => {
  const res = response.data.response || response.data;

  console.log("repayment ajax 返回 ", res);

  if (res && res.data && (res.data.code == "401" || res.data.code == "553")) {
    window.localStorage.setItem("token", "");
    router.replace({
      name: "login",
    });
    Toast.$create({
      txt: "登录超时，请重新登录",
      type: `warn`,
      time: 3000,
    }).show();

    return;
  }

  if (res.success === true) {
    return res;
  } else if (res.success === false) {
    Toast.$create({
      txt: handleErrorMsg(res),
      type: "warn",
      time: 4000,
    }).show();
    return res;
  } else {
    // TODO 图片流 文件流捕获
    if (res instanceof Blob) {
      return res;
    }
    return Promise.reject(new Error(res.message || "Error"));
  }
}, err);

export { service as axios };
