<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-09-30 13:55:01
 * @LastEditors: CZK
 * @LastEditTime: 2024-10-16 10:26:05
-->
<template>
  <div class="container">
      <div class="title-box">
        <p class="title">选择开户行</p>
        <p class="desc">精确选择，确保资金流动顺畅~</p>
      </div>
      <div class="info-search-box">
        <img src="../../assets/images/img/search_icon.png" alt="">
        <input type="text" v-model="bankName" @blur="search" placeholder="搜索开户行">
      </div>
      
      <template v-for="(item,index) in searchArr">
        <div class="info-faren-main" :id="item.id"  v-if="item.data.length>0" :key="index">
          <p class="info-faren-title-box">{{item.name}}</p>
          <div class="info-faren-item border-bottom-1px" v-for="(jtem,jndex) in item.data" :key="jndex" @click="select(jtem.bankId,jtem.bankName)">
            <img :src="bankImg[jtem.bankName]?bankImg[jtem.bankName]:bankImg['默认']"/>
            {{jtem.bankName}}
          </div>
        </div>
      </template>
      <div class="search-box">
        <span v-for="(item,index) in searchArr" :key="index" @click="tomao(index,item.id)" :class="{'on':currentBankIndex==index}">
         {{item.name}}
        </span>
      </div>
  </div>

</template>
<script type="text/ecmascript-6">
import{getBankList} from "@/api/user"
import {bankImg} from "@/utils/bankImg"
export default {
  name: "selectOpenBlank",
  components: {
 
  },
  data(){
    return {
      commiting:false,
      currentBankIndex:0,
      bankImg,
      bankName:'',
      searchArr:[
        {id:'cj',name:'常见',data:[]},
        {id:'A',name:'A',data:[]},
        {id:'B',name:'B',data:[]},
        {id:'C',name:'C',data:[]},
        {id:'D',name:'D',data:[]},
        {id:'E',name:'E',data:[]},
        {id:'F',name:'F',data:[]},
        {id:'G',name:'G',data:[]},
        {id:'H',name:'H',data:[]},
        {id:'I',name:'I',data:[]},
        {id:'J',name:'J',data:[]},
        {id:'K',name:'K',data:[]},
        {id:'L',name:'L',data:[]},
        {id:'M',name:'M',data:[]},
        {id:'N',name:'N',data:[]},
        {id:'O',name:'O',data:[]},
        {id:'P',name:'P',data:[]},
        {id:'Q',name:'Q',data:[]},
        {id:'R',name:'R',data:[]},
        {id:'S',name:'S',data:[]},
        {id:'T',name:'T',data:[]},
        {id:'U',name:'U',data:[]},
        {id:'V',name:'V',data:[]},
        {id:'W',name:'W',data:[]},
        {id:'X',name:'X',data:[]},
        {id:'Y',name:'Y',data:[]},
        {id:'Z',name:'Z',data:[]},
      ],
      banks:[]
    }
  },
  methods: {
    search(){
      this.getBankList(this.bankName)
    },
    tomao(index,id){
      this.currentBankIndex = index
      try {
        document.getElementById(id).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'})
      } catch (error) {
        console.log('元素不在')
      }
    },
    select(banklistId,bankName){
     const bankInfo = JSON.parse(this.$ls.get("bankInfo"))
      bankInfo.banklistId = banklistId
      bankInfo.banklistName = bankName

      this.$ls.set("bankInfo",JSON.stringify(bankInfo))
      this.$router.back()
      // this.$store.commit('SET_BANKINFO',info)
      // console.log(this.$store.getters.bankInfo,'000000000')
    },
    async getBankList(bankName){
      let res = await getBankList({bankName,token:''})
      if(res.success){
        this.searchArr.forEach(item=>item.data=[])
        res.data.forEach(item=>{
          item.firstLetter = item.firstLetter.toUpperCase()
          let findIndex = this.searchArr.findIndex(jtem=>jtem.name==item.firstLetter)

          if(findIndex>-1) this.searchArr[findIndex].data.push(item)

          if(item.commonStatus) this.searchArr[0].data.push(item)
        })
      }
    },

  },
  created(){
    this.getBankList()
  }
}
</script>
<style lang="less" scoped>
input{
  all: unset;
}
.container{
width: 100%;
background:#F5F7FB url("../../assets/images/img/bg1.png") no-repeat;
background-size: contain;
display: flex;
flex-direction: column;

.search-box{
    width: 24px;
    height: 459px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    
    line-height: 17px;
    text-align: center;
    position: fixed;
    right: 26px;
    top: 60%;
    transform: translateY(-50%);
      span{
        display: block;
        color: #999999;
        &.on{
          color: #3399FF;
        }
    }
  }
  .info-search-box{
    width: 343px;
    height: 44px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    margin: 17px auto 0;
    padding:0 17px;
    display:flex;
    align-items:center;
    img{
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
    input{
      font-weight: 400;
      font-size: 14px;
      margin-left:8px;
    }
  }
.beian{
  width: 198px;
  height: auto;
  display: block;
  margin: 20px auto;
  &.fid{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.title-box{
  margin-top: 27px;
  margin-left: 32px;
  .title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 18px;
  }
  .desc{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 22px;
    margin-top: 12px;
  }
}
.info-faren-main{
  width: 343px;
  // height: 500px;
  background: #FFFFFF;
  margin: 17px auto 0;
  border-radius: 8px 8px 8px 8px;
  .info-faren-title-box{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    margin-left: 17px;
    margin-top: 14px;
    line-height: 18px;
  }
  .info-faren-item{
    width: 294px;
    height: 50px;
    display: flex;
    align-items: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    margin-left: 10px;
    color: #333333;
    line-height: 18px;
    img{
      width: 30px;
      height: 30px;
      object-fit: contain;
      margin-right: 12px;
      margin-left: 6px;
    }
  }
  .agreement-box{
    display: flex;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 18px;
    margin: 16px 0;
    text-align: justify;
    a{
      color:#3399FF;
    }
    i{
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      flex-shrink: 0;
      background: url("../../assets/images/img/check_on.png") no-repeat;
      background-size: contain;
    }
  }
  .info-faren-title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
    color: #1C2023;
    line-height: 20px;
    &::after{
      content: '*';
      color:#FA5151;
    }
  }
  .info-faren-content{
    display: flex;
    justify-content: space-between;
  }
  .tips{
    width: 321px;
    height: 40px;
    background: #FFFBE1;
    border-radius: 8px 8px 8px 8px;
    padding: 10px 0 10px 6px;
    text-align: left;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin: 14px 0;
    color: #FFA54C;
  }
  .faren-form{
    width: 100%;
    padding:0 10px;
    .form-item1{
      width: 100%;
      display: flex;
      justify-content: space-between;
      min-height: 50px;
      label{
        width: 56px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 50px;
        margin-right: 32px;
      }
      .types-box{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 18px;
        margin-top: 16px;
        p{
          display: flex;
          margin-bottom: 11px;
        }
        i{
          display: inline-block;
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          background: url("../../assets/images/img/no.png") no-repeat;
          background-size: contain;
          margin-right: 10px;
          &.yes{
            background: url("../../assets/images/img/yes.png") no-repeat;
          }
        }
      }
    }
    .form-item{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      position: relative;
      .bank-box{
        // background: #F5F5F5;
        width: 232px;
        height: 38px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #C9C9C9;
        padding: 0 6px;
        display: flex;
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        &.on{
          color: #333333;
        }
        &.b{
          &::before{
            content: '';
            width: 24px;
            height: 24px;
            background: url("../../assets/images/img/b_icon.png") no-repeat;
            background-size: contain;
            display: block;
            position: absolute;
            right: 0;
          }
        }
      }
      &.r{
        &::before{
          content: '';
          width: 24px;
          height: 24px;
          background: url("../../assets/images/img/right_icon.png") no-repeat;
          background-size: contain;
          display: block;
          position: absolute;
          right: 0;
        }
      }
      &.yuan{
        &::before{
          content: '元';
          position: absolute;
          right: 6px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
        }
      }
      &.day{
        &::before{
          content: '天';
          position: absolute;
          right: 6px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
        }
      }
      label{
        width: 70px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 20px;
        margin-right: 32px;
      }
      input{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        &::placeholder{
          color: #999999;
        }
      }
      span{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
    }
  }
  .add-btn{
    width: 321px;
    height: 42px;
    text-align: center;
    background: #CCCCCC;
    border-radius: 30px 30px 30px 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 42px;
    margin: 22px auto 22px;
    color: #FFFFFF;
    &.on{
      background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
      margin: 10px auto 16px;
    }
  }
}
}
.confrim-content{
  width: 100%;
  height: 374px;
  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
  padding: 0 12px;
  .header{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    position: relative;
    i{
      display: block;
      width: 24px;
      height: 24px;
      background: url("../../assets/images/img/×.png") no-repeat;
      background-size: contain;
      position: absolute;
      left: 10px;
    }
  }
  .content{
    width: 100%;
    height: calc(374px - 50px);
    overflow-y: scroll;
    .bank-box{
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #F5F5F5;
      padding: 0 12px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      &.on{
        font-weight: 600;
        position: relative;
        &::before{
          content: '';
          display: block;
          width: 24px;
          height: 24px;
          background: url("../../assets/images/img/gou.png") no-repeat;
          background-size: contain;
          position: absolute;
          right: 12px;
        }
      }
      img{
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
      span{
        text-align: left;
    }
  }
}

 
}
</style>