var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "info-faren-main" }, [
      _c("div", { staticClass: "info-faren-title-box" }, [
        _vm._v("\n        请将店铺管理员及主账号手机号修改为下方手机号\n      ")
      ]),
      _c("div", { staticClass: "info-faren-content" }, [
        _c("div", { staticClass: "faren-form" }, [
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("新手机号")]),
            _c("span", [_vm._v(_vm._s(_vm.phoneInfo.phoneNum))])
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "info-faren-main" }, [
      _vm._m(1),
      _c(
        "div",
        { staticClass: "add-btn on", on: { click: _vm.commit } },
        [
          _vm.commiting ? _c("cube-loading") : _vm._e(),
          _c("span", [_vm._v("已绑定，下一步")])
        ],
        1
      )
    ]),
    _c("img", {
      staticClass: "beian fid",
      attrs: { src: require("../../assets/images/img/beian.png") }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("绑定店铺账号")]),
      _c("p", { staticClass: "desc" }, [_vm._v("Hi，请填写以下资料吧~")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-faren-content" }, [
      _c("div", { staticClass: "faren-form" }, [
        _c("div", { staticClass: "form-item border-bottom-1px" }, [
          _c("span", { staticClass: "b" }, [_vm._v("店铺后台绑定手机号验证码")])
        ]),
        _c("div", { staticClass: "form-item border-bottom-1px" }, [
          _c("label", [_vm._v("验 证 码")]),
          _c("span", { staticClass: "btn" }, [_vm._v("获取验证码")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }