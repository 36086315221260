import {
  evalOpts,
  STATUS_SUCCESS,
  STATUS_UPLOADING,
  STATUS_ERROR,
} from "./util";

import { osspolicy } from "@/api/common";
import { getUploadProof } from "@/api/user";
/**
 * 获取阿里云oss验签
 */
let osspolicyObject = {};

export default async function ajaxUpload(file, options, changeHandler) {
  const {
    target,
    headers = {},
    data = {},
    fileName = "file",
    withCredentials,
    timeout,
    prop = "file",
    progressInterval = 100,
    checkSuccess = function() {
      return true;
    },
    type = "oss",
  } = options;

  const realTarget = evalOpts(target, file);

  file.progress = 0;
  file.status = STATUS_UPLOADING;

  const xhr = new window.XMLHttpRequest();
  file._xhr = xhr;
  let progressTid = 0;
  if (xhr.upload) {
    let lastProgressTime = Date.now();
    xhr.upload.onprogress = function(e) {
      if (e.total > 0) {
        if (progressTid) {
          clearTimeout(progressTid);
          const now = Date.now();
          const diff = now - lastProgressTime;
          if (diff >= progressInterval) {
            computed();
          } else {
            progressTid = setTimeout(computed, diff);
          }
        } else {
          // first time
          computed();
          progressTid = 1;
        }
      }
      function computed() {
        file.progress = e.loaded / e.total;
        lastProgressTime = Date.now();
      }
    };
  }

  const formData = new window.FormData();

  // 阿里云直传
  if (type === "oss") {
    const setFormData = (data) => {
      // todo针对阿里云,设置文件后缀名
      let Suffix = file.name
        .split(".")
        .pop()
        .toLowerCase();

      Object.keys(data).forEach((key) => {
        if (key === "key") {
          //文件名
          //formData.append(key, `${data[key]}_${Math.random().toString(16).substr(2,15)}.${Suffix}`);
          const userId = window.localStorage.getItem("userId");
          const title = Suffix === "mp4" || Suffix === "mov" ? "app_video_prd" : "app_file_prd";
          const fileName = `${title}_${userId}_${Date.now()}.${Suffix}`;
          formData.append(key, fileName);
          //设置文件名
          const target = `https://wfwloan-app.oss-cn-shenzhen.aliyuncs.com/`;
          // const target = 'http://test-011.oss-cn-shenzhen.aliyuncs.com/'
          file.filenameUrl = `${target}${fileName}`;          
        } else {
          formData.append(key, data[key]);
        }
      });
      formData.append("file", file[prop]);
      formData.append("success_action_status", '200');//添加成功返回状态码
    };
    // 待优化
    let hasExpire = () => {
      if (JSON.stringify(osspolicyObject) === "{}") {
        return true;
      }
      const nowTime = +new Date();
      const { expire, setInBrowserTime } = osspolicyObject;

      // 设置1秒缓存 + 网络延迟时间
      // return nowTime - setInBrowserTime >= expire * 1000 - 1000;
      return nowTime >= expire * 1000 - 1000;
    };

    if (hasExpire()) {
      // const res = await osspolicy();
      const res = await getUploadProof({});
      if (res.success) {
        // const data = res.data.data;
        const data = res.data;

        osspolicyObject = { ...data, setInBrowserTime: +new Date() };

        setFormData(data);
      }
    } else {
      setFormData(osspolicyObject);
    }
  } else {
    // 普通上传
    const realData = evalOpts(data, file);
    Object.keys(realData).forEach((key) => {
      formData.append(key, realData[key]);
    });

    formData.append(fileName, file[prop]);
  }

  xhr.onload = function() {
    if (xhr.status < 200 || xhr.status >= 300) {
      setStatus(STATUS_ERROR);
      return;
    }
    setResponse();
    const response = file.response;

    if (checkSuccess.length <= 2) {
      const isSuccess = checkSuccess(response, file);
      setStatus(isSuccess ? STATUS_SUCCESS : STATUS_ERROR);
    } else {
      // callback
      checkSuccess(response, file, (isSuccess) => {
        setStatus(isSuccess ? STATUS_SUCCESS : STATUS_ERROR);
      });
    }
  };
  xhr.onerror = function() {
    setResponse();
    setStatus(STATUS_ERROR);
  };
  xhr.ontimeout = function() {
    setResponse();
    setStatus(STATUS_ERROR);
  };

  xhr.open("POST", realTarget, true);
  if (withCredentials) {
    xhr.withCredentials = true;
  }
  const realHeaders = evalOpts(headers, file);
  Object.keys(realHeaders).forEach((key) => {
    xhr.setRequestHeader(key, realHeaders[key]);
  });
  if (timeout > 0) {
    xhr.timeout = timeout;
  }

  xhr.send(formData);
  function setStatus(status) {
    clearTimeout(progressTid);
    progressTid = 0;
    file.progress = 1;
    file.status = status;
    changeHandler && changeHandler(file);
  }
  function setResponse() {
    let response = xhr.responseText || xhr.response;
    try {
      response = JSON.parse(response);
    } catch (e) {
      console.log("上传出错了 respone返回有问题 =>", e.message);
    }
    file.response = response;
    file.response.data = { url: file.filenameUrl };
    file.responseHeaders = xhr.getAllResponseHeaders();
  }
}
