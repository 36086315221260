/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-09-02 14:56:59
 * @LastEditors: CZK
 * @LastEditTime: 2024-09-14 10:48:52
 */
// 单独处理授权登录的接口地址
import { axios } from "@/utils/requestCarFinance";

//微信授权登录
export function verificationCode(params = {}) {
  return axios({
    url: "/app/login/verificationCode",
    method: "get",
    params
  });
}

//微信确定登陆
export function wechatSureLogin(data) {
  return axios({
    url: "/wechatSureLogin",
    method: "post",
    data
  });
}


/**
 * 获取图片验证码base64接口
 * 
 */

 export function getCaptcha(query){
  return axios({
    url: "/getCaptcha",
    method: "get",
    params: query,
  });
}


/**
 * 登录发送验证码
 * @param {*} data mobile ,pssword, rememberMe, where, authCode, authId
 */
 export function loginSMS(data) {
  return axios({
    url: "/loginSMS.do",
    method: "post",
    data
  });
}


/**
 * 获取openid
 * 
 */

export function getOpenid(query){
  return axios({
    url: "/app/login/getOpenid",
    method: "get",
    params: query,
  });
}



/**
 * 登录发送验证码
 * @param {*} data mobile ,pssword, rememberMe, where, authCode, authId
 */
export function sendMes(data) {
  return axios({
    url: "/sendMes",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data
  });
}
