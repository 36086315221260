var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "info-faren-main" }, [
      _c("div", { staticClass: "info-faren-title-box" }, [
        _vm._v("\n        请添加资金方放款的收款账户\n      ")
      ]),
      _c("div", { staticClass: "info-faren-content" }, [
        _c("div", { staticClass: "faren-form" }, [
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("账 户 名")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.info.name,
                  expression: "info.name"
                }
              ],
              attrs: { type: "text", placeholder: "请输入" },
              domProps: { value: _vm.info.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.info, "name", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("账 户 号")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.info.bankNo,
                  expression: "info.bankNo"
                }
              ],
              attrs: { type: "text", placeholder: "请输入" },
              domProps: { value: _vm.info.bankNo },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.info, "bankNo", $event.target.value)
                }
              }
            })
          ]),
          _c(
            "div",
            {
              staticClass: "form-item border-bottom-1px ",
              on: { click: _vm.selectTap }
            },
            [
              _c("label", [_vm._v("开 户 行")]),
              _c(
                "div",
                {
                  staticClass: "bank-box b",
                  class: { on: _vm.info.banklistName }
                },
                [_vm._v(_vm._s(_vm.info.banklistName || "请选择开户行"))]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "form-item border-bottom-1px ",
              on: { click: _vm.selectCity }
            },
            [
              _c("label", [_vm._v("所属城市")]),
              _c(
                "div",
                { staticClass: "bank-box b", class: { on: _vm.info.cityName } },
                [_vm._v(_vm._s(_vm.info.cityName || "请选择所属城市"))]
              )
            ]
          ),
          _c("div", { staticClass: "form-item border-bottom-1px " }, [
            _c("label", [_vm._v("所属支行")]),
            _c(
              "div",
              {
                staticClass: "bank-box b",
                class: { on: _vm.info.openBranchName },
                on: { click: _vm.selectTap1 }
              },
              [_vm._v(_vm._s(_vm.info.openBranchName || "请选择所属支行"))]
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "add-btn on", on: { click: _vm.commit } },
        [
          _vm.commiting ? _c("cube-loading") : _vm._e(),
          _c("span", [_vm._v("确定")])
        ],
        1
      )
    ]),
    _c("img", {
      staticClass: "beian fid",
      attrs: { src: require("../../assets/images/img/beian.png") }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("添加收款账户")]),
      _c("p", { staticClass: "desc" }, [_vm._v("Hi，请填写以下资料吧~")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }