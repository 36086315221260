var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm._m(0),
      _c("div", { staticClass: "info-faren-main" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "info-faren-content" },
          _vm._l(_vm.faren.idCard, function(item, index) {
            return _c("OSSUpload", {
              key: index,
              ref: "upload1",
              refInFor: true,
              attrs: {
                type: item.uploadType,
                backgroundImgName: item.bg,
                accept: item.accept,
                title: item.title,
                files: item.files,
                multiple: item.multiple
              },
              on: {
                success: function(e) {
                  return _vm.handleSuccess(e, "faren", index)
                },
                "update:files": function($event) {
                  return _vm.$set(item, "files", $event)
                }
              }
            })
          }),
          1
        ),
        _c("div", { staticClass: "tips" }, [
          _vm._v("请确认以下信息是否正确，如有误请重新识别")
        ]),
        _c("div", { staticClass: "faren-form" }, [
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("真实姓名")]),
            _c("span", [_vm._v(_vm._s(_vm.faren.name))])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("身份证号")]),
            _c("span", [_vm._v(_vm._s(_vm.faren.idCardNo))])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("签发机关")]),
            _c("span", [_vm._v(_vm._s(_vm.faren.signIssued))])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("有效期限")]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.faren.effectiveStartDate) +
                  "-" +
                  _vm._s(_vm.faren.effectiveEndDate)
              )
            ])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("联系电话")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.faren.phone,
                  expression: "faren.phone"
                }
              ],
              attrs: {
                type: "text",
                maxlength: 11,
                placeholder: "请填写法人实名手机号"
              },
              domProps: { value: _vm.faren.phone },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.faren, "phone", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("电子邮箱")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.faren.email,
                  expression: "faren.email"
                }
              ],
              attrs: { type: "text", placeholder: "请填写电子邮箱" },
              domProps: { value: _vm.faren.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.faren, "email", $event.target.value)
                }
              }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "info-faren-main" }, [
        _c("div", { staticClass: "info-faren-title-box" }, [
          _c("p", { staticClass: "info-faren-title on" }, [
            _vm._v("企业联系人信息")
          ]),
          _c("div", { staticClass: "select-box" }, [
            _c("span", { on: { click: _vm.changeLianxiren } }, [
              _c("i", { class: { yes: _vm.lianxiren.contactStatus == 1 } }),
              _vm._v("同法人")
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "info-faren-content" },
          _vm._l(_vm.lianxiren.idCard, function(item, index) {
            return _c("OSSUpload", {
              key: index,
              ref: "upload1",
              refInFor: true,
              attrs: {
                type: item.uploadType,
                backgroundImgName: item.bg,
                accept: item.accept,
                title: item.title,
                files: item.files,
                multiple: item.multiple
              },
              on: {
                success: function(e) {
                  return _vm.handleSuccess(e, "lianxiren", index)
                },
                "update:files": function($event) {
                  return _vm.$set(item, "files", $event)
                }
              }
            })
          }),
          1
        ),
        _c("div", { staticClass: "tips" }, [
          _vm._v("请确认以下信息是否正确，如有误请重新识别")
        ]),
        _c("div", { staticClass: "faren-form" }, [
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("真实姓名")]),
            _c("span", [_vm._v(_vm._s(_vm.lianxiren.name))])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("身份证号")]),
            _c("span", [_vm._v(_vm._s(_vm.lianxiren.idCardNo))])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("签发机关")]),
            _c("span", [_vm._v(_vm._s(_vm.lianxiren.signIssued))])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("有效期限")]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.lianxiren.effectiveStartDate) +
                  "-" +
                  _vm._s(_vm.lianxiren.effectiveEndDate)
              )
            ])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("联系电话")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.lianxiren.phone,
                  expression: "lianxiren.phone"
                }
              ],
              attrs: {
                type: "text",
                maxlength: 11,
                placeholder: "请填写法人实名手机号"
              },
              domProps: { value: _vm.lianxiren.phone },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.lianxiren, "phone", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("电子邮箱")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.lianxiren.email,
                  expression: "lianxiren.email"
                }
              ],
              attrs: { type: "text", placeholder: "请填写电子邮箱" },
              domProps: { value: _vm.lianxiren.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.lianxiren, "email", $event.target.value)
                }
              }
            })
          ])
        ])
      ]),
      _vm._l(_vm.konggu, function(item, index) {
        return _c("div", { key: item.radom, staticClass: "info-faren-main" }, [
          _c("div", { staticClass: "info-faren-title-box" }, [
            _c(
              "p",
              { staticClass: "info-faren-title", class: { on: index === 0 } },
              [_vm._v("企业控股股东信息" + _vm._s(index + 1))]
            ),
            _c("div", { staticClass: "select-box" }, [
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.changeGudong(index, 1)
                    }
                  }
                },
                [
                  _c("i", { class: { yes: item.shareholderStatus == 1 } }),
                  _vm._v("同法人")
                ]
              ),
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.changeGudong(index, 2)
                    }
                  }
                },
                [
                  _c("i", { class: { yes: item.shareholderStatus == 2 } }),
                  _vm._v("同联系人")
                ]
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "info-faren-content" },
            _vm._l(item.idCard, function(jtem, jndex) {
              return _c("OSSUpload", {
                key: jtem.bg,
                ref: "upload1",
                refInFor: true,
                attrs: {
                  type: jtem.uploadType,
                  backgroundImgName: jtem.bg,
                  accept: jtem.accept,
                  title: jtem.title,
                  files: jtem.files,
                  multiple: jtem.multiple
                },
                on: {
                  success: function(e) {
                    return _vm.handleSuccess1(e, "konggu", index, jndex)
                  },
                  "update:files": function($event) {
                    return _vm.$set(jtem, "files", $event)
                  }
                }
              })
            }),
            1
          ),
          _c("div", { staticClass: "tips" }, [
            _vm._v("请确认以下信息是否正确，如有误请重新识别")
          ]),
          _c("div", { staticClass: "faren-form" }, [
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("真实姓名")]),
              _c("span", [_vm._v(_vm._s(item.name))])
            ]),
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("身份证号")]),
              _c("span", [_vm._v(_vm._s(item.idCardNo))])
            ]),
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("签发机关")]),
              _c("span", [_vm._v(_vm._s(item.signIssued))])
            ]),
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("有效期限")]),
              _c("span", [
                _vm._v(
                  _vm._s(item.effectiveStartDate) +
                    "-" +
                    _vm._s(item.effectiveEndDate)
                )
              ])
            ]),
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("联系电话")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: item.phone,
                    expression: "item.phone"
                  }
                ],
                attrs: {
                  type: "text",
                  maxlength: 11,
                  placeholder: "请填写股东本人手机号"
                },
                domProps: { value: item.phone },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(item, "phone", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("电子邮箱")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: item.email,
                    expression: "item.email"
                  }
                ],
                attrs: { type: "text", placeholder: "请填写电子邮箱" },
                domProps: { value: item.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(item, "email", $event.target.value)
                  }
                }
              })
            ])
          ]),
          index == _vm.konggu.length - 1
            ? _c(
                "div",
                { staticClass: "add-btn", on: { click: _vm.addKonggu } },
                [_vm._v("+添加企业控股信息")]
              )
            : _vm._e()
        ])
      }),
      _vm._l(_vm.shouyiren, function(item, index) {
        return _c("div", { key: index, staticClass: "info-faren-main" }, [
          _c("div", { staticClass: "info-faren-title-box" }, [
            _c(
              "p",
              { staticClass: "info-faren-title", class: { on: index === 0 } },
              [_vm._v("企业最终受益人信息" + _vm._s(index + 1))]
            ),
            _c("div", { staticClass: "select-box" }, [
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.changeShouyiren(index, 1)
                    }
                  }
                },
                [
                  _c("i", { class: { yes: item.shareholderStatus == 1 } }),
                  _vm._v("同法人")
                ]
              ),
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.changeShouyiren(index, 2)
                    }
                  }
                },
                [
                  _c("i", { class: { yes: item.shareholderStatus == 2 } }),
                  _vm._v("同联系人")
                ]
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "info-faren-content" },
            _vm._l(item.idCard, function(jtem, jndex) {
              return _c("OSSUpload", {
                key: jtem.bg,
                ref: "upload1",
                refInFor: true,
                attrs: {
                  type: jtem.uploadType,
                  backgroundImgName: jtem.bg,
                  accept: jtem.accept,
                  title: jtem.title,
                  files: jtem.files,
                  multiple: jtem.multiple
                },
                on: {
                  success: function(e) {
                    return _vm.handleSuccess1(e, "shouyiren", index, jndex)
                  },
                  "update:files": function($event) {
                    return _vm.$set(jtem, "files", $event)
                  }
                }
              })
            }),
            1
          ),
          _c("div", { staticClass: "tips" }, [
            _vm._v("请确认以下信息是否正确，如有误请重新识别")
          ]),
          _c("div", { staticClass: "faren-form" }, [
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("真实姓名")]),
              _c("span", [_vm._v(_vm._s(item.name))])
            ]),
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("身份证号")]),
              _c("span", [_vm._v(_vm._s(item.idCardNo))])
            ]),
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("签发机关")]),
              _c("span", [_vm._v(_vm._s(item.signIssued))])
            ]),
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("有效期限")]),
              _c("span", [
                _vm._v(
                  _vm._s(item.effectiveStartDate) +
                    "-" +
                    _vm._s(item.effectiveEndDate)
                )
              ])
            ]),
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("联系电话")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: item.phone,
                    expression: "item.phone"
                  }
                ],
                attrs: {
                  type: "text",
                  maxlength: 11,
                  placeholder: "请填写股东本人手机号"
                },
                domProps: { value: item.phone },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(item, "phone", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("电子邮箱")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: item.email,
                    expression: "item.email"
                  }
                ],
                attrs: { type: "text", placeholder: "请填写电子邮箱" },
                domProps: { value: item.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(item, "email", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("联系地址")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: item.address,
                    expression: "item.address"
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: "请填写企业最终受益人联系地址"
                },
                domProps: { value: item.address },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(item, "address", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-item1 border-bottom-1px" }, [
              _c("label", [_vm._v("受益方式")]),
              _c(
                "div",
                { staticClass: "types-box" },
                _vm._l(_vm.types, function(ttem, tndex) {
                  return _c(
                    "p",
                    {
                      key: ttem,
                      on: {
                        click: function($event) {
                          return _vm.changeType(index, "shouyiren", tndex + 1)
                        }
                      }
                    },
                    [
                      _c("i", {
                        class: { yes: tndex == item.benefitType - 1 }
                      }),
                      _c("span", [_vm._v(_vm._s(ttem))])
                    ]
                  )
                }),
                0
              )
            ])
          ]),
          index == _vm.shouyiren.length - 1
            ? _c(
                "div",
                { staticClass: "add-btn", on: { click: _vm.addshouyiren } },
                [_vm._v("+添加企业受益人信息")]
              )
            : _vm._e(),
          index == _vm.shouyiren.length - 1
            ? _c(
                "div",
                { staticClass: "add-btn on", on: { click: _vm.commit } },
                [
                  _vm.commiting ? _c("cube-loading") : _vm._e(),
                  _c("span", [_vm._v("下一步")])
                ],
                1
              )
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("企业补充信息")]),
      _c("p", { staticClass: "desc" }, [_vm._v("Hi，请填写以下资料吧~")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-faren-title-box" }, [
      _c("p", { staticClass: "info-faren-title on" }, [_vm._v("企业法人信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }