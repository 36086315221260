var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVerificationShow,
          expression: "isVerificationShow"
        }
      ],
      staticClass: "puzzle-container"
    },
    [
      _c("div", { staticClass: "puzzle-header" }, [
        _c("span", { staticClass: "puzzle-header-left" }, [
          _vm._v("短信安全验证")
        ]),
        _c("div", [
          _c("span", {
            staticClass: "re-btn iconfont icon-shuaxin",
            on: { click: _vm.refreshImg }
          }),
          _c("span", {
            staticClass: "close-btn iconfont icon-guanbi",
            on: { click: _vm.closeVerificationBox }
          })
        ])
      ]),
      _c(
        "div",
        {
          style:
            "position:relative;overflow:hidden;width:" + _vm.dataWidth + "px;"
        },
        [
          _c(
            "div",
            {
              style:
                "position:relative;width:" +
                _vm.dataWidth +
                "px;height:" +
                _vm.dataHeight +
                "px;"
            },
            [
              _c("img", {
                ref: "scream",
                style:
                  "width:" +
                  _vm.dataWidth +
                  "px;height:" +
                  _vm.dataHeight +
                  "px;",
                attrs: { id: "scream", src: _vm.imgRandom }
              }),
              _c("canvas", {
                ref: "puzzleBox",
                attrs: {
                  id: "puzzle-box",
                  width: _vm.dataWidth,
                  height: _vm.dataHeight
                }
              })
            ]
          ),
          _c(
            "div",
            {
              staticClass: "puzzle-lost-box",
              style:
                "left:" +
                _vm.left_Num +
                "px;width:" +
                _vm.dataWidth +
                "px;height:" +
                _vm.dataHeight +
                "px;"
            },
            [
              _c("canvas", {
                ref: "puzzleShadow",
                attrs: {
                  id: "puzzle-shadow",
                  width: _vm.dataWidth,
                  height: _vm.dataHeight
                }
              }),
              _c("canvas", {
                ref: "puzzleLost",
                attrs: {
                  id: "puzzle-lost",
                  width: _vm.dataWidth,
                  height: _vm.dataHeight
                }
              })
            ]
          ),
          _c(
            "p",
            {
              ref: "verTips",
              class: "ver-tips" + (_vm.displayTips ? " slider-tips" : "")
            },
            [
              _vm.verification
                ? [
                    _c("i", {
                      staticStyle: { "background-position": "-4px -1207px" }
                    }),
                    _c("span", { staticStyle: { color: "#42ca6b" } }, [
                      _vm._v("验证通过")
                    ]),
                    _c("span")
                  ]
                : _vm._e(),
              !_vm.verification
                ? [
                    _c("span", { staticClass: "error-tips" }, [
                      _vm._v("验证失败，请重新验证")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "slider-container",
          style: "width:" + _vm.dataWidth + "px;"
        },
        [
          _c("div", {
            staticClass: "slider-bar",
            class: { on: !_vm.moveStart }
          }),
          _c("div", {
            ref: "sliderBtn",
            staticClass: "slider-btn",
            on: { mousedown: _vm.startMove, touchstart: _vm.startMove }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }