var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "info-faren-main" }, [
      _vm._m(1),
      _c(
        "div",
        { staticClass: "add-btn on", on: { click: _vm.commit } },
        [
          _vm.commiting ? _c("cube-loading") : _vm._e(),
          _c("span", [_vm._v("下一步")])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("企业开户")]),
      _c("p", { staticClass: "desc" }, [_vm._v("请填写以下资料吧~")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "body-box" }, [
      _c("div", { staticClass: "up-box" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/img/step_1.png") }
        }),
        _c("span", { staticClass: "t" }, [_vm._v("打款金额确认")]),
        _c("span", { staticClass: "d" }, [
          _vm._v("新网银行已于2024.12.6向您的"),
          _c("br"),
          _vm._v("对公账号成功打款了一笔小额金额，"),
          _c("br"),
          _vm._v("请填写收款金额完成开户认证")
        ])
      ]),
      _c("div", { staticClass: "faren-form" }, [
        _c("div", { staticClass: "form-item border-bottom-1px" }, [
          _c("label", [_vm._v("企业名称")]),
          _c("span", [_vm._v("广州市启蒙玩具有限公司")])
        ]),
        _c("div", { staticClass: "form-item border-bottom-1px" }, [
          _c("label", [_vm._v("收款账号")]),
          _c("span", [_vm._v("6226********1234")])
        ]),
        _c("div", { staticClass: "form-item border-bottom-1px" }, [
          _c("label", [_vm._v("收款金额")]),
          _c("input", {
            attrs: { type: "text", placeholder: "请输入收款金额" }
          }),
          _c("div", [_vm._v("元")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }