<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-10-29 13:58:38
 * @LastEditors: CZK
 * @LastEditTime: 2024-11-13 17:38:04
-->
<template>
  <div class="container" v-if="info">
    <div class="card">
      <p class="date">{{info.signDate}} 请款(元)</p>
      <div>
        <span class="money">{{info.loanAmount}}</span><span class="status status01">{{info.orderStatus}}</span>
      </div>
      <p class="small-money">剩余本金 {{info.principalBalance	}} 元</p>
    </div>

    <div class="card-item" @click="toapplyDetail('applyDetail')">
      <span>请款明细及协议</span>
      <img src="../../assets/images/img/right_icon.png" alt="">
    </div>

    <div class="card-detail">
      <div class="header border-bottom-1px">
        <span class="title">已还明细</span><span class="val">共{{info.repaymentFrequency}}次还款</span>
      </div>
      <div class="line">
        <span class="title">已还本金</span><span class="val">¥{{info.repaidPrincipal||0}}</span>
      </div>
      <div class="line">
        <span class="title">已还利息</span><span class="val">¥{{info.repaidInterest}}</span>
      </div>
    </div>

    <div class="list-item" @click="toMenu('repaymentDetails',item.billId)" v-for="(item,index) in info.repaidList" :key="index">
      <div class="line">
        <span class="title">还款 ¥{{item.repaymentDiff}}</span> <img src="../../assets/images/img/right_icon.png" alt="">
      </div>
      <p>{{item.repayTime}}</p>
    </div>

    <!-- <div class="list-item" @click="toMenu('repaymentDetails')">
      <div class="line">
        <span class="title">还款 ¥1,000.00</span> <img src="../../assets/images/img/right_icon.png" alt="">
      </div>
      <p>2024.05.08 17:23</p>
    </div> -->
    
  </div>
</template>
<script>
import {getBillDetail} from "@/api/user"
  export default {
    name: "billingDetails",
    data() {
      return {
        info:null
      }
    },
    methods:{
      toapplyDetail(name){
        const applyId = this.$route.query.applyId
        this.$router.push({name,query:{applyId}})
      },
      toMenu(name,billId){
        this.$router.push({name,query:{billId}})
      },
      async getBillDetail(){
        const applyId = this.$route.query.applyId
        const res = await getBillDetail({token:'',applyId})
        if(res.success) this.info = res.data
      }
    },
    mounted() {
      this.getBillDetail()
    }
  }
</script>
<style lang="less" scoped>
  .container {
    width: 100%;
    background: #F5F7FB url(../../assets/images/img/mine_bg.png) no-repeat top center;
    .card{
      width: 343px;
      height: 154px;
      background: #FFFFFF;
      margin:22px auto 0;
      padding:22px 16px;
      box-shadow: 0px 3 8px 0px rgba(35,96,170,0.1);
      border-radius: 8px 8px 8px 8px;
      .small-money{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin-top:20px;
        line-height: 18px;
      }
      .date{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 18px;
      }
      div{
        margin-top:8px;
        display:flex;
        justify-content: space-between;
        align-items:center;
        .money{
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 38px;
          color: #333333;
          line-height: 48px;
        }
        .status{
          width: 60px;
          height: 26px;
          background: #E8F6FC;
          display:block;
          text-align:center;
          line-height:26px;
          border-radius: 5px 5px 5px 5px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #007BFF;
          &.status01{
            background: #E5F9ED;
            color: #1BA555;
          }
        }
      }
    }
    .card-detail{
      width: 343px;
      margin:16px auto 0;
      background: #FFFFFF;
      padding: 0 11px;
      border-radius: 8px 8px 8px 8px;
      .line{
        height:50px;
        width:100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;

        .title{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 18px;
        }
        .val{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          line-height: 20px;
        }
      }
      .header{
        height:50px;
        width:100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;
        .title{
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #333333;
          line-height: 18px;
        }
        .val{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 18px;
        }
      }
    }
    .card-item{
      width: 343px;
      height: 46px;
      margin:16px auto 0;
      background: #FFFFFF;
      display:flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 17px;
      border-radius: 8px 8px 8px 8px;
      span{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 18px;
      }
      img{
        width:24px;
        height:24px;
        object-fit:contain;
      }
    }


    .list-item{
      width: 343px;
      height: 80px;
      margin:16px auto 0;
      background: #FFFFFF;
      padding: 0 17px;
      .line{
        display:flex;
        align-items: center;
        height:40px;
        justify-content: space-between;
        
        border-radius: 8px 8px 8px 8px;
        span{
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          line-height: 18px;
        }
        img{
          width:24px;
          height:24px;
          object-fit:contain;
        }
      }
      p{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin-top: 10px;
      }
    }
  }
</style>