const state = {
  cacheView: [],
};

const mutations = {
  ADD_CACHE_VIEW(state, view) {
    if (state.cacheView.includes(view.name)) {
      return;
    }
    state.cacheView.push(view.name);
  },
  CLEAR_CACHE_VIEW(state) {
    state.cacheView = [];
  },
  CLEAR_CACHE_VIEW_BY_NAME(state, view) {
    state.cacheView = state.cacheView.filter(ele => {
      return ele !== view.name;
    });
  }
};

const actions = {
  addCachedView({ commit }, view) {
    commit("ADD_CACHE_VIEW", view);
  },
  clearCacheView({ commit }) {
    commit("CLEAR_CACHE_VIEW");
  },
  clearCacheViewByName({ commit }, view) {    
    commit("CLEAR_CACHE_VIEW_BY_NAME", view);
  }
};

export default {
  state,
  mutations,
  actions,
};
