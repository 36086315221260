<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-09-29 16:47:02
 * @LastEditors: CZK
 * @LastEditTime: 2024-10-25 14:27:23
-->
<template>
  <div class="container">
    <div class="title-box">
      <p class="title">补充资料</p>
      <p class="desc">Hi，请填写以下资料吧~</p>
    </div>

    <div class="info-faren-main">
      <div class="tips">请上传以下照片或文件，照片要求清晰无反光，文件小于10M。</div>
      <div class="info-faren-content">
        <OSSUpload 
        v-for="(item, index) in filesArray" 
        :key="index" 
        :type="item.uploadType"   
        :backgroundImgName="item.bg" 
        :accept="item.accept"
        :need="item.need"
        :successBg="item.successBg"
        ref="upload1"
        @success="(e)=>{return handleSuccess(e,index)}"
        :title="item.title" 
        :files.sync="item.files" 
        style="margin-bottom: 14px;"
        :multiple="item.multiple"/>
      </div>

      <div class="add-btn on" @click="commit"><cube-loading v-if="commiting"></cube-loading>
        <span>下一步</span></div>

    </div>

  </div>
</template>
<script type="text/ecmascript-6">
import OSSUpload from "@comp/Upload/OSSUpload";
import {saveSupplyInfo,updateShopInfo} from "@api/user";
import {findNextFlowThenJump,findNextFlowThenJump1} from "../main/findNextFlow"
export default {
  name: "additionalInformation",
  components: {
    OSSUpload
  },
  data(){
    return {
      commiting:false,
      type:1,
      filesArray:[]
        // files:[
        //   {
        //     uploadType: 1,
        //     bg: "carme.png",
        //     accept: "image/png,image/jpeg,image/gif",
        //     // accept: ".xls,.xlsx,.csv",
        //     title: "店铺资质(必传)",
        //     need:0,
        //     multiple: false,
        //     files: []
        //   },
        //   {
        //     uploadType: 1,
        //     bg: "carme.png",
        //     accept: ".xls,.xlsx,.csv",
        //     title: "待结算订单报表 (必传)",
        //     multiple: false,
        //     need:1,
        //     files: []
        //   },
        //   {
        //     uploadType: 1,
        //     bg: "carme.png",
        //     accept: ".xls,.xlsx,.csv",
        //     title: "店铺裁图（选传）",
        //     multiple: false,
        //     need:0,
        //     files: []
        //   },
        //   {
        //     uploadType: 1,
        //     bg: "carme.png",
        //     accept: ".xls,.xlsx,.csv",
        //     title: "结算账单报表（选传）",
        //     multiple: false,
        //     need:0,
        //     files: []
        //   },
        //   {
        //     uploadType: 1,
        //     bg: "carme.png",
        //     accept: ".xls,.xlsx,.csv",
        //     title: "订单报表（选传）",
        //     multiple: false,
        //     need:0,
        //     files: []
        //   },
        // ]

    }
  },
  methods: {
    async commit(){
      if(this.commiting) return
      const {filesArray} = this
      let flag = false
      let tipsName = ''
      for(let i in filesArray){
        if((filesArray[i].need==1||filesArray[i].need)&&!filesArray[i].url){
          flag = true;
          tipsName = filesArray[i].title
          break;
        }
      }
      // let frontUrl= "",
      //   licenseUrl= "",
      //   orderReportUrl= "",
      //   settlementReportUrl= "",
      //   waitSettlementReportUrl= ""

      // filesArray.forEach(item=>{
      //   if(item.code == 'ShopCertQualification')  frontUrl = item.filenameUrl
      //   if(item.code == 'ShopScreenshot')   licenseUrl = item.filenameUrl
      //   if(item.code == 'OrderReportForm')   orderReportUrl = item.filenameUrl
      //   if(item.code == 'SettlementOrderReportForm')   settlementReportUrl = item.filenameUrl
      //   if(item.code == 'WaitSettlementOrderReportForm')   waitSettlementReportUrl = item.filenameUrl
      // })

     const supplementInfoList = filesArray.map(item=>{
      return {code:item.code,url:item.url}
     })
      if(flag){
        return this.$createToast({txt:`请上传${tipsName}`,type: "error"}).show();
      }

      this.commiting = true
      let res = null
      if(this.type==1) {
        res =  await saveSupplyInfo({
            // frontUrl,
            // licenseUrl,
            // orderReportUrl,
            // settlementReportUrl,
            // waitSettlementReportUrl,
            supplementInfoList,
            token:''
          })
      }else{
        res =  await updateShopInfo({
            // frontUrl,
            // licenseUrl,
            // orderReportUrl,
            // settlementReportUrl,
            // waitSettlementReportUrl,
            supplementInfoList,
            token:''
          })
      }

      

      if(res.success){
        this.type==1?findNextFlowThenJump():findNextFlowThenJump1()
      }

      this.commiting = false
    },
    handleSuccess(e,index){
      this.filesArray[index].url = e.filenameUrl
    },
    createDom(json){
      if(!json) return
      const childJson = JSON.parse(json)
      this.filesArray =  childJson.map(item=>{
          item.need = parseInt(item.need)
          let imgItem =  {
            uploadType: 1,
            bg: "carme.png",
            accept: "image/png,image/jpeg,image/gif",
            // accept: ".xls,.xlsx,.csv",
            title: "店铺资质(必传)",
            need:0,
            url:'',
            multiple: false,
            files: []
          }
          let pdfItem =  {
            uploadType: 2,
            bg: "carme.png",
            // accept: "image/png,image/jpeg,image/gif",
            accept: "application/pdf",
            title: "店铺资质(必传)",
            need:0,
            successBg:'successBg.png',
            url:'',
            multiple: false,
            files: []
          }

          let excelItem =  {
            uploadType: 3,
            bg: "carme.png",
            // accept: "image/png,image/jpeg,image/gif",
            accept: ".xls,.xlsx,.csv",
            title: "店铺资质(必传)",
            need:0,
            successBg:'successBg.png',
            url:'',
            multiple: false,
            files: []
          }
          let returnItem = null
        if(item.code=="ShopCertQualification"||item.code=="ShopScreenshot"){
          imgItem.need = item.need
          imgItem.code = item.code
          imgItem.title = `${item.name}${item.need=='1'?'(必传)' :'(选传)'}`
          returnItem = imgItem
        }else if(item.code=='CreditReport'){
          pdfItem.need = item.need
          pdfItem.code = item.code
          pdfItem.title = `${item.name}${item.need=='1'?'(必传)' :'(选传)'}`
          returnItem = pdfItem
        }else{
          excelItem.need = item.need
          excelItem.code = item.code
          excelItem.title = `${item.name}${item.need=='1'?'(必传)' :'(选传)'}`
          returnItem = excelItem
        }
        return returnItem
      })
    }
  },
  created(){
    const {childJson,type} = this.$route.query;
    this.type = type
    this.createDom(childJson)
  }
}
</script>
<style lang="less" scoped>
.container{
width: 100%;
background:#F5F7FB url("../../assets//images/img/bg1.png") no-repeat;
background-size: contain;
.title-box{
  margin-top: 27px;
  margin-left: 32px;
  .title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 18px;
  }
  .desc{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 22px;
    margin-top: 12px;
  }
}
.info-faren-main{
  width: 343px;
  display: flex;
  flex-direction: column;
  // height: 500px;
  background: #FFFFFF;
  margin: 17px auto 0;
  border-radius: 8px 8px 8px 8px;
  .info-faren-title-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    .select-box{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      display: flex;
      line-height: 18px;
      span{display: flex;align-items: center;}
      i{
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("../../assets/images/img/no.png") no-repeat;
        background-size: contain;
        margin-left: 10px;
        margin-right: 10px;
        &.yes{
          background: url("../../assets/images/img/yes.png") no-repeat;
        }
      }
    }
  }
  .info-faren-title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
    color: #1C2023;
    line-height: 20px;
    &::after{
      content: '*';
      color:#FA5151;
    }
  }
  .info-faren-content{
    display: flex;
    margin-top: 14px;
    padding: 0 12px;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .tips{
    width: 100%;
    height: 60px;
    background: #FFFBE1;
    border-radius: 8px 8px 0px 0px;
    padding: 17px 10px;
    text-align: left;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #FFA54C;
  }
  .add-btn{
    width: 321px;
    height: 42px;
    text-align: center;
    background: #CCCCCC;
    border-radius: 30px 30px 30px 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 42px;
    margin: 12px auto 0;
    color: #FFFFFF;
    &.on{
      background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
      margin: 10px auto 16px;
    }
  }
}
}
</style>