/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2021-12-06 09:20:52
 * @LastEditors: CZK
 * @LastEditTime: 2024-09-20 16:09:19
 */
export const getCustomH5Platform = () => {
    const { hostname, origin: ip } = location;
  
    function getPlatform() {
      const platform = {
        xafq: {
          name: "豆乐派",
          where: "xafq",
          host:'xafq'
        },
        xiaoai: {
          name: "专属页",
          where: "xiaoai",
          host:'xiaoai'
        },
        lt: {
          name: "新平台",
          where: "xiaoai",
          host:'lt'
        },
        ech5: {
          name: "豆易链",
          where: "ech5",
          host:'ech5'
        },
        dev: {
          platform: "dev",
          name: "开发者",
          where: "xafq",
          host:'xafq'
        }
      };
  
      const platformSign = hostname.split(".")[0];
      const sessionPlanformSign = window.sessionStorage.getItem("platformSign");
      return  platform[platformSign] || platform[sessionPlanformSign] || platform.dev;
    }
  
    const customH5Platform = getPlatform();
  
    // Object.defineProperty(window, "customH5Platform", {
    //   get() {
    //     return customH5Platform;
    //   },
    //   set() {
    //     throw new Error("此属性已定义");
    //   }
    // });
    // 已在main.js定义
    // window.customH5Platform = customH5Platform;
    return customH5Platform;
  };
  