var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "search-box" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.keyword,
              expression: "keyword"
            }
          ],
          attrs: { type: "text", placeholder: "输入关键词" },
          domProps: { value: _vm.keyword },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.keyword = $event.target.value
            }
          }
        }),
        _c("i", {
          staticClass: "iconfont icon-search",
          on: { click: _vm.keywordVal }
        })
      ]),
      _c("img", {
        staticClass: "banner-img",
        attrs: { src: _vm.staticConfig.question_banner, alt: "" }
      }),
      _c(
        "div",
        { staticClass: "navs-box" },
        _vm._l(_vm.navs, function(item, index) {
          return _c(
            "span",
            { key: index, class: index == _vm.navIndex ? "on" : "" },
            [_vm._v(_vm._s(item))]
          )
        }),
        0
      ),
      _vm.helpList.length !== 0 && _vm.helpListLoaded
        ? _c(
            "div",
            { staticClass: "bill-list" },
            [
              _c(
                "cube-scroll",
                {
                  ref: "scroll",
                  attrs: { options: _vm.options },
                  on: {
                    "pulling-down": _vm.onPullingDown,
                    "pulling-up": _vm.onPullingUp
                  }
                },
                _vm._l(_vm.helpList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "history-bill-item border-bottom-1px",
                      on: {
                        click: function($event) {
                          return _vm.viewHelpDetail(item.questionId)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "flex-1 flex flex-nowrap" }, [
                        _c("div", { staticClass: "circle" }),
                        _c("div", { staticClass: "flex-1 bill-item-info" }, [
                          _c("div", { staticClass: "bill-item-name" }, [
                            _vm._v(_vm._s(item.questionContent))
                          ])
                        ]),
                        _c("span", {
                          staticClass:
                            "iconfont icon-jiantou item-right-select-icon"
                        })
                      ])
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm.helpList.length === 0 && _vm.helpListLoaded
        ? _c("div", [
            _c("img", {
              staticClass: "empty-list",
              attrs: { src: require("@/assets/images/empty.png"), alt: "" }
            }),
            _c("div", { staticClass: "c-666 mgt10" }, [_vm._v("暂无记录")])
          ])
        : _c("c-spin", {
            staticStyle: { "margin-top": "40vh", "text-align": "center" },
            attrs: { tip: "Loading...", size: "large" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }