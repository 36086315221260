var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "info-faren-main" }, [
      _vm._m(1),
      _c(
        "div",
        { staticClass: "add-btn on", on: { click: _vm.commit } },
        [
          _vm.commiting ? _c("cube-loading") : _vm._e(),
          _c("span", [_vm._v("下一步")])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("企业开户")]),
      _c("p", { staticClass: "desc" }, [_vm._v("请填写以下资料吧~")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "body-box" }, [
      _c("div", { staticClass: "up-box" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/img/step_2.png") }
        }),
        _c("span", { staticClass: "t" }, [_vm._v("法人确认")]),
        _c("span", { staticClass: "d" }, [
          _vm._v("当前操作需要法人确认，请点击“获取"),
          _c("br"),
          _vm._v("验证码”，并回填接收到的短信验证码")
        ])
      ]),
      _c("div", { staticClass: "faren-form" }, [
        _c("div", { staticClass: "form-item border-bottom-1px" }, [
          _c("label", [_vm._v("法    人")]),
          _c("span", [_vm._v("广州市启蒙玩具有限公司")])
        ]),
        _c("div", { staticClass: "form-item border-bottom-1px" }, [
          _c("label", [_vm._v("手 机 号")]),
          _c("span", [_vm._v("6226********1234")])
        ]),
        _c("div", { staticClass: "form-item border-bottom-1px" }, [
          _c("label", [_vm._v("验 证 码")]),
          _c("input", { attrs: { type: "text", placeholder: "短信验证码" } }),
          _c("div", [_vm._v("获取验证码")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }