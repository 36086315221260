<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-12-11 16:07:03
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-11 16:07:03
-->
<template>
  <div>
    <img :src="url"/>
  </div>
</template>
<script>
export default {
  name:'showImage',
  data(){
    return {
      url:''
    }
  },
  mounted(){
    const {title,imgUrl} = this.$route.query
    this.url = imgUrl
    document.title = title
  }
}
</script>
<style scoped lang="less">
img{
  width:100%;
  height:auto;
  object-fit:contain;
}
</style>