var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.info
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "card" }, [
            _c("p", { staticClass: "date" }, [
              _vm._v(_vm._s(_vm.info.signDate) + " 请款(元)")
            ]),
            _c("div", [
              _c("span", { staticClass: "money" }, [
                _vm._v(_vm._s(_vm.info.loanAmount))
              ]),
              _c("span", { staticClass: "status status01" }, [
                _vm._v(_vm._s(_vm.info.orderStatus))
              ])
            ]),
            _c("p", { staticClass: "small-money" }, [
              _vm._v("剩余本金 " + _vm._s(_vm.info.principalBalance) + " 元")
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "card-item",
              on: {
                click: function($event) {
                  return _vm.toapplyDetail("applyDetail")
                }
              }
            },
            [
              _c("span", [_vm._v("请款明细及协议")]),
              _c("img", {
                attrs: {
                  src: require("../../assets/images/img/right_icon.png"),
                  alt: ""
                }
              })
            ]
          ),
          _c("div", { staticClass: "card-detail" }, [
            _c("div", { staticClass: "header border-bottom-1px" }, [
              _c("span", { staticClass: "title" }, [_vm._v("已还明细")]),
              _c("span", { staticClass: "val" }, [
                _vm._v("共" + _vm._s(_vm.info.repaymentFrequency) + "次还款")
              ])
            ]),
            _c("div", { staticClass: "line" }, [
              _c("span", { staticClass: "title" }, [_vm._v("已还本金")]),
              _c("span", { staticClass: "val" }, [
                _vm._v("¥" + _vm._s(_vm.info.repaidPrincipal || 0))
              ])
            ]),
            _c("div", { staticClass: "line" }, [
              _c("span", { staticClass: "title" }, [_vm._v("已还利息")]),
              _c("span", { staticClass: "val" }, [
                _vm._v("¥" + _vm._s(_vm.info.repaidInterest))
              ])
            ])
          ]),
          _vm._l(_vm.info.repaidList, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "list-item",
                on: {
                  click: function($event) {
                    return _vm.toMenu("repaymentDetails", item.billId)
                  }
                }
              },
              [
                _c("div", { staticClass: "line" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("还款 ¥" + _vm._s(item.repaymentDiff))
                  ]),
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/img/right_icon.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("p", [_vm._v(_vm._s(item.repayTime))])
              ]
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }