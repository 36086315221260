// import { RouteView } from "@/components/layouts";
// 导航组件 还需完善
import RouteView from "@/components/layouts/GuideButtonRouterView";
import { getCustomH5Platform } from "@/utils/isDev";
import { resolve } from "core-js/fn/promise";

const Login = resolve => {
  import("@views/main/Login").then(module => {
    resolve(module);
  });
};



const MainView = resolve => {
  import("@comp/Main/MainView").then(module => {
    resolve(module);
  });
};

// 小爱
// const XaIndex = resolve => {
//   import("@views/repayment/").then(module => {
//     resolve(module);
//   });
// };

const customH5Platform = getCustomH5Platform();
console.log(customH5Platform.where,"平台")
const mainRouterItem = {
  path: "main",
  name: "main",
  component: MainView,
  // redirect: "/repayment/main/mines",
  redirect:  "/main/xaIndex" ,
  meta: {
    title: customH5Platform.name,
    keepAlive: true
  },
  children: [

    {
      path: "xaIndex",
      name: "xaIndex",
      component: ()=> import("@views/main/NewXaIndex"),
      meta: {
        title: "首页",
        keepAlive: true
      }
    },
    {
      path: "xaIndex2",
      name: "xaIndex2",
      component: ()=> import("@views/main/NewXaIndex"),
      meta: {
        title: "首页",
        keepAlive: true
      }
    },
    {
      path: "supplementaryInformation",
      name: "supplementaryInformation",
      component: ()=>import("@views/main/supplementaryInformation"),
      meta: {
        title: "企业补充资料",
        keepAlive: true
      }
    },
    {
      path: "storeInfo",
      name: "storeInfo",
      component: ()=>import("@views/main/storeInfo"),
      meta: {
        title: "店铺信息",
        keepAlive: true
      }
    },
    {
      path: "additionalInformation",
      name: "additionalInformation",
      component: ()=>import("@views/main/additionalInformation"),
      meta: {
        title: "补充资料",
        keepAlive: true
      }
    },
    {
      path: "result",
      name: "result",
      component: ()=>import("@views/main/result"),
      meta: {
        title: "审核结果",
        keepAlive: true
      }
    },
    {
      path: "withdrawalApplication",
      name: "withdrawalApplication",
      component: ()=>import("@views/main/withdrawalApplication"),
      meta: {
        title: "借款",
        keepAlive: true
      }
    },
    {
      path: "receivingAccount",
      name: "receivingAccount",
      component: ()=>import("@views/main/receivingAccount"),
      meta: {
        title: "添加收款账户",
        keepAlive: true
      }
    },
    {
      path: "changeReceivingAccount",
      name: "changeReceivingAccount",
      component: ()=>import("@views/main/changeReceivingAccount"),
      meta: {
        title: "更改收款账户",
        keepAlive: true
      }
    },

    {
      path: "bindStoreAccount",
      name: "bindStoreAccount",
      component: ()=>import("@views/main/bindStoreAccount"),
      meta: {
        title: "绑定店铺账号",
        keepAlive: true
      }
    },
    {
      path: "selectOpenBlank",
      name: "selectOpenBlank",
      component: ()=>import("@views/main/selectOpenBlank"),
      meta: {
        title: "选择开户行",
        keepAlive: true
      }
    },
    {
      path: "selectCity",
      name: "selectCity",
      component: ()=>import("@views/main/selectCity"),
      meta: {
        title: "选择所在城市",
        keepAlive: true
      }
    },
    {
      path: "SelectTheBranchYouBelongTo",
      name: "SelectTheBranchYouBelongTo",
      component: ()=>import("@views/main/SelectTheBranchYouBelongTo"),
      meta: {
        title: "选择所属支行",
        keepAlive: true
      }
    },
    
    {
      path: "loading",
      name: "loading",
      component: ()=>import("@views/main/loading"),
      meta: {
        title: "loading",
      }
    },
    {
      path: "applyRecode",
      name: "applyRecode",
      component: ()=>import("@views/main/applyRecode"),
      meta: {
        title: "请款记录",
      }
    },
    {
      path: "applyDetail",
      name: "applyDetail",
      component: ()=>import("@views/main/applyDetail"),
      meta: {
        title: "请款详情",
      }
    },
    {
      path: "mine",
      name: "mine",
      component: ()=>import("@views/main/mine"),
      meta: {
        title: "我的账单",
      }
    },
    {
      path: "billingDetails",
      name: "billingDetails",
      component: ()=>import("@views/main/billingDetails"),
      meta: {
        title: "账单详情",
      }
    },
    {
      path: "repaymentDetails",
      name: "repaymentDetails",
      component: ()=>import("@views/main/repaymentDetails"),
      meta: {
        title: "还款详情",
      }
    },

    {
      path: "receivingAccountList",
      name: "receivingAccountList",
      component: ()=>import("@views/main/receivingAccountList"),
      meta: {
        title: "收款账户",
      }
    },

    {
      path: "receivingAccountDetail",
      name: "receivingAccountDetail",
      component: ()=>import("@views/main/receivingAccountDetail"),
      meta: {
        title: "收款账户详情",
      }
    },

    {
      path: "transactionDetails",
      name: "transactionDetails",
      component: ()=>import("@views/main/transactionDetails"),
      meta: {
        title: "交易详情",
      }
    },

    {
      path: "settlementAccount",
      name: "settlementAccount",
      component: ()=>import("@views/main/settlementAccount"),
      meta: {
        title: "结算账户",
      }
    },
    {
      path: "AgreementView",
      name: "AgreementView",
      component: ()=>import("@views/main/AgreementView"),
      meta: {
        title: "协议合同",
      }
    },

    {
      path: "helpCenter",
      name: "helpCenter",
      component: ()=>import("@views/main/HelpCenter"),
      meta: {
        title: "常见问题"
      }
    },

    {
      path: "HelpDetail",
      name: "HelpDetail",
      component: ()=>import("@views/main/HelpDetail"),
      meta: {
        title: "问题详情"
      }
    },

    {
      path: "relationInfo",
      name: "relationInfo",
      component: ()=>import("@views/main/relationInfo"),
      meta: {
        title: "关联信息"
      }
    },

    {
      path: "enterpriseSupplementInfo",
      name: "enterpriseSupplementInfo",
      component: ()=>import("@views/main/enterpriseSupplementInfo"),
      meta: {
        title: "企业补充信息"
      }
    },

    {
      path: "companyInformation",
      name: "companyInformation",
      component: ()=>import("@views/main/companyInformation"),
      meta: {
        title: "企业信息"
      }
    },
    {
      path: "showImage",
      name: "showImage",
      component: ()=>import("@views/main/showImage"),
      meta: {
        title: "展示图片"
      }
    },

    {
      path: "maintenance",
      name: "maintenance",
      component: ()=>import("@views/main/maintenance"),
      meta: {
        title: "系统维护中"
      }
    },

    {
      path: "openAccount",
      name: "openAccount",
      component: ()=>import("@views/main/openAccount"),
      meta: {
        title: "企业开户"
      }
    },
    {
      path: "openAccount_step_1",
      name: "openAccount_step_1",
      component: ()=>import("@views/main/openAccount_step_1"),
      meta: {
        title: "企业开户"
      }
    },
    {
      path: "openAccount_step_2",
      name: "openAccount_step_2",
      component: ()=>import("@views/main/openAccount_step_2"),
      meta: {
        title: "企业开户"
      }
    },

    {
      path: "openAccount_step_3",
      name: "openAccount_step_3",
      component: ()=>import("@views/main/openAccount_step_3"),
      meta: {
        title: "企业开户"
      }
    },
  ]
};
const loginRouterItem = {
  path: "login",
  name: "login",
  component: Login,
  meta: {
    title: customH5Platform.name
  }
};

const repaymentRouter = {
  path: "/",
  name: "repaymentMain",
  // redirect: "/repayment/mine",
  // redirect: "/repayment/main/mines",
  redirect: "/main/xaIndex",
  component: RouteView,
  meta: { title: "付款流程" },
  children: [
    // {
    //   path: "main",
    //   name: "main",
    //   component: MainView,
    //   redirect: "/repayment/main/mines",
    //   meta: {
    //     title: "豆乐派",
    //     keepAlive: true
    //   },
    //   children: [
    //     {
    //       path: "xaIndex",
    //       name: "xaIndex",
    //       component: XaIndex,
    //       meta: {
    //         title: "首页",
    //         keepAlive: true
    //       }
    //     },
    //     {
    //       path: "mines",
    //       name: "mines",
    //       component: Mine,
    //       meta: {
    //         title: "我的",
    //         keepAlive: true
    //       }
    //     },
    //   ]
    // },
    // {
    //   path: "login",
    //   name: "login",
    //   component: Login,
    //   meta: {
    //     title: "豆乐派"
    //   }
    // },
    mainRouterItem,
   loginRouterItem,
    // {
    //   path: "myBankCard",
    //   name: "myBankCard",
    //   component: MyBankCard,
    //   meta: {
    //     title: "我的银行卡",
    //   }
    // },
    // {
    //   path: "myBankCardDetail",
    //   name: "myBankCardDetail",
    //   component: MyBankCardDetail,
    //   meta: {
    //     title: "银行卡详情",
    //   }
    // },
    // {
    //   path: "aboutUs",
    //   name: "aboutUs",
    //   component: AboutUs,
    //   meta: {
    //     title: "关于我们"
    //   }
    // },
    // {
    //   path: "invitation",
    //   name: "invitation",
    //   component: invitation,
    //   meta: {
    //     title: "邀请新人"
    //   }
    // },
    // {
    //   path: "cooperation",
    //   name: "cooperation",
    //   component: cooperation,
    //   meta: {
    //     title: "合作"
    //   }
    // },
    
    
    // {
    //   path: "customerService",
    //   name: "customerService",
    //   component: CustomerService,
    //   meta: {
    //     title: "我的客服"
    //   }
    // },
    // {
    //   path: "helpCenter",
    //   name: "helpCenter",
    //   component: HelpCenter,
    //   meta: {
    //     title: "常见问题"
    //   }
    // },
    
    // {
    //   path: "helpDetail",
    //   name: "helpDetail",
    //   component: HelpDetail,
    //   meta: {
    //     title: "问题详情"
    //   }
    // },
    // {
    //   path: "mySetting",
    //   name: "mySetting",
    //   component: MySetting,
    //   meta: {
    //     title: "安全设置"
    //   }
    // },
    // {
    //   path: "msgCenter",
    //   name: "msgCenter",
    //   component: MsgCenter,
    //   meta: {
    //     title: "消息中心"
    //   }
    // },
    // {
    //   path: "msgDetail",
    //   name: "msgDetail",
    //   component: MsgDetail,
    //   meta: {
    //     title: "消息中心"
    //   }
    // },
    // {
    //   path: "loanApplyP",
    //   name: "loanApplyP",
    //   component: LoanApplyP,
    //   meta: {
    //     title: "申请订单",
    //     keepAlive: true
    //   }
    // },
    
    // {
    //   path: "fddResult",
    //   name: "fddResult",
    //   component: FddResult,
    //   meta: {
    //     title: "实名认证中"
    //   }
    // },
    // {
    //   path: "kuangshiResult",
    //   name: "kuangshiResult",
    //   component: kuangshiResult,
    //   meta: {
    //     title: "视频审核中"
    //   }
    // },
    // {
    //   path: "FddAuthResult",
    //   name: "FddAuthResult",
    //   component: FddAuthResult,
    //   meta: {
    //     title: "授权中"
    //   }
    // },
    // {
    //   path: "fillBaseInfo",
    //   name: "fillBaseInfo",
    //   component: FillBaseInfo,
    //   meta: {
    //     title: "补充个人信息"
    //   }
    // },
    // {
    //   path: "additionalInfo",
    //   name: "additionalInfo",
    //   component: AdditionalInfo,
    //   meta: {
    //     title: "补充资料"
    //   }
    // },
    // {
    //   path: "editAdditionalInfo",
    //   name: "editAdditionalInfo",
    //   component: editAdditionalInfo,
    //   meta: {
    //     title: "补充资料"
    //   }
    // },
    // {
    //   path: "fillContactsInfo",
    //   name: "fillContactsInfo",
    //   component: FillContactsInfo,
    //   meta: {
    //     title: "常用联系人"
    //   }
    // },
    // {
    //   path: "bindCard",
    //   name: "bindCard",
    //   component: BindCard,
    //   meta: {
    //     title: "添加银行卡"
    //   }
    // },
    // {
    //   path: "reBindCard",
    //   name: "reBindCard",
    //   component: ReBindCard,
    //   meta: {
    //     title: "添加银行卡"
    //   }
    // },
    // {
    //   path: "likeWechat",
    //   name: "likeWechat",
    //   component: LikeWechat,
    //   meta: {
    //     title: "关注微信"
    //   }
    // },
    // {
    //   path: "modifyMobile",
    //   name: "modifyMobile",
    //   component: ModifyMobile,
    //   meta: {
    //     title: "修改手机号"
    //   }
    // },
    // {
    //   path: "modifyMobileRebind",
    //   name: "modifyMobileRebind",
    //   component: ModifyMobileRebind,
    //   meta: {
    //     title: "更改绑卡鉴权确认"
    //   }
    // },
    // {
    //   path: "mineInfo",
    //   name: "mineInfo",
    //   component: MineInfo,
    //   meta: {
    //     title: "我的资料"
    //   }
    // },
    // {
    //   path: "accountUnregister",
    //   name: "accountUnregister",
    //   component: AccountUnregister,
    //   meta: {
    //     title: "账号注销"
    //   }
    // }
  ]
};

export default repaymentRouter;
