/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-10-15 15:37:26
 * @LastEditors: CZK
 * @LastEditTime: 2024-10-15 16:13:44
 */
export const bankImg = {
  '北京银行':require('@/assets/images/bank/北京银行@2x.png'),
  '渤海银行':require('@/assets/images/bank/渤海银行@2x.png'),
  '甘肃银行':require('@/assets/images/bank/甘肃银行@2x.png'),
  '工商银行':require('@/assets/images/bank/工商银行@2x.png'),
  '光大银行':require('@/assets/images/bank/光大银行@2x.png'),
  '广东省农村信用社':require('@/assets/images/bank/广东省农村信用社@2x.png'),
  '广发银行':require('@/assets/images/bank/广发银行@2x.png'),
  '河北银行':require('@/assets/images/bank/河北银行@2x.png'),
  '湖州银行':require('@/assets/images/bank/湖州银行@2x.png'),
  '华夏银行':require('@/assets/images/bank/华夏银行@2x.png'),
  '徽商银行':require('@/assets/images/bank/徽商银行@2x.png'),
  '建设银行':require('@/assets/images/bank/建设银行@2x.png'),
  '江苏银行':require('@/assets/images/bank/江苏银行@2x.png'),
  '交通银行':require('@/assets/images/bank/交通银行@2x.png'),
  '兰州银行':require('@/assets/images/bank/兰州银行@2x.png'),
  '民生银行':require('@/assets/images/bank/民生银行@2x.png'),
  '南京银行':require('@/assets/images/bank/南京银行@2x.png'),
  '内蒙古银行':require('@/assets/images/bank/内蒙古银行@2x.png'),
  '农业银行':require('@/assets/images/bank/农业银行@2x.png'),
  '平安银行':require('@/assets/images/bank/平安银行@2x.png'),
  '浦发银行':require('@/assets/images/bank/浦发银行@2x.png'),
  '上海银行':require('@/assets/images/bank/上海银行@2x.png'),
  '微众银行':require('@/assets/images/bank/微众银行@2x.png'),
  '兴业银行':require('@/assets/images/bank/兴业银行@2x.png'),
  '邮储银行':require('@/assets/images/bank/邮储银行@2x.png'),
  '招商银行':require('@/assets/images/bank/招商银行@2x.png'),
  '浙商银行':require('@/assets/images/bank/浙商银行@2x.png'),
  '中国银行':require('@/assets/images/bank/中国银行@2x.png'),
  '中信银行':require('@/assets/images/bank/中信银行@2x.png'),
  '中原银行':require('@/assets/images/bank/中原银行@2x.png'),
  '默认':require('@/assets/images/bank/默认@2x.png')
}