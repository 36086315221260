<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-10-29 13:58:38
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-11 10:27:43
-->
<template>
  <div class="container"  v-if="info">
    <p class="title">全部应还总额(元)</p>
    <p class="num">{{info.principalBalanceTotal}}</p>
    <div class="content">
      <div class="header border-bottom-1px">
        <span :class="{on:index===0}" @click="changeIndex(0)">未还账单</span>
        <span :class="{on:index===1}" @click="changeIndex(1)">已还账单</span>
      </div>
      <div class="scroll-box">
        <!-- <cube-scroll
          ref="scroll"
          :direction="'vertical'"
          :data="items"
          @pulling-up="onPullingUp"
          :options="options"> -->

          <div class="item border-bottom-1px" v-for='(item,index) in list' :key='index' @click="toMenu('billingDetails',item.applyId)">
            <div class="line">
              <span class="money">¥{{item.loanAmount||0}}</span> <span class="money1" v-if="item.principalBalance">¥{{item.principalBalance||0}}</span>
            </div>
            <div class="line">
              <span class="date">{{item.signDate}} 请款</span> <span class="status" v-if="item.principalBalance">剩余本金</span>
            </div>
          </div>
        <!-- </cube-scroll> -->
         <empty v-if='list.length===0'/>
      </div>
    </div>
  </div>
</template>
<script>
  import {getMyBill} from '@/api/user'
  import empty from './empty.vue'
  export default {
    name: "Mine",
    components:{empty},
    data() {
      return {
        items:[1,1,2,1,4,4,4,5,5,5],
        index:0,
        options: {
          pullUpLoad: {
            threshold: 0,
            visible: true,
            txt: { more: "", noMore: "已加载完毕" }
          }
        },
        info:null,
        list:[]
      }
    },
    methods:{
      toMenu(name,applyId){
        this.$router.push({name,query:{applyId}})
      },
      changeIndex(i){
        this.index = i
        const arr = [this.info.noRepaymentList||[],this.info.repaymentList||[]]
        this.list = arr[i]
      },
      async getInfo(){
        let res =await getMyBill({token:''})
        if(res.success){
           this.info = res.data
           this.list = res.data.noRepaymentList	||[]
        }
      }
    },
    mounted() {
      this.getInfo()
    }
  }
</script>
<style lang="less" scoped>
  .container {
    width: 100%;
    background: #F5F7FB url(../../assets/images/img/mine_bg.png) no-repeat;
    background-size: 100% auto;
    .title{
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
      text-align:center;
      margin-top:30px;
    }
    .num{
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 36px;
      margin-top:5px;
      text-align:center;
      color: #FFFFFF;
      line-height: 45px;
    }
    .content{
      width: 343px;
      background: #FFFFFF;
      padding:0 10px;
      margin:30px auto 0;
      box-shadow: 0px 3 8px 0px rgba(35,96,170,0.1);
      border-radius: 8px 8px 8px 8px;
      // overflow-y: scroll;
      min-height:calc(100vh - 130px);
      -webkit-overflow-scrolling: touch;
      .item{
        width:100%;
        height: 70px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 26px 0 6px;
        .line{
          display:flex;
          align-items: center;
          justify-content: space-between;
          &:last-child{
            margin-top: 7px;
          }
          &:first-child{
            position: relative;
            &::after{
              content: '';
              background: url("../../assets/images/img/right_icon.png") no-repeat center center;
              background-size: 24px 24px;
              width: 24px;
              height: 24px;
              display: block;
              position: absolute;
              right: -30px;
            }
          }
          .money{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
          }
          .money1{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #009DFF;
          }
          .date{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
          }
          .status{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
          }
        }
      }
      .header{
        width:100%;
        height:50px;
        display:flex;
        justify-content:space-between;
        align-items:center;
        span{
          flex: 1;
          text-align: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
          line-height: 18px;
          transition: 0.3s;
          &.on{
            position: relative;
            color:#009DFF;
            &::after{
              content:'';
              width: 56px;
              height: 2px;
              display:block;
              position: absolute;
              margin: 0 auto;
              left: 0;
              right: 0;
              bottom: -5px;
              background: #009DFF;
            }
          }
        }

      }
    }
  }
</style>