<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-12-09 18:18:36
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-11 14:33:14
-->
<template>
  <div class="container">
    <div class="search-box">
      <input type="text" placeholder="输入关键词" v-model="keyword">
      <i class="iconfont icon-search" @click="keywordVal"></i>
    </div>
    <img :src="staticConfig.question_banner" alt="" class="banner-img">
    <div class="navs-box">
      <span v-for="(item,index) in navs" :key="index" :class="index==navIndex?'on':''">{{item}}</span>
    </div>
    <div v-if="helpList.length !== 0 && helpListLoaded" class="bill-list">
      <cube-scroll
        ref="scroll"
        @pulling-down="onPullingDown"
        @pulling-up="onPullingUp"
        :options="options"
      >
        <!-- <img class="aboutus-pic w340" src="@assets/images/aboutus/aboutus-6.png" /> -->
        <div
          v-for="(item,index) in helpList"
          :key="index"
          class="history-bill-item border-bottom-1px"
          @click="viewHelpDetail(item.questionId)"
        >
          <div class="flex-1 flex flex-nowrap">
            <div class="circle"></div>
            <div class="flex-1 bill-item-info">
              <div class="bill-item-name">{{item.questionContent}}</div>
            </div>
            <span class="iconfont icon-jiantou item-right-select-icon"></span>
          </div>
        </div>
      </cube-scroll>
    </div>
    <div v-else-if="helpList.length === 0 && helpListLoaded">
      <img class="empty-list" src="@/assets/images/empty.png" alt />
      <div class="c-666 mgt10">暂无记录</div>
    </div>
    <c-spin v-else style="margin-top:40vh;text-align: center;" tip="Loading..." size="large"></c-spin>
  </div>
</template>

<script type="text/ecmascript-6">
import { querySysQuestions } from "@/api/user";

export default {
  name: "",
  components: {},
  data() {
    return {
      options: {
        pullDownRefresh: {
          threshold: 60,
          stop: 40,
          txt: "更新成功"
        },
        pullUpLoad: {
          threshold: 0,
          visible: true,
          txt: { more: "", noMore: "已加载完毕" }
        }
      },
      pageInfo: {
        rows: 20,
        page: 1,
        questionType:'0'
      },
      keyword:'',
      helpListLoaded: false,
      helpList: [],
      navIndex:0,
      navs:['全部'],
      oldSource:[]
    };
  },
  computed: {},
  methods: {
    //跳转
    viewHelpDetail(questionId) {
      this.$router.push({
        name: "helpDetail",
        query: { questionId }
      });
    },
    //请求数据
    getHelpList(init) {
      init ? (this.pageInfo.page = 1) : this.pageInfo.page++;

      return new Promise((resolve, reject) => {
        const handleCallback = res => {
          if (res.success) {
            const helpList = res.rows;
            if (init) {
              this.helpList = [...helpList];
            } else {
              if (res.page >= this.pageInfo.page) {
                this.helpList = this.helpList.concat(helpList);
              }
            }
            this.oldSource=this.helpList
            if(this.keyword) this.keywordVal()
            this.helpListLoaded = true;
            this.$refs.scroll && this.$refs.scroll.forceUpdate(true);
            resolve(res);
          } else {
            reject();
          }
        };
        querySysQuestions({ rows:this.pageInfo.rows.toString(), page:this.pageInfo.page.toString(), questionType:this.pageInfo.questionType }).then(handleCallback);
      });
    },
    // 模糊搜索商户门店
    keywordVal() {
      const val = this.keyword;
      if (val.length === 0) {
        this.helpList = [...this.oldSource];
      } else {
        this.helpList = this.oldSource.filter((item) => {
          return (item.questionContent && item.questionContent.includes(val));
        });
      }
    },
    onPullingDown() {
      this.getHelpList(true);
    },
    onPullingUp() {
      this.getHelpList(false);
    }
  },
  created() {
    this.getHelpList(true);
  }
};
</script>

<style scoped lang="less">
.container {
  padding-top: 16px;
  .navs-box{
    width: 375px;
    height: 40px;
    opacity: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    padding: 0 26px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    span{
      position: relative;
      &.on{
      color: #4E75FA;
      &::after{
        content: '';
        width: 20px;
        height: 4px;
        background: #4E75FA;
        border-radius: 10px;
        position: absolute;
        bottom: -12px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    }
    
  }
  .search-box{
    width: 333px;
    height: 28px;
    background: #F5F7F9;
    border: 1px solid #F0F5FC;
    opacity: 1;
    border-radius: 14px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    margin-bottom: 16px;
    padding: 0 16px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 0px;
    color: #969696;
    i{
      color: #333;
      font-size: 20px;
      font-weight: 600;
    }
    input{
      flex: 1;
      text-align: center;
      background:transparent;
      outline: 0;
    }
  }
  .banner-img{
    width: 100%;
    height: 108px;
    object-fit: contain;
  }
  .aboutus-pic {
    display: block;
    width: 100%;
    margin:0 auto 0;
  }

  .w340 {
    width: 340px;
  }

  .bill-list {
    padding: 0 16px;
    height: calc(100vh - 200px);
    overflow-y: scroll;

    .history-bill-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 54px;
      font-size: 12px;
      color: #666;
    }

    .bill-item-info {
      margin-left: 20px;
      text-align: left;
    }

    .bill-item-name {
      font-size: 16px;

      color: rgba(51, 51, 51, 1);
      
      float: left;
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .bill-item-time {
      margin-top: 12px;
      font-size: 14px;
      color: #666;
    }

    .bill-item-right {
      //line-height: 74px;
      min-width: 60px;
      padding-right: 10px;
      text-align: right;
      font-size: 15px;
      // background: cornflowerblue;

      div {
        //display: inline-block;

        &.bill-item-amount {
        }

        &.bill-item-status {
          margin-top: 10px;
          color: #666;
        }
      }
    }

    .item-right-select-icon {
      font-size: 12px;
      margin-right: -4px;
      color: #cdcdd1;
    }
  }

  .empty-list {
    width: 248px;
    height: 184px;
    margin-top: 50px;
  }

  .circle {
    position: absolute;
    width: 6px;
    height: 6px;
    background:#2090ff;
    border-radius: 50%;
    border: 1px solid #2090ff;
  }
}
</style>
