var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "info-faren-main" }, [
      _c("div", { staticClass: "tips" }, [
        _vm._v(
          "请上传证件彩色原件扫描图片或照片。支持jpg，jpeg，bmp或gif格式，单个文件大小5MB"
        )
      ]),
      _c("div", { staticClass: "body-box" }, [
        _vm._m(1),
        _c("div", { staticClass: "upload-company-box border-bottom-1px" }, [
          _c("div", { staticClass: "upload-company-show-image" }, [
            _vm.form.businessLicenseUrl
              ? _c(
                  "div",
                  {
                    staticClass: "default-image",
                    on: {
                      click: function($event) {
                        return _vm.see(_vm.form.businessLicenseUrl)
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "d",
                      attrs: { src: _vm.form.businessLicenseUrl }
                    })
                  ]
                )
              : _c("div", { staticClass: "default-image" }, [
                  _c("img", {
                    staticClass: "d",
                    attrs: { src: require("../../assets/images/img/d-img.jpg") }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "see-btn",
                      on: {
                        click: function($event) {
                          return _vm.see()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/img/see.png")
                        }
                      }),
                      _c("span", [_vm._v("查看样本")])
                    ]
                  )
                ])
          ]),
          _c(
            "div",
            { staticClass: "upload-company-btn " },
            [
              _c("OSSUpload", {
                ref: "upload1",
                staticClass: "ossupload",
                attrs: {
                  type: _vm.form.idCard.uploadType,
                  backgroundImgName: _vm.form.idCard.bg,
                  accept: _vm.form.idCard.accept,
                  classType: true,
                  title: _vm.form.idCard.title,
                  files: _vm.form.idCard.files,
                  multiple: _vm.form.idCard.multiple
                },
                on: {
                  success: function(e) {
                    return _vm.getCompanyUrl(e, "form")
                  },
                  "update:files": function($event) {
                    return _vm.$set(_vm.form.idCard, "files", $event)
                  }
                }
              }),
              _c("button", { staticClass: "on", on: { click: _vm.upload } }, [
                _vm._v("上传")
              ])
            ],
            1
          )
        ]),
        _c("div", { staticClass: "faren-form" }, [
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", { staticClass: "on" }, [_vm._v("企业名称")]),
            _c("span", [_vm._v(_vm._s(_vm.form.name))])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", { staticClass: "on" }, [_vm._v("企业类型")]),
            _c("span", [_vm._v(_vm._s(_vm.form.enterpriseType))])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", { staticClass: "on" }, [_vm._v("统一社会信用代码")]),
            _c("span", [_vm._v(_vm._s(_vm.form.creditCode))])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", { staticClass: "on" }, [_vm._v("成立时间")]),
            _c("span", [_vm._v(_vm._s(_vm.form.foundTime))])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", { staticClass: "on" }, [_vm._v("经营地址")]),
            _c("span", [_vm._v(_vm._s(_vm.form.address))])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", { staticClass: "on" }, [_vm._v("国标行业代码")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.enterpriseTrade,
                  expression: "form.enterpriseTrade"
                }
              ],
              attrs: {
                type: "text",
                maxlength: 10,
                placeholder: "请输入国标行业代码"
              },
              domProps: { value: _vm.form.enterpriseTrade },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "enterpriseTrade", $event.target.value)
                }
              }
            })
          ])
        ]),
        _c(
          "div",
          { staticClass: "add-btn on", on: { click: _vm.commit } },
          [
            _vm.commiting ? _c("cube-loading") : _vm._e(),
            _c("span", [_vm._v("下一步")])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("企业信息")]),
      _c("p", { staticClass: "desc" }, [_vm._v("Hi，请填写以下资料吧~")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-faren-title-box" }, [
      _c("p", { staticClass: "info-faren-title on" }, [_vm._v("营业执照")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }