<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-09-26 16:15:28
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-12 10:09:10
-->
<template>
  <div class="container">
    <div class="title-box">
      <p class="title">企业补充信息</p>
      <p class="desc">Hi，请填写以下资料吧~</p>
    </div>

    <div class="info-faren-main">
      <div class="info-faren-title-box">
        <p class="info-faren-title on">企业法人信息</p>
      </div>
      <div class="info-faren-content">
        <OSSUpload 
        v-for="(item, index) in faren.idCard" 
        :key="index" 
        :type="item.uploadType"   
        :backgroundImgName="item.bg" 
        :accept="item.accept"
        ref="upload1" 
        :title="item.title"
        @success="(e)=>{return handleSuccess(e,'faren',index)}"
        :files.sync="item.files" 
        :multiple="item.multiple"/>
      </div>
      <div class="tips">请确认以下信息是否正确，如有误请重新识别</div>

      <div class="faren-form">
        <div class="form-item border-bottom-1px">
          <label>真实姓名</label>
          <span>{{faren.name}}</span>
        </div>
        <div class="form-item border-bottom-1px">
          <label>身份证号</label>
          <span>{{faren.idCardNo}}</span>
        </div>
        <div class="form-item border-bottom-1px">
          <label>签发机关</label>
          <span>{{faren.signIssued}}</span>
        </div>
        <div class="form-item border-bottom-1px">
          <label>有效期限</label>
          <span>{{faren.effectiveStartDate}}-{{faren.effectiveEndDate}}</span>
        </div>
        <div class="form-item border-bottom-1px">
          <label>联系电话</label>
          <input type="text" :maxlength="11" v-model="faren.phone" placeholder="请填写法人实名手机号">

          <div class="select-box">
            <span @click="changeFaren"><i :class="{yes:faren.contactStatus==1}"></i>同本人</span>
          </div>
        </div>

        <div class="form-item border-bottom-1px">
          <label>电子邮箱</label>
          <input type="text"  v-model="faren.email" placeholder="请填写电子邮箱">
        </div>
      </div>
    </div>

    <div class="info-faren-main">
      <div class="info-faren-title-box">
        <p class="info-faren-title on">企业联系人信息</p>
        <div class="select-box">
          <span @click="changeLianxiren"><i :class="{yes:lianxiren.contactStatus==1}"></i>同法人</span>
        </div>
      </div>

      <div class="info-faren-content">
        <OSSUpload 
        v-for="(item, index) in lianxiren.idCard" 
        :key="index" 
        :type="item.uploadType"   
        :backgroundImgName="item.bg" 
        :accept="item.accept" 
        @success="(e)=>{return handleSuccess(e,'lianxiren',index)}"
        ref="upload1" 
        :title="item.title" 
        :files.sync="item.files" 
        :multiple="item.multiple"/>
      </div>
      <div class="tips">请确认以下信息是否正确，如有误请重新识别</div>

      <div class="faren-form">
        <div class="form-item border-bottom-1px">
          <label>真实姓名</label>
          <span>{{lianxiren.name}}</span>
        </div>
        <div class="form-item border-bottom-1px">
          <label>身份证号</label>
          <span>{{lianxiren.idCardNo}}</span>
        </div>
        <div class="form-item border-bottom-1px">
          <label>签发机关</label>
          <span>{{lianxiren.signIssued}}</span>
        </div>
        <div class="form-item border-bottom-1px">
          <label>有效期限</label>
          <span>{{lianxiren.effectiveStartDate}}-{{lianxiren.effectiveEndDate}}</span>
        </div>
        <div class="form-item border-bottom-1px">
          <label>联系电话</label>
          <input type="text" :maxlength="11" v-model="lianxiren.phone" placeholder="请填写法人实名手机号">
        </div>
        <div class="form-item border-bottom-1px" v-if='!isSame'>
          <label>验证码</label>
          <input type="text" :maxlength="6" v-model="authCode" placeholder="验证码">
          <div
            class="get-code"
            @click.stop.prevent="getCaptcha"
          >{{ state.smsSendBtn ? state.time + 's后重发' : '获取验证码'}}</div>
        </div>
        <div class="form-item border-bottom-1px">
          <label>电子邮箱</label>
          <input type="text"  v-model="lianxiren.email" placeholder="请填写电子邮箱">
        </div>
      </div>
    </div>

    <!-- <div class="info-faren-main"  v-for="(item,index) in konggu" :key="item.radom">
        <div class="info-faren-title-box">
          <p class="info-faren-title" :class="{on:index===0}">企业控股股东信息{{ index+1 }}</p>
          <div class="select-box">
            <span  @click="changeGudong(index,1)"><i :class="{yes:item.shareholderStatus==1}"></i>同法人</span>
            <span  @click="changeGudong(index,2)"><i :class="{yes:item.shareholderStatus==2}"></i>同联系人</span>
          </div>
        </div>
        <div class="info-faren-content">
          <OSSUpload 
          v-for="(jtem, jndex) in item.idCard" 
          :key="jtem.bg" 
          :type="jtem.uploadType"   
          :backgroundImgName="jtem.bg" 
          :accept="jtem.accept" 
          ref="upload1" 
          @success="(e)=>{return handleSuccess1(e,'konggu',index,jndex)}"
          :title="jtem.title" 
          :files.sync="jtem.files" 
          :multiple="jtem.multiple"/>
        </div>
        <div class="tips">请确认以下信息是否正确，如有误请重新识别</div>

        <div class="faren-form">
          <div class="form-item border-bottom-1px">
            <label>真实姓名</label>
            <span>{{item.name}}</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>身份证号</label>
            <span>{{item.idCardNo}}</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>签发机关</label>
            <span>{{item.signIssued}}</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>有效期限</label>
            <span>{{item.effectiveStartDate}}-{{item.effectiveEndDate}}</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>联系电话</label>
            <input type="text" :maxlength="11" v-model="item.phone" placeholder="请填写股东本人手机号">
          </div>
          <div class="form-item border-bottom-1px">
            <label>电子邮箱</label>
            <input type="text"  v-model="item.email" placeholder="请填写电子邮箱">
          </div>
        </div>
      <div class="add-btn" v-if="index==konggu.length-1" @click="addKonggu">+添加企业控股信息</div>
    </div>

    <div class="info-faren-main"  v-for="(item,index) in shouyiren" :key="index">
        <div class="info-faren-title-box">
          <p class="info-faren-title" :class="{on:index===0}">企业最终受益人信息{{ index+1 }}</p>
          <div class="select-box">
            <span @click="changeShouyiren(index,1)"><i :class="{yes:item.shareholderStatus==1}"></i>同法人</span>
            <span @click="changeShouyiren(index,2)"><i :class="{yes:item.shareholderStatus==2}"></i>同联系人</span>
          </div>
        </div>

        <div class="info-faren-content">
          <OSSUpload 
          v-for="(jtem, jndex) in item.idCard" 
          :key="jtem.bg" 
          :type="jtem.uploadType"   
          :backgroundImgName="jtem.bg" 
          :accept="jtem.accept" 
          @success="(e)=>{return handleSuccess1(e,'shouyiren',index,jndex)}"
          ref="upload1" 
          :title="jtem.title" 
          :files.sync="jtem.files" 
          :multiple="jtem.multiple"/>
        </div>
        <div class="tips">请确认以下信息是否正确，如有误请重新识别</div>

        <div class="faren-form">
          <div class="form-item border-bottom-1px">
            <label>真实姓名</label>
            <span>{{item.name}}</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>身份证号</label>
            <span>{{item.idCardNo}}</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>签发机关</label>
            <span>{{item.signIssued}}</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>有效期限</label>
            <span>{{item.effectiveStartDate}}-{{item.effectiveEndDate}}</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>联系电话</label>
            <input type="text" :maxlength="11" v-model="item.phone" placeholder="请填写股东本人手机号">
          </div>

          <div class="form-item border-bottom-1px">
            <label>电子邮箱</label>
            <input type="text"  v-model="item.email" placeholder="请填写电子邮箱">
          </div>

          <div class="form-item border-bottom-1px">
            <label>联系地址</label>
            <input type="text" v-model="item.address" placeholder="请填写企业最终受益人联系地址">
          </div>

          <div class="form-item1 border-bottom-1px">
            <label>受益方式</label>
            <div class="types-box">
              <p v-for="(ttem,tndex) in types" :key="ttem" @click="changeType(index,'shouyiren',tndex+1)"><i :class="{yes:tndex==(item.benefitType-1)}"></i><span>{{ ttem }}</span></p>
            </div>
          </div>
        </div>
      <div class="add-btn" v-if="index==shouyiren.length-1" @click="addshouyiren">+添加企业受益人信息</div>
          </div>
 -->
      <div class="add-btn on" @click="commit">
        <cube-loading v-if="commiting"></cube-loading>
        <span>下一步</span>
      </div>


  </div>
</template>
<script type="text/ecmascript-6">
import OSSUpload from "@comp/Upload/OSSUpload";
import { resetImageBlob } from "@/utils/getOssImageBlobUrl";
// import fontCard from '@comp/fontCard/index'
import {findNextFlowThenJump} from "./findNextFlow"
import {uploadIdCard,saveRelationInfo,getEnterpriseAuthInfo,sendSms} from "@api/user";
const LIMITTIME = 60;
export default {
  name: "SupplementaryInformation",
  components: {
    // fontCard,
    OSSUpload
  },
  // 受益方式 1-直接或间接25%(含)以上 2-通过人事、财务等其他方对公司进行控制 3-高级管理员 4-法人或公司负责人 5-其他
  data(){
    return {
      authCode:'',
      authId:'',
      state: {
        time: LIMITTIME,
        smsSendBtn: false
      },
      types:['直接或间接控股25%（含）以上','通过人事、财务等其他方对公司 进行控制','高级管理人员','法人或公司负责人','其他'],
      kongguItem:{
          radom:Math.random(),
          shareholderStatus: 0,
          phone:'',
          effectiveEndDate:'',
          effectiveStartDate:'',
          identityFrontUrl:'',
          identityReverseUrl:'',
          signIssued:'',
          email:'',
          idCardNo:'',
          name:'',
          idCard:[
            {
              uploadType: 1,
              bg: "font_bg.png",
              accept: "image/png,image/jpeg,image/gif",
              // accept: ".xls,.xlsx,.csv",
              title: "上传身份证头像面",
              multiple: false,
              files: []
            },
            {
              uploadType: 1,
              bg: "back_bg.png",
              accept: "image/png,image/jpeg,image/gif",
              // accept: ".xls,.xlsx,.csv",
              title: "上传身份证国徽面",
              multiple: false,
              files: []
            }
          ]
        },
      shouyirenItem:{
          shareholderStatus: 0,
          phone:'',
          effectiveEndDate:'',
          effectiveStartDate:'',
          identityFrontUrl:'',
          identityReverseUrl:'',
          signIssued:'',
          idCardNo:'',
          name:'',
          email:'',
          address:'',
          benefitType:1,
          idCard:[
            {
              uploadType: 1,
              bg: "font_bg.png",
              accept: "image/png,image/jpeg,image/gif",
              // accept: ".xls,.xlsx,.csv",
              title: "上传身份证头像面",
              multiple: false,
              files: []
            },
            {
              uploadType: 1,
              bg: "back_bg.png",
              accept: "image/png,image/jpeg,image/gif",
              // accept: ".xls,.xlsx,.csv",
              title: "上传身份证国徽面",
              multiple: false,
              files: []
            }
          ]
      },
      shouyiren:[
      {
          shareholderStatus: 0,
          phone:'',
          effectiveEndDate:'',
          effectiveStartDate:'',
          signIssued:'',
          idCardNo:'',
          email:'',
          identityFrontUrl:'',
          identityReverseUrl:'',
          name:'',
          address:'',
          benefitType:1,
          idCard:[
            {
              uploadType: 1,
              bg: "font_bg.png",
              accept: "image/png,image/jpeg,image/gif",
              // accept: ".xls,.xlsx,.csv",
              title: "上传身份证头像面",
              multiple: false,
              files: []
            },
            {
              uploadType: 1,
              bg: "back_bg.png",
              accept: "image/png,image/jpeg,image/gif",
              // accept: ".xls,.xlsx,.csv",
              title: "上传身份证国徽面",
              multiple: false,
              files: []
            }
          ]
      },
      ],
      konggu:[
        {
          radom:Math.random(),
          shareholderStatus: 0,
          phone:'',
          effectiveEndDate:'',
          effectiveStartDate:'',
          signIssued:'',
          idCardNo:'',
          identityFrontUrl:'',
          identityReverseUrl:'',
          name:'',
          email:'',
          idCard:[
            {
              uploadType: 1,
              bg: "font_bg.png",
              accept: "image/png,image/jpeg,image/gif",
              // accept: ".xls,.xlsx,.csv",
              title: "上传身份证头像面",
              multiple: false,
              files: []
            },
            {
              uploadType: 1,
              bg: "back_bg.png",
              accept: "image/png,image/jpeg,image/gif",
              // accept: ".xls,.xlsx,.csv",
              title: "上传身份证国徽面",
              multiple: false,
              files: []
            }
          ]
        }
      ],
      faren:{
        phone:'',
        effectiveEndDate:'',
        effectiveStartDate:'',
        signIssued:'',
        idCardNo:'',
        name:'',
        contactStatus:0,
        email:'',
        identityFrontUrl:'',
        identityReverseUrl:'',
        idCard:[
          {
            uploadType: 1,
            bg: "font_bg.png",
            accept: "image/png,image/jpeg,image/gif",
            // accept: ".xls,.xlsx,.csv",
            title: "上传身份证头像面",
            multiple: false,
            files: []
          },
          {
            uploadType: 1,
            bg: "back_bg.png",
            accept: "image/png,image/jpeg,image/gif",
            // accept: ".xls,.xlsx,.csv",
            title: "上传身份证国徽面",
            multiple: false,
            files: []
          }
        ]
      },
      lianxiren:{
        contactStatus:0,
        phone:'',
        effectiveEndDate:'',
        effectiveStartDate:'',
        signIssued:'',
        identityFrontUrl:'',
        identityReverseUrl:'',
        idCardNo:'',
        email:'',
        name:'',
        idCard:[
          {
            uploadType: 1,
            bg: "font_bg.png",
            accept: "image/png,image/jpeg,image/gif",
            // accept: ".xls,.xlsx,.csv",
            title: "上传身份证头像面",
            multiple: false,
            files: []
          },
          {
            uploadType: 1,
            bg: "back_bg.png",
            accept: "image/png,image/jpeg,image/gif",
            // accept: ".xls,.xlsx,.csv",
            title: "上传身份证国徽面",
            multiple: false,
            files: []
          }
        ]
      },
      commiting:false
    }
  },
  computed:{
    isSame(){
      return this.lianxiren.phone == this.$ls.get("loginMobile")
    }
  },
  methods: {
    async getInfo(){
     
     let res =  await getEnterpriseAuthInfo({token:""})
     if(res.success){
      /**
       * 
        address
        : 
        "广东省惠来县前詹镇铭东管区铭东新村十横巷7之1号"
        backgroundIdCardUrl
        : 
        "https://testapi.fadada.com:8443/api/get_file.api?app_id=403133&v=2.0&timestamp=20241009182130&uuid=984ec13596ec4f158f3943ef1c46e356&msg_digest=RjI5RTk2NjFDOTg3MjBERDVCNTY2Mzg0RkI0MEQzRDNCQTY2ODJCNA=="
        expiresDate
        : 
        "2036-01-28"
        headPhotoUrl
        : 
        "https://testapi.fadada.com:8443/api/get_file.api?app_id=403133&v=2.0&timestamp=20241009182130&uuid=fc60d881346b44d596c905d27bb11963&msg_digest=QTk5OUNDNjdBQzk1RkZFMTE4NkY0NTQ4QUQwOTAxRkJCNEVERDkwNQ=="
        idCard
        : 
        "445224198903090962"
        issueAuthority
        : 
        "惠来县公安局"
        name
        : 
        "林喜旋"
        phone
        : 
        "15918648674"
        startDate
        : 
        "2016-01-28"
        type
        : 
        "1"
       */
      // let img = 'http://test-011.oss-cn-shenzhen.aliyuncs.com/app_file_prd_1237509448591884288_1728469748911.jpg'
        const {address,backgroundIdCardUrl,expiresDate,headPhotoUrl,idCard,issueAuthority,name,phone,startDate,type} = res.data
        let keyName = type=='1'?'faren':'lianxiren'
        this[keyName].phone = phone
        this[keyName].signIssued  = issueAuthority
        this[keyName].idCardNo = idCard
        this[keyName].name = name
        this[keyName].identityFrontUrl = headPhotoUrl 
        this[keyName].identityReverseUrl = backgroundIdCardUrl
        this[keyName].effectiveStartDate = startDate
        this[keyName].effectiveEndDate = expiresDate
        if(headPhotoUrl){
          let imgurl = headPhotoUrl.replace('http://test-011.oss-cn-shenzhen.aliyuncs.com','')
          let utl = await resetImageBlob(imgurl)
          this.$set(this[keyName].idCard[0].files, 0, utl)
        }
        if(backgroundIdCardUrl){
          let imgurl1 = backgroundIdCardUrl.replace('http://test-011.oss-cn-shenzhen.aliyuncs.com','')
          let utl1 = await resetImageBlob(imgurl1)
          this.$set(this[keyName].idCard[1].files, 0, utl1)
        }
        
     }
    },
    changeType(index,KeyName,type){
      this[KeyName][index].benefitType = type
      this.$forceUpdate()
    },
    //法人 联系人
    async handleSuccess(e,KeyName,index){
      if(e.status == 'success'){
        const {filenameUrl}=e
        const toast = this.$createToast({txt: '识别中...',type: "loading",mask:true,time:999});
        toast.show()
        let res = await uploadIdCard({url:filenameUrl,token:''})
        toast.hide()
        if(res.success){
          const{data} = res
          if(data){
            const {address,birthDate,ethnicity,idNumber,issueAuthority,name,sex,type,validPeriod}=data
            if(type=='face'){
              this[KeyName].idCardNo = idNumber
              this[KeyName].name = name
              this[KeyName].identityFrontUrl = filenameUrl
            }else{
              const validPeriodDate = validPeriod.split('-')
              this[KeyName].effectiveStartDate = validPeriodDate[0].replace(/\./g,'-')
              this[KeyName].effectiveEndDate = validPeriodDate[1].replace(/\./g,'-')
              this[KeyName].signIssued = issueAuthority
              this[KeyName].identityReverseUrl = filenameUrl
            }
          }else{
            if(index==0){
              this[KeyName].idCardNo = ''
              this[KeyName].name = ''
              this[KeyName].identityFrontUrl = ''
            }else{
              this[KeyName].effectiveStartDate = ''
              this[KeyName].effectiveEndDate = ''
              this[KeyName].signIssued = ''
              this[KeyName].identityReverseUrl = ''
            }
            this.$createToast({txt: res.message,type: "error"}).show();
          }
        }else{
          this.$createToast({txt: '识别失败，请重试~',type: "error"}).show();
        }
        console.log(res,'识别结果')
      }else{
        this.$createToast({txt: '上传失败，请重试~',type: "error"}).show();
      }
    },
    //股东受益人
    async handleSuccess1(e,KeyName,index,jndex){
      if(e.status == 'success'){
        const {filenameUrl}=e
        const toast = this.$createToast({txt: '识别中...',type: "loading",mask:true,time:999});
        toast.show()
        let res = await uploadIdCard({url:filenameUrl,token:''})
        toast.hide()
        if(res.success){
          const{data} = res
          if(data){
            const {address,birthDate,ethnicity,idNumber,issueAuthority,name,sex,type,validPeriod}=data
            if(type=='face'){
              this[KeyName][index].idCardNo = idNumber
              this[KeyName][index].name = name
              this[KeyName][index].identityFrontUrl = filenameUrl
            }else{
              const validPeriodDate = validPeriod.split('-')
              this[KeyName][index].effectiveStartDate = validPeriodDate[0].replace(/\./g,'-')
              this[KeyName][index].effectiveEndDate = validPeriodDate[1].replace(/\./g,'-')
              this[KeyName][index].signIssued = issueAuthority
              this[KeyName][index].identityReverseUrl = filenameUrl
            }
          }else{
            if(jndex==0){
              this[KeyName][index].idCardNo = ''
              this[KeyName][index].name = ''
              this[KeyName][index].identityFrontUrl = ''
            }else{
              this[KeyName][index].effectiveStartDate = ''
              this[KeyName][index].effectiveEndDate = ''
              this[KeyName][index].signIssued = ''
              this[KeyName][index].identityReverseUrl = ''
            }
            this.$createToast({txt: res.message,type: "error"}).show();
          }
        }else{
          this.$createToast({txt: '识别失败，请重试~',type: "error"}).show();
        }
        console.log(res,'识别结果')
      }else{
        this.$createToast({txt: '上传失败，请重试~',type: "error"}).show();
      }
    },
    changeGudong(index,status){
      let itemStatus = this.konggu[index].shareholderStatus;
      if(itemStatus===status){
        this.konggu[index] = this.kongguItem
      } 
      else {
        console.log(this.konggu,'---------===================')
        if(status==1){
          this.konggu[index] = {...this.faren,shareholderStatus:status}
        }else{
          this.konggu[index] = {...this.lianxiren,shareholderStatus:status}
        }

        console.log(this.faren,'................法人',this.konggu[index])
      }
      this.$forceUpdate()
    },
    changeShouyiren(index,status){
      let itemStatus = this.shouyiren[index].shareholderStatus;
      if(itemStatus===status) this.shouyiren[index] = this.shouyirenItem
      else {
        if(status==1){
          this.shouyiren[index] = {...this.faren,shareholderStatus:status,benefitType:1,address:''}
        }else{
          this.shouyiren[index] = {...this.lianxiren,shareholderStatus:status,benefitType:1,address:''}
        }
      }
      this.$forceUpdate()
    },
    changeLianxiren(){
      this.lianxiren.contactStatus = 1-this.lianxiren.contactStatus;
      if(this.lianxiren.contactStatus===1){
        this.lianxiren = {...this.faren,contactStatus:1}
      }else{
        this.lianxiren = {
        contactStatus:0,
        phone:'',
        effectiveEndDate:'',
        effectiveStartDate:'',
        signIssued:'',
        idCardNo:'',
        name:'',
        idCard:[
          {
            uploadType: 1,
            bg: "font_bg.png",
            accept: "image/png,image/jpeg,image/gif",
            // accept: ".xls,.xlsx,.csv",
            title: "上传身份证头像面",
            multiple: false,
            files: []
          },
          {
            uploadType: 1,
            bg: "back_bg.png",
            accept: "image/png,image/jpeg,image/gif",
            // accept: ".xls,.xlsx,.csv",
            title: "上传身份证国徽面",
            multiple: false,
            files: []
          }
        ]
      }
      }
    },
    addshouyiren(){
      if(this.shouyiren.length==4) return this.$createToast({txt: "最多只能添加四个",type: "error"}).show();
       this.shouyiren.push(this.shouyirenItem)
    },
    changeFaren(){
      
      this.faren.contactStatus = 1-this.faren.contactStatus;
      if(this.faren.contactStatus===1){
        this.faren.phone = this.$ls.get("loginMobile")
      }else{
        this.faren.phone = ''
      }
    },
    //恢复按钮
    regainCheckBtn(state) {
      state.time = LIMITTIME;
      state.smsSendBtn = false;
    },
    //获取验证码
    getCaptcha() {
      const {state } = this;
      // if(!this.showCode) return
      if (!this.lianxiren.phone) {
        this.$createToast({
          type: "warn",
          txt: "请输入联系人手机号"
        }).show();
        return;
      }
      setTimeout(() => {
        if (this.lianxiren.phone.length > 0) {
          state.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              this.regainCheckBtn(state);
              window.clearInterval(interval);
            }
          }, 1000);
          const toastSuccess = this.$createToast({
            txt: "验证码发送中",
            type: "correct",
            time: 0
          });
          toastSuccess.show();
          sendSms({mobile:this.lianxiren.phone, authCodeType:'UserLogin'})
            .then(res => {
              toastSuccess.hide();
              if (res && res.success && res.data && res.data.authId) {
                this.$createToast({
                  txt: "验证码已发送，请留意",
                  type: "correct"
                }).show();
                // 储存tattedId
                const tattedId = res.data.authId;
                this.authId = tattedId;
              } else {
                clearInterval(interval);
                this.regainCheckBtn(state);
              }
            })
            .catch(err => {
              console.log('err', err)
              toastSuccess.hide();
              clearInterval(interval);
              this.regainCheckBtn(state);
            });
        } else {
          this.$createToast({
            txt: "请确认手机号",
            type: "error"
          }).show();
        }
      }, 0);
    },
    addKonggu(){
       if(this.konggu.length==4) return this.$createToast({txt: "最多只能添加四个",type: "error"}).show();
       this.konggu.push(this.kongguItem)
    },
    async commit(){
      if(this.commiting) return;
      const{faren,konggu,shouyiren,lianxiren,authCode,authId}=this
      console.log(faren,'法人身份证========')
      if(!faren.identityFrontUrl||!faren.identityReverseUrl){
        return this.$createToast({txt: "请上传法人身份证",type: "error"}).show();
      }


      if(!faren.phone){
        return this.$createToast({txt: "请输入法人联系电话",type: "error"}).show();
      }

      //检查手机号的合法性
      if(!/^1[34578]\d{9}$/.test(faren.phone)){
        return this.$createToast({txt: "请输入正确的联系电话",type: "error"}).show();
      }

      if(!faren.email){
        return this.$createToast({txt: "请输入法人电子邮箱",type: "error"}).show();
      }


      // 检查邮箱的合法性
      if(!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(faren.email)){
        return this.$createToast({txt: "请输入正确的电子邮箱",type: "error"}).show();
      }
      

      if(!lianxiren.identityFrontUrl||!lianxiren.identityReverseUrl){
        return this.$createToast({txt: "请上传联系人身份证",type: "error"}).show();
      }


      if(!lianxiren.phone){
        return this.$createToast({txt: "请输入联系人联系电话",type: "error"}).show();
      }

      //检查手机号的合法性
      if(!/^1[34578]\d{9}$/.test(lianxiren.phone)){
        return this.$createToast({txt: "请输入正确的联系电话",type: "error"}).show();
      }

      if(!this.isSame&&!authCode){
        return this.$createToast({txt: "请输入验证码",type: "error"}).show();
      }

      if(!lianxiren.email){
        return this.$createToast({txt: "请输入联系人电子邮箱",type: "error"}).show();
      }


      // 检查邮箱的合法性
      if(!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(lianxiren.email)){
        return this.$createToast({txt: "请输入正确的电子邮箱",type: "error"}).show();
      }

      // const kongguOne = konggu[0]
      // if(!kongguOne.identityFrontUrl||!kongguOne.identityReverseUrl){
      //   return this.$createToast({txt: "请上传首个股东身份证",type: "error"}).show();
      // }


      // if(!kongguOne.phone){
      //   return this.$createToast({txt: "请输入首个股东联系电话",type: "error"}).show();
      // }

      // //检查手机号的合法性
      // if(!/^1[34578]\d{9}$/.test(kongguOne.phone)){
      //   return this.$createToast({txt: "请输入正确的联系电话",type: "error"}).show();
      // }

      // if(!kongguOne.email){
      //   return this.$createToast({txt: "请输入首个股东电子邮箱",type: "error"}).show();
      // }


      // // 检查邮箱的合法性
      // if(!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(kongguOne.email)){
      //   return this.$createToast({txt: "请输入正确的电子邮箱",type: "error"}).show();
      // }

      // const shouyirenOne = shouyiren[0]
      // if(!shouyirenOne.identityFrontUrl||!shouyirenOne.identityReverseUrl){
      //   return this.$createToast({txt: "请上传首个受益人身份证",type: "error"}).show();
      // }


      // if(!shouyirenOne.phone){
      //   return this.$createToast({txt: "请输入首个受益人联系电话",type: "error"}).show();
      // }

      // //检查手机号的合法性
      // if(!/^1[34578]\d{9}$/.test(shouyirenOne.phone)){
      //   return this.$createToast({txt: "请输入正确的联系电话",type: "error"}).show();
      // }

      // if(!shouyirenOne.email){
      //   return this.$createToast({txt: "请输入首个受益人电子邮箱",type: "error"}).show();
      // }


      // // 检查邮箱的合法性
      // if(!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(shouyirenOne.email)){
      //   return this.$createToast({txt: "请输入正确的电子邮箱",type: "error"}).show();
      // }

      
      this.commiting = true
      const lawPerson =  Object.keys(faren).filter(key => key !== 'idCard').reduce((acc, key) => {
          acc[key] = faren[key];
          return acc;
        }, {});
      
      const contactPerson =  Object.keys(faren).filter(key => key !== 'idCard').reduce((acc, key) => {
        acc[key] = lianxiren[key];
        return acc;
      }, {});
      
      // console.log(konggu,'股东==========')
      // const haskonggu = konggu.filter(item => item.identityFrontUrl&&item.identityReverseUrl)
      
      // const shareholderPersons = haskonggu.map(item=>{
      //   console.log(Object.keys(this.kongguItem).filter(key => key !== 'idCard'),'股东过滤')
      //   return Object.keys(this.kongguItem).filter(key => key !== 'idCard').reduce((acc, key) => {
      //     acc[key] = item[key];
      //     return acc;
      //   }, {});
      // })
      // console.log(shouyiren,'=============shouyiren1')
      // const hasshouyiren = shouyiren.filter(item => item.identityFrontUrl&&item.identityReverseUrl)

      // const benefitPersons = hasshouyiren.map(item=>{
        
      //   return Object.keys(this.shouyirenItem).filter(key => key !== 'idCard').reduce((acc, key) => {
      //     acc[key] = item[key];
      //     return acc;
      //   }, {});
      // })

      // console.log(lawPerson,'法人',contactPerson,'===',shareholderPersons,'........',benefitPersons)

     let res =  await saveRelationInfo({
        authId,
        authCode,
        lawPerson,contactPerson,
        // shareholderPersons,benefitPersons,
        token:''
      })

      if(res.success){
        findNextFlowThenJump()
      }
      this.commiting = false
    }
  },
  created(){
    this.lianxiren.phone = this.$ls.get("loginMobile")
    // this.getInfo()
  }
}
</script>
<style lang="less" scoped>
input{
all: unset;
}
.container{
width: 100%;
background:#F5F7FB url("../../assets//images/img/bg1.png") no-repeat;
background-size: contain;
.title-box{
  margin-top: 27px;
  margin-left: 32px;
  .title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 18px;
  }
  .desc{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 22px;
    margin-top: 12px;
  }
}
.info-faren-main{
  width: 343px;
  // height: 500px;
  background: #FFFFFF;
  margin: 17px auto 0;
  border-radius: 8px 8px 8px 8px;
  padding: 14px 12px 12px;
  .info-faren-title-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    .select-box{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      display: flex;
      line-height: 18px;
      span{display: flex;align-items: center;}
      i{
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("../../assets/images/img/no.png") no-repeat;
        background-size: contain;
        margin-left: 10px;
        margin-right: 10px;
        &.yes{
          background: url("../../assets/images/img/yes.png") no-repeat;
          background-size: contain;
        }
      }
    }
  }
  .info-faren-title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
    color: #1C2023;
    line-height: 20px;
    &.on{
      &::after{
        content: '*';
        color:#FA5151;
      }
    }
    
  }
  .info-faren-content{
    display: flex;
    justify-content: space-between;
  }
  .tips{
    width: 321px;
    height: 40px;
    background: #FFFBE1;
    border-radius: 8px 8px 8px 8px;
    padding: 10px 0 10px 6px;
    text-align: left;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin: 14px 0;
    color: #FFA54C;
  }
  .faren-form{
    .form-item1{
      width: 100%;
      display: flex;
      justify-content: space-between;
      min-height: 50px;
      label{
        width: 56px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 50px;
        margin-right: 32px;
      }
      .types-box{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 18px;
        margin-top: 16px;
        p{
          display: flex;
          margin-bottom: 11px;
        }
        i{
          display: inline-block;
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          background: url("../../assets/images/img/no.png") no-repeat;
          background-size: contain;
          margin-right: 10px;
          &.yes{
            background: url("../../assets/images/img/yes.png") no-repeat;
            background-size: contain;
          }
        }
      }
    }
    .form-item{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      .select-box{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        flex-shrink: 0;
        display: flex;
        padding-right: 12px;
        line-height: 18px;
        span{display: flex;align-items: center;}
        i{
          display: inline-block;
          width: 16px;
          height: 16px;
          background: url("../../assets/images/img/no.png") no-repeat;
          background-size: contain;
          margin-left: 10px;
          margin-right: 10px;
          &.yes{
            background: url("../../assets/images/img/yes.png") no-repeat;
            background-size: contain;
          }
        }
      }
      label{
        width: 56px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 20px;
        margin-right: 32px;
      }
      .get-code{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
      input{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        &::placeholder{
          color: #999999;
        }
      }
      span{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
    }
  }
  
}

.add-btn{
    width: 321px;
    height: 42px;
    text-align: center;
    background: #CCCCCC;
    border-radius: 30px 30px 30px 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 42px;
    margin: 12px auto 0;
    color: #FFFFFF;
    &.on{
      background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
      margin: 10px auto 16px;
    }
  }
}
</style>