/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-10-10 09:32:26
 * @LastEditors: CZK
 * @LastEditTime: 2024-10-10 09:33:42
 */
// import { axios } from "./requestCarFinance";
// import axios from "axios";
import { axioss } from "@/utils/requestOss";

/**
 * 下载blob 设置
 * @param {*} url
 */
export function getImageBlobURL(url = "") {
  // 转化为https
  const urlhttps = url.replace("http://", "https://");
  return axioss
    .get(urlhttps, {
      responseType: "blob",
      params: {}
    })
    .then((res) => {
      console.log(res,"返回值")
      // 离开页面后可 revokeObjectURL 释放
      return Promise.resolve(URL.createObjectURL(res));
    })
    .catch((err) => {
      console.log(err,"错误返回值")
      return Promise.reject(err);
    });
}

/**
 * 设置 blob:url 返回 cube-ui格式
 * @param {*} url 图片url;string
 */
export function resetImageBlob(url = "") {
  return getImageBlobURL(url)
    .then((res) => {
      const item = {
        url: res,
        status: "success",
        progress: 1,
        response: {
          success: true,
          message: "操作成功",
          errMsg: "操作成功",
          data: {
            url,
          },
          other: null,
        },
      };
      return Promise.resolve(item);
    })
    .catch((err) => {
      console.log("err", err);
      const item = {
        url: "",
        status: "error",
        progress: 1,
        response: {
          success: false,
          message: "回显失败",
          errMsg: "回显失败",
          data: {
            url,
          },
          other: null,
        },
      };
      return Promise.reject(item);
    });
}
