/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-10-10 09:32:37
 * @LastEditors: CZK
 * @LastEditTime: 2024-10-10 09:32:55
 */
import axios from "axios";
import { JSEncrypt } from "jsencrypt";
import CryptoJS from "crypto-js";
import router from "@/router";
import store from "@/store";
import {
  transformRequestFn,
  paramsSerializer,
  handleErrorMsg,
} from "./request";
import { Toast } from "cube-ui";
import { RSA_PUBLIC_KEY, DEBUG, ENV_KEY } from "@/config/config";
import { freshToken } from "@/api/user";

const service = axios.create({
  baseURL: "/ossUpdata",
  timeout: 60000,
  withCredentials: true,
  transformRequest: [transformRequestFn],
  paramsSerializer,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

// function getLocalToken() {
//   const token = window.localStorage.getItem("token");
//   return token;
// }

// service.setToken = (token) => {
//   service.defaults.headers["X-Token"] = token;
//   window.localStorage.setItem("token", token);
// };

// const refreshToken = ()=>{
//   return service({
//     url: "/freshToken",
//     method: "post",
//     data: {token:window.localStorage.getItem("token"), freshToken:window.localStorage.getItem("freshToken")},
//     headers: {
//       "isRefresh":true
//     }
//   });
// }

const refreshToken = ()=>{
  let token = ''
  if (window.localStorage.getItem("token")&&!window.localStorage.getItem("token")=='undefined') token = ''
  else token = window.localStorage.getItem("token")
  return freshToken({token:token, freshToken:window.localStorage.getItem("freshToken")});
}

let isRefreshing = false;

let requests = [];

const errorMsgMap = {
  "401": "错误代码401，登录超时，请重新登录",
  "403": "错误代码403，登录超时，请重新登录",
  "404": "错误代码404，Not Found",
  "500": "错误代码500，服务器错误",
};

const err = (error) => {
  
  console.log("接口出错了=>",error)
  if (error.response) {
    const { statusText, status } = error.response;
    const errorCode = errorMsgMap[status];
    const fullPath = window.location.pathname + window.location.search
    const loginPath ='/main/login'
    if (errorCode) {
      if (status === 403) {
        console.log("403 ===============");
        window.localStorage.setItem("token", "");
        store.commit('SET_ISLOGIN', false);
        //403跳转登录页
        console.log(window.location.pathname,'路由',window.location.search);
        // 不是登录页才缓存
        if(loginPath!=fullPath) window.localStorage.setItem('redirect',fullPath);
        router.replace({
          name: "login"
        });
      } else if (status === 401) {
        
        const config = error.config;
        console.log("401 ===============",config.headers.NeedAuthorization != false);
        
        console.log(window.location.pathname,'路由',window.location.search);
        // 不是登录页才缓存
        if(loginPath!=fullPath) window.localStorage.setItem('redirect',fullPath);
        
        if (config.headers.NeedAuthorization != false) {
          if (!isRefreshing) {
            isRefreshing = true;
           
            return refreshToken()
              .then((res) => {
                let token = res.data;
                console.log("chenyh get a new token : " + token);
                //保存token
                window.localStorage.setItem("token", token);
                //第二次调url会拼上baseURL导致路径不对
                if (config.baseURL) {
                  config.baseURL = "";
                }
                window.localStorage.setItem("token", "");
                store.commit('SET_ISLOGIN', false);
                
                router.replace({ // 重新登录需传递原始路径
                  name: "login"
                });
                return service(config);
              })
              .catch((res) => {
                console.error("refreshtoken error =>", res);
                //window.location.href = "/repayment/login";
                window.localStorage.setItem("token", "");
                store.commit('SET_ISLOGIN', false);
                
                router.replace({ // 重新登录需传递原始路径
                  name: "login"
                });
              })
              .finally(() => {
                isRefreshing = false;
              });
          } else {
            return new Promise((resolve) => {
              requests.push(() => {
                //config.headers["X-Token"] = token;
                resolve(service(config));
              });
            });
          }
        }
      } else {
        Toast.$create({
          txt: errorCode,
          type: "error",
          time: 2200,
        }).show();
      }
    } else {
      let tipText = (statusText=='null'||statusText==null)?'操作失败':statusText
      Toast.$create({
        txt: "error",
        type: `error ${tipText}`,
        time: 1200,
      }).show();
    }
  } else {
    Toast.$create({
      txt: "error",
      type: `error`,
      time: 1200,
    }).show();
  }
  return Promise.reject(error);
};

service.interceptors.request.use((config) => {
  if (config.method == "get") {
    //console.log("method get");
    
    //请求参数添加当前时间
    config.params.sendTime = getServerTime();
    //请求参数添加资管Id
    config.params.manageId = getManageId();
    //请求类型
    config.params.req = getRequestType();
    //添加token
    addTokenIfNeed(config);

    //加签
    config.params.sign = getParamSign(config.params);
    console.log("加签后参数: ", config.params);

  } else if (config.method == "post") {
    //console.log("method post");

    //如果参数为String，则反序列化
    if (typeof(config.data) === "string") {
      config.data = JSON.parse(config.data);
      console.log("chenyh post 反序列化 : ", config.data);
    }
    //请求参数添加当前时间
    config.data.sendTime = getServerTime();
    //请求参数添加资管Id
    config.data.manageId = getManageId();
    //请求类型
    config.data.req = getRequestType();
    //添加token
    addTokenIfNeed(config);

    //加签
    config.data.sign = getParamSign(config.data);
    console.log("加签后参数: ", config.data);

  }
  return config;
}, err);

service.interceptors.response.use((response) => {
  const res = response.data.response || response.data;

  console.log("xafq ajax 返回: ", res);

  if (res.success === true) {
    return res;
  } else if (res.success === false) {
    // 如果当前商户机构状态为"暂停进件"或“订单分期金额 ＞当前机构 “剩余额度””
    if (res.code == '10027') {
      router.replace({
        name: "exceeding",
      });
    } else {
      Toast.$create({
        txt: handleErrorMsg(res),
        type: "warn",
        time: 4000,
      }).show();
      return res;
    }
    
  } else {
    // TODO 图片流 文件流捕获
    if (res instanceof Blob) {
      return res;
    }  
    return Promise.reject(new Error(res.message || "Error"));
  }
}, err);

//chenyh 20201010
/**
 * 添加token
*/
function addTokenIfNeed(obj) {
  
  if (obj.method == "get") {
    if (obj.params.token == undefined) {
      //console.log("chenyh token is undefined ");
      //无token字段，忽略
    } else {
      //console.log("chenyh token is not undefined ");
      //有token字段，添加token
      // 优化token的取值 如果没值或'undefined'时直接赋空
      let cacheToken = window.localStorage.getItem("token")
      if (cacheToken && cacheToken !== 'undefined') {
       obj.params.token = cacheToken
      }else{
        obj.params.token = ''
      }
    }
  } else if (obj.method == "post") {
    if (obj.data.token == undefined) {
      //console.log("chenyh token is undefined ");
      //无token字段，忽略
    } else {
      //console.log("chenyh token is not undefined ");
      //有token字段，添加token
      // 优化token的取值 如果没值或'undefined'时直接赋空
      let cacheToken = window.localStorage.getItem("token")
      if (cacheToken && cacheToken !== 'undefined') {
       obj.data.token = cacheToken
      }else{
        obj.data.token = ''
      }
    }
  }
}

/**
 * 排序
 */
function objKeySort(obj) {
  var newkey = Object.keys(obj).sort();
  var newObj = {};
  for (var i = 0; i < newkey.length; i++) {
    newObj[newkey[i]] = obj[newkey[i]];
  }
  return newObj;
}

/**
 * MD5并大写
*/
function getMd5Str(obj) {
  return CryptoJS.MD5(JSON.stringify(obj)).toString().toUpperCase();
}

/**
 * RSA
*/
function getRsaStr(obj, publicKey) {
  let encrypt = new JSEncrypt();
  if (null == publicKey) {
    //console.log("Rsa publicKey is null");
  } else {
    //console.log("Rsa publicKey is " + publicKey)
  }
  encrypt.setPublicKey(publicKey);
  return encrypt.encrypt(obj);
}

/**
 * 接口签名
 * 
*/
function getParamSign(obj) {
  //1.剔除sign字段
  delete obj.sign;
  //console.log("加签前参数: ", obj);

  //2.请求参数排序
  const sortData = objKeySort(obj);
  //console.log("chenyh 参数排序: ", sortData);

  //3.md5
  const md5Str = getMd5Str(sortData);
  //console.log("chenyh md5: ", md5Str);

  //4.RSA
  const rsaStr = getRsaStr(md5Str, RSA_PUBLIC_KEY);
  //console.log("chenyh RSA: ", rsaStr);

  return rsaStr;
}

/**
 * 获取本地与服务器的时间差
 * 
*/
function getTimeDiff() {
  const currentTimeDiff = window.localStorage.getItem("currentTimeDiff");
  //console.log("chenyh currentTimeDiff is " + currentTimeDiff);
  return null == currentTimeDiff ? 0 : Number(currentTimeDiff);
}

/**
 * 获取服务器时间
 * 
*/
function getServerTime() {
  return (Date.now() - getTimeDiff()).toString();
}

/**
 * 获取资管Id
 * 
*/
function getManageId() {
  // if (DEBUG) {
  //   return ENV_KEY[0].debugKey;
  // } else {
  //   return ENV_KEY[0].releaseKey;
  // }
  if (process.env.NODE_ENV === 'production') {
    return ENV_KEY[0].releaseKey;
  } else {
    return ENV_KEY[0].debugKey;
  }
}

/**
 * 请求类型
*/
function getRequestType() {
  return "h5";
}

export { service as axioss, getParamSign, getServerTime, addTokenIfNeed, getManageId };
