import { render, staticRenderFns } from "./applyDetail.vue?vue&type=template&id=33d4c1a3&scoped=true&"
import script from "./applyDetail.vue?vue&type=script&lang=js&"
export * from "./applyDetail.vue?vue&type=script&lang=js&"
import style0 from "./applyDetail.vue?vue&type=style&index=0&id=33d4c1a3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d4c1a3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/workspace/ecommerce_h5_dev_front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('33d4c1a3', component.options)
    } else {
      api.reload('33d4c1a3', component.options)
    }
    module.hot.accept("./applyDetail.vue?vue&type=template&id=33d4c1a3&scoped=true&", function () {
      api.rerender('33d4c1a3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/main/applyDetail.vue"
export default component.exports