<!-- 处理商户、资管、总控后台二维码登录 统一处理  -->
<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2021-08-18 11:00:25
 * @LastEditors: CZK
 * @LastEditTime: 2024-09-20 17:29:18
-->
<template>
  <div class="login-holder" v-if="!loading">
    <div v-if="status==1&&weChatType" class="form-box">
      <div class="logo-mian-box">
        <img src="@/assets/images/main/empty.png" alt="" class="empty-img">
        <!-- <img v-if="where === 'Back'" class="logo-img" src="@/assets/images/logo06.png" alt="后台管理系统" />
        <img  v-if="where === 'Manage'" class="logo-img" src="@/assets/images/logo04.png" alt="资管管理系统" />
        <img v-if="where === 'Asset'" class="logo-img" src="@/assets/images/logo05.png" alt="商户管理系统" />
        <img v-if="where === 'partner'" class="logo-img" src="@/assets/images/parnet_home_logo.png" alt="合伙人后台系统" /> -->
        <h1>豆易链</h1>
      </div>
      <p class="tip_message"  v-if="isBind==1">你的帐号{{phone}}请求登录系统</p>
       <div class="message-box1"  v-if="isBind==0">
         <span class="svg-container"> <img src="@/assets/images/icons/mine_02.png" alt=""></span>
          <input
            ref="username"
            v-model.trim="loginForm.mobile"
            placeholder="手机号"
            name="username"
            type="number"
            maxlength="11"
            oninput="if(value.length>11) value = value.slice(0,11)"
            onkeyup="this.value=this.value.replace(/\D/g,'')"
            class="fill-input"
            v-fixedInput
            tabindex="1"
          />
          
       </div>

        <div class="sms-box" v-if="isBind==0">
          <!-- <i class="el-icon-s-management mgr10" style="color:#aaa"></i> -->
          <span class="svg-container"> <img src="@/assets/images/icons/mine_01.png" alt=""></span>
          <div class="fill-item ">
            <input
              key="captchaValue"
              ref="captchaValue"
              v-model="loginForm.captchaValue"
              type="number"
              class="fill-input"
              v-fixedInput
              placeholder="图形验证码"
              name="captchaValue"
              oninput="if(value.length>4) value = value.slice(0,4)"
              onkeyup="this.value=this.value.replace(/\D/g,'')"
              tabindex="3"
              maxlength="4"
            />
            <img :src="imgCode" @click="getCaptcha" class="ico" alt="">
          </div>
        </div>

        <div class="sms-box" v-if="isBind==0">
          <!-- <i class="el-icon-s-comment mgr10" style="color:#aaa"></i> -->
          <span class="svg-container"> <img src="@/assets/images/icons/mine_01.png" alt=""></span>
          <div class="fill-item">
            <input
              key="authCode"
              ref="authCode"
              v-model="loginForm.authCode"
              type="number"
              class="fill-input"
              v-fixedInput
              placeholder="短信验证码"
              name="authCode"
              oninput="if(value.length>6) value = value.slice(0,6)"
              onkeyup="this.value=this.value.replace(/\D/g,'')"
              tabindex="3"
              :disabled="!showCode"
              @blur="smsCodetip = false"
              @keyup.enter.native="handleLogin"
            />

            <div
              class="get-code"
              :class="{'disabled':isDisableSend||!showCode}"
              @click.stop.prevent="sendSmsCode"
            >{{sendSmsCodeText}}</div>
            <!-- <el-button type="primary" class="sms-btn" :class="{on:isDisableSend||!showCode}" :disabled="isDisableSend||!showCode" @click.native.prevent="sendSmsCode" >{{sendSmsCodeText}}</el-button> -->
          </div>
      </div>
       <p class="small_message"  v-if="isBind==0">为了你的账号安全，首次授权登录需要短信验证</p>
       <div class="btn-box" :style="isBind==0?'':'margin-top:100px'">
         <c-button
          :primary="true"
          class="submit-button"
          @click="confirmTap"
          >确认登录</c-button
        >
        <c-button :light="true" style="margin-left:0;" class="submit-button white" @click="canncelTap">取消</c-button>
      </div>
    </div>
    <div v-else-if='weChatType' class="success-box">
      <!-- <i class="el-icon-success" style="color:#409EFF;" v-if="status==2"></i> -->
      <img class="resutl-icon" src="@assets/images/success.png" v-if="status==2"/>
      <img class="resutl-icon" src="@assets/images/error.png" v-else/>
      <!-- <i class="el-icon-error" style="color:#606060;" v-else></i> -->
      <p>{{['','','已成功登录','已取消登录'][status]}}</p>
      <p class="tip">{{['','','你已成功登录，可以在网页上开始操作','如需登录，请返回登录页重新发起'][status]}}</p>
    </div>
  </div>
  <c-spin :spinning="true" class="loading-box" v-else tip="Loading..." size="large"></c-spin>
</template>

<script>
import { validUsername, validMobile } from "@/utils/validate";
import { wxlogin } from "@/utils/auth";
import { JSEncrypt } from "jsencrypt";
import fixedInput from "@/directive/fixedInput";
import { ButtonMixin } from "@comp/Button";
import { getOpenid, wechatSureLogin, getCaptcha, sendMes} from "@/api/login";

export default {
  directives: {
    fixedInput
  },
  mixins: [ButtonMixin],
  data() {
    return {
      where:'',
      loginLoading:false,
      loginForm:{ 
        mobile: "",
        password: "",
        authId:"",
        authCode:'',
        captchaKey:'',
        captchaValue:''
      },
      weChatType:true,
      phone:'',
      openId:'o6gPbjkrIq-Ye7jDjLVHUhYMc0NA',
      status:1, // 1待确认 2已确认 3已取消
      loading: true,
      imgCode:'',
      showCode:false,
      isBind:0,
      isDisableSend: false,
      sendSmsCodeText: "获取验证码",
    };
  },
  watch:{
    'loginForm.captchaValue':{
      handler(val){
        // 展示验证码框
        if(val.length==4) this.showCode = true
        else this.showCode = false
      },
      deep:true
    },
  },
  created(){
    document.title="授权登录确认"
    this.where = this.$route.query.where||'Manage';
    this.weChatType=this.getIsWxClient()
    // this.getCaptcha()
    if(this.weChatType){
      // 判断是否微信登录了
      // if(!window.localStorage.getItem('openId')){
        // console.log(window.localStorage.getItem('openId'),"缓存openId")
        //判断是否回来回调
        if (this.$route.query.code) {
        //   console.log(this.$route.query.code,"回调code")
            //执行微信登录跟后端换取openId mobile:this.loginForm.mobile,
            getOpenid({code:this.$route.query.code,where:this.where=='partner'?'Manage' : this.where }).then(res=>{
              if(res.success){
                console.log(res.data.wxOpenid,"返回openId")
                this.isBind = Number(res.data.isBind)
                this.openId = res.data.wxOpenid
                var tel = res.data.mobile
                var reg =/(\d{3})\d{4}(\d{4})/;
                this.phone =tel.replace(reg, "$1****$2");
                this.loginForm.mobile = tel
                if(this.isBind == 0) this.getCaptcha() //获取图形验证码
                window.localStorage.setItem('openId',res.data.wxOpenid)
                setTimeout(()=>{
                  this.loading=false;
                },1000)
              }
            })
        }else{
          //前往微信授权
           const { hostname, origin: ip } = location;
          wxlogin(window.location.href)
        }
      // }else{
      //    this.openId=window.localStorage.getItem('openId')
      //    console.log(this.openId,"---------")
      // }
      
    }else{

      this.$createToast({txt: '请在微信浏览器打开', type: "error"}).show();
    }
  },
  mounted(){

  },
  methods: {
    // 获取图形验证码
    getCaptcha(){
        getCaptcha({token:''}).then(res=>{
          this.imgCode = res.data.base64
          this.loginForm.captchaKey = res.data.validateKey
        })
    },
       /**
   * 判断是否是微信环境
   */
   getIsWxClient(){
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
          return true;
      }
      return false;
    },
    sendSmsCode() {
      // 校验手机号
      const {where } = this;
      console.log(this.loginForm,'--------------')
      if (!this.loginForm.mobile) {
        this.$createToast({txt: '请先填写手机号码', type: "error"}).show();
        return false;
      }
      if (!validMobile(this.loginForm.mobile||'')) {
        this.$createToast({txt: '手机号码格式不正确', type: "error"}).show();
        return false;
      }
      // if (this.encrypt === null) {
      //   this.getPublickey()
      //     .then((res) => {
      //       console.log(res)
      //       this.sendSmsCode();
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //       // this.loading = false;
      //       this.$createToast({txt: '获取RSA公钥失败，请稍后重试...', type: "error"}).show();
      //     });

      //   return false;
      // }
      if(this.isDisableSend) return
      const loginFormData = {
        mobile: this.loginForm.mobile,
        captchaKey:this.loginForm.captchaKey,
        captchaValue:this.loginForm.captchaValue,
        // password: this.encrypt.encrypt(this.$route.query.password),
        where: where=='partner'?'Manage':where,
        authCodeType: 'UserLogin',
      };
      sendMes(loginFormData).then(res => {
        if(res.success) {
          this.$createToast({txt: `短信已发送到${loginFormData.mobile}`, type: "success"}).show();
          this.loginForm.authId = res.data.authId;
          this.countDown();
        }
      }).catch(err => {
        console.log(err)
      })
      
    },
    confirmTap(){
      const loginFormData={
        ...this.loginForm,
        // password:this.encrypt.encrypt(this.loginForm.password),
        rememberMe:false,
        where:this.where=='partner'?'Manage':this.where,
        orderNo:this.$route.query.orderNo,
        status:1,
        wxOpenid:this.openId
      }

      console.log(this.loginForm,'------------........')

      if(this.isBind===0){
        if(!this.loginForm.mobile) return this.$createToast({txt: `请输入手机号`, type: "error"}).show(); 
        if(!this.loginForm.authCode) return this.$createToast({txt: `请输入验证码`, type: "error"}).show(); 
      }
      if(this.loginLoading) return
      this.loginLoading = true
      wechatSureLogin(loginFormData).then(res=>{
        if(res.success){
          // if(this.$route.query.isPC == true|| this.$route.query.isPC == "true" ) 
          // else{
          //    this.$notify({
          //       title: "登录成功",
          //       message: "欢迎回来",
          //       type: "success",
          //       duration: 4000,
          //       offset: 100,
          //     });

          //   //   // 手机端直接进入首页
          //   //   setTimeout(()=>{
          //   //     this.getQuanxian()
          //   // }, 3000);
          // }

          this.status=2
        } 
        setTimeout(()=>{
          this.loginLoading = false
        },2000)
        document.title="授权结果"
      })
    },
    canncelTap(){
      const loginFormData={
        ...this.loginForm,
        // password:this.encrypt.encrypt(this.loginForm.password),
        rememberMe:false,
        where:this.where=='partner'?'Manage':this.where,
        orderNo:this.$route.query.orderNo,
        status:2,
        wxOpenid:this.openId
      }

      // if(this.isBind===0){
      //   if(!this.loginForm.mobile) return this.$message({message: "请输入验证码",type: "error"});
      //   if(!this.loginForm.authCode) return this.$message({message: "请输入验证码",type: "error"});
      //   loginFormData.mobile = this.loginForm.username
      // }
      if(this.loginLoading) return
      this.loginLoading = true

       wechatSureLogin(loginFormData).then(res=>{
        if(res.success) this.status=3
         document.title="授权结果"

        setTimeout(()=>{
          this.loginLoading = false
        },2000)
      })
    },
    countDown() {
      // 获取短信验证码操作
        let seconds = 60;
        this.isDisableSend = true;
        this.timerId = setInterval(() => {
          seconds--;
          if(seconds <= 0) {
            clearInterval(this.timerId);
            seconds = 60;
            this.isDisableSend = false;
            this.sendSmsCodeText = `重新获取`;
          } else {
            this.sendSmsCodeText = `已发送(${seconds}s)`;
          }
        }, 1000);
        this.$once("hook:beforeDestroy", () => {
          clearInterval(this.timerId);
        });
    },
  },

};
</script>
<style scoped lang="less">
.el-message{
  min-width: 300px;
  max-width: 100%;
}
.sms-box{
  .el-input__inner{border:none;font-size: 16px;}
}
.message-box1{
  .el-input__inner{border:none;font-size: 16px;}
}
.svg-icon{
 color: rgb(170, 170, 170);
 margin-right: 10px;
}
.logo-mian-box{
  width: 80%;
  position: relative;
  display: flex;
  align-items: center;
  // margin-top: 20px;
  // padding:  0 30px;
  justify-content: center;
  h1{
    height: 60px;
    width: auto;
    position: absolute;
    z-index: 2;
    font-size: 30px;
    color: #333;
    top: 64px;
  }
  .empty-img{
    width: 100%;
    object-fit: contain;
  }
  .logo-img{
    height: 60px;
    width: auto;
    position: absolute;
    z-index: 2;
    top: 64px;
  }
}
.message-box1{
  width: 80%;
  // justify-content: center;
  // margin: 20px auto 10px;
  padding-bottom: 10px;
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
  border-bottom:solid 1px #eee;
  .svg-container{
    margin-right:10px;
    img{
      width:14px;
    }
  }

  input{
    flex:1;
    font-size: 12px;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    background: none;
    &::placeholder{
      font-size: 12px;
      color:#999;
    }
  }
}
.fill-input{

}
.fill-item{
  flex:1;
  display:flex;
  align-items:center;
  input{
    font-size: 12px;
    flex:1;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    background: none;
    &::placeholder{
      font-size: 12px;
      color:#999;
    }
  }
}
.login-holder {
  max-width: 100%;
  text-align: center;
  max-height: 100vh;
  padding:20px 0;
  .small_message{
    color: #999;
    font-size: 14px;
  }
  .form-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .success-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
      width:80px;
      height:auto;
      margin-bottom:30px;
    }
    p{
      font-size: 18px;
      font-weight: bold;
      margin-bottom:20px;
      &.tip{
        font-size: 14px;
        color: #666;
        margin-top: 0;
        font-weight: 300;
      }
    }
  }
  .sms-box{
    width: 80%;
    display: flex;
    align-items: center;
    // margin: 20px auto 10px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    justify-content: space-between;
    border-bottom:solid 1px #eee;
    .svg-container{
      margin-right:10px;
      display:block;
      img{
        width:14px;
      }
    }
    div{
      flex: 1;
    }
    .ico{
      width:80px;
      height:30px;
      object-fit:contain;
    }
    .sms-btn {
      background: #0456fa;
      border: #0456fa;
      &.on{
        background-color: #666;
        color: #fff;
        border:none;
      }
    }
  }
}
.tip_message{
  font-size: 18px;
  color: #333;
  margin-top: 20px;
  // margin-bottom: 0;
}
.submit-button {
  width: 100%;
  height: 45px;
  margin: 20px 0 0;
}
.btn-box{
  width: 80%;
  height: 140px;
}
.success-box{
  display: flex;
  flex-direction: column;
  i{
    font-size: 60px;
  }
}

.get-code {
  width: 90px;
  text-align: right;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #4E75FA;
}
.loading-box{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  margin: auto;
}
</style>