<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2021-12-06 09:20:52
 * @LastEditors: CZK
 * @LastEditTime: 2021-12-06 16:28:30
-->
<template>
  <div class="container">
    <div class="flex-1 flex">
      <div class="flex-1 help-item-info">
        <div class="help-item-title"><img src="../../assets/images/icons/question_01.png" alt="">  {{this.title}}</div>
        <div class="line"/>
        <div class="content-box">
          <img src="../../assets/images/icons/question_02.png" alt="">
          <div class="help-item-content" v-html="this.content"/>
        </div>      
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { querySysQuestionDetail } from "@/api/user";

export default {
  name: "",
  data() {
    return {
      title: NaN,
      content: NaN
    };
  },
  computed: {},
  components: {},
  methods: {},
  mounted() {
    const { questionId } = this.$route.query;
    querySysQuestionDetail({questionId}).then(res => {
      if (res.success) {
        const { questionContent, answerContent } = res.data;
        this.title = questionContent;
        this.content = answerContent;
      }
    });
  }
};
</script>

<style scoped lang="less">

.help-item-info {
  padding: 0 16px;
  height: 100vh;
  overflow-y: scroll;
  text-align: left;

  .help-item-title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 600;
    margin: 19px auto 17px;
    color: #333333;
    img{
      width: 20px;
      margin-right: 10px;
      height: 20px;
      object-fit: contain;
    }
  }
  .content-box{
    display: flex;
    width: 100%;
    img{
      width: 20px;
      height: 20px;
      margin-right: 10px;
      object-fit: contain;
    }
  }
  .help-item-content {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
  }

  .line {
    height: 0.5px;
    width: 100%;
    border-top: 1px;
    background:rgba(0, 0, 0, 0.1);
    float:left;
    margin-bottom:15px;
  }
}
</style>
