<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2021-12-06 09:20:52
 * @LastEditors: CZK
 * @LastEditTime: 2024-09-20 18:21:39
-->
<template>
  <div id="app">
    <!-- <div class="powerOn-box" v-if="isOpen">
      <img src="./assets/images/icons/open_on_logo.png" alt="">
      <span>点亮美好生活</span>
    </div> -->
    <!-- <template v-if="showToggleBtn">
      <div id="toggle-platform-box">
        <cube-button :inline="true" @click="showBtn = !showBtn">当前:{{name}}</cube-button>
        <div class="toggle-platform" v-show="showBtn">
          <cube-button :inline="true" class="mgb10" @click="togglePlatform('xafq')">切换到小爱</cube-button>
          <cube-button :inline="true" class="mgb10" @click="togglePlatform('xiaoai')">切换到专属</cube-button>
        </div>
      </div>
    </template> -->
    <transition name="fade" mode="in-out">
      <router-view v-wechat-title="$route.meta.title"></router-view>
    </transition>
  </div>
</template>

<script>

export default {
  data() {
    return {
      showToggleBtn: false,
      showBtn: false,
      name: "小爱",
      isOpen:true
    };
  },
  components: {
  
  },
  methods: {
    togglePlatform(val) {
      window.sessionStorage.setItem("platformSign", val);
      window.location.reload();
    }
  },
  created () {
    setTimeout(()=>{
      this.isOpen=false
    },2000)
  },
  mounted () {
    if (process.env.NODE_ENV === "development") {
      const platformSign = window.sessionStorage.getItem("platformSign");
      this.name = platformSign ==  "xiaoai" ? "专属" : "小爱"; // 默认豆乐派
      this.showToggleBtn = true;
    }
  }
};
</script>


<style lang="less">

.powerOn-box{
  width: 100%;
  height: 100vh;
  background: #4E75FA;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  img{
    width: 85px;
    height: 94px;
    object-fit: contain;
    margin: 140px auto 18px;
  }
  span{
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 0px;
    color: #FFFFFF;
    opacity: 0.5;
    margin: 0 auto;
    position: relative;
    &::before{
      content: '';
      width: 10px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      left: -12px;
    }
    &::after{
      content: '';
      width: 10px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      right: -12px;
    }
  }
}
#toggle-platform-box {
  position: fixed;
  width: auto;
  height: 30px;
  z-index: 10;
  bottom: 10px;
  left: calc(50% - 40px);
  .toggle-platform {
    position: absolute;
    left: -4px;
    bottom: 32px;
    /* background: #fff; */
    width: auto;
    text-align: center;
    .mgb10 {
      margin-bottom: 10px;
    }
  }
}

#app {
  // font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}

.fade-enter {
  opacity: 0;
}
.fade-leave {
  opacity: 1;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s;
}

</style>
<style>
.cube-popup-center .cube-popup-content{
  border-radius: 10px !important;
}
.cube-picker-wheel-item,.cube-action-sheet-item{
  font-size: 14px !important;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
</style>