var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.info
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "card" }, [
          _c("img", {
            staticClass: "card-logo",
            attrs: { src: require("../../assets/images/img/user.png") }
          }),
          _c("p", { staticClass: "danwei" }, [
            _vm._v(_vm._s(_vm.info.sourceName))
          ]),
          _c("p", { staticClass: "money" }, [
            _vm._v("+" + _vm._s(_vm.info.payTotal || 0))
          ]),
          _c("div", { staticClass: "line border-bottom-1px" }, [
            _c("span", { staticClass: "title" }, [_vm._v("交易卡号")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.formatNumber(_vm.info.receiverBankAccount)))
            ])
          ]),
          _c("div", { staticClass: "line border-bottom-1px" }, [
            _c("span", { staticClass: "title" }, [_vm._v("交易时间")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.info.transactionTime))
            ])
          ]),
          _c("div", { staticClass: "line border-bottom-1px" }, [
            _c("span", { staticClass: "title" }, [_vm._v("摘要")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.info.abstractName))
            ])
          ]),
          _c("div", { staticClass: "line border-bottom-1px" }, [
            _c("span", { staticClass: "title" }, [_vm._v("对方账户")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.formatNumber(_vm.info.sourceBankAccount)))
            ])
          ]),
          _c("div", { staticClass: "line border-bottom-1px" }, [
            _c("span", { staticClass: "title" }, [_vm._v("业务状态")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.info.payStatus))
            ])
          ]),
          _c("div", { staticClass: "line border-bottom-1px" }, [
            _c("span", { staticClass: "title" }, [_vm._v("交易编码")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.info.transactionNo))
            ])
          ]),
          _vm.info.electronicReceiptUrl
            ? _c(
                "div",
                {
                  staticClass: "btn main",
                  on: {
                    click: function($event) {
                      return _vm.window.open(_vm.info.electronicReceiptUrl)
                    }
                  }
                },
                [_vm._v("查看电子回单")]
              )
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }