<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2021-12-06 09:20:52
 * @LastEditors: CZK
 * @LastEditTime: 2024-09-20 18:20:43
-->
<template>
	<div >
		<router-view></router-view>
		<!-- <div class="tabberWarp"> -->
			<!-- <div class="warp">
				<Item :txt='item.txt' :page='item.page' @change='getVal' v-for='item in tabbarDes' :sel='selected' :key="item.txt">
					<img :src="item.normalImg" slot='normalImg'>
					<img :src="item.activeImg" slot='activeImg'>
				</Item>
			</div> -->

			<!-- <div class="warp">
				<div class="itemWarp flex_mid" v-for="(item ,index) in tabbarDes" :key="item.txt" @click="switchToTab(item.page)">
					<img :src="$route.path.indexOf(item.page) != -1 ? item.activeImg : item.normalImg" alt="">
					<span :class="$route.path.indexOf(item.page) != -1 ? 'active' : ''">{{item.txt}}</span>
				</div>
			</div> -->

		<!-- </div> -->
	</div>
</template>

<script type="text/javascript">
	import Item from './Item.vue';
	export default{
		components:{
			Item
		},
		data:function(){
			return{
				selected: "mines",
				tabbarDes:[
					{
						txt:'首页',
						page:'xaIndex2',
						normalImg:require('@/assets/images/main/index_p_g.png'),
						activeImg:require('@/assets/images/main/index_p.png')
					},
					{
						txt:'我的',
						page:'mines',
						normalImg:require('@/assets/images/main/me_p_g.png'),
						activeImg:require('@/assets/images/main/me_p.png')
					}
				],
				isLogin: true,
				where: "xafq"
			}
		},
		methods:{
			getVal:function(res) {
				this.selected = res;
			},
			switchToTab(path) {
				if(!this.isLogin && this.where === "xiaoai") {
					// this.$bus.$emit("showLoginDialog", "desc:首页显示登录弹窗");
					// return;
					console.log('进入')
					this.$router.replace({name: 'login'});
					return
				}
				this.$router.replace({name: path});

			}
		},
		created () {
			const { where } = window.customH5Platform;
			this.where = where;
			if(where === "xiaoai") {
				this.$bus.$on('isLogin', (res)=> {
					if(res === "0") {
						this.isLogin = false;
					} else if(res === "1") {
						this.isLogin = true;
					}
				});
			}
			
		},
		beforeDestroy () {
			if(this.where === "xiaoai") {
				this.$bus.$off('isLogin')
			}
		}
	}
</script>
<style type="text/css">
	.warp{
		width: 100%;
		border-top: 1px solid #eee;
		background: #fff;
		display: flex;
		align-items: center;
		justify-content: space-around;
		font-size: 0;
	}
	.warp img{
		width: 20px;
		height: 20px;
	}
	.tabberWarp img{
		margin-top: 10px;
		margin-bottom: 5px;
	}
	.tabberWarp{
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		padding-bottom: 5px;
		background: #fff;
	}
	.itemWarp{
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.itemWarp span{
		font-size: 12px;
		color: #C9C9C9;
	}
	.itemWarp span.active {
		color:  #666666;
	}
</style>