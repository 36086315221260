import { render, staticRenderFns } from "./openAccount_step_3.vue?vue&type=template&id=239d40a8&scoped=true&"
import script from "./openAccount_step_3.vue?vue&type=script&lang=js&"
export * from "./openAccount_step_3.vue?vue&type=script&lang=js&"
import style0 from "./openAccount_step_3.vue?vue&type=style&index=0&id=239d40a8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "239d40a8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/workspace/ecommerce_h5_dev_front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('239d40a8', component.options)
    } else {
      api.reload('239d40a8', component.options)
    }
    module.hot.accept("./openAccount_step_3.vue?vue&type=template&id=239d40a8&scoped=true&", function () {
      api.rerender('239d40a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/main/openAccount_step_3.vue"
export default component.exports