var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _vm._m(0),
          _vm._l(_vm.konggu, function(item, index) {
            return _c(
              "div",
              { key: item.radom, staticClass: "info-faren-main" },
              [
                _c("div", { staticClass: "info-faren-title-box" }, [
                  _c(
                    "p",
                    {
                      staticClass: "info-faren-title",
                      class: { on: index === 0 }
                    },
                    [_vm._v("企业控股股东信息" + _vm._s(index + 1))]
                  ),
                  item.type === 0
                    ? _c("div", { staticClass: "select-box" }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.changeGudong(index, 1)
                              }
                            }
                          },
                          [
                            _c("i", {
                              class: { yes: item.shareholderStatus == 1 }
                            }),
                            _vm._v("同法人")
                          ]
                        ),
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.changeGudong(index, 2)
                              }
                            }
                          },
                          [
                            _c("i", {
                              class: { yes: item.shareholderStatus == 2 }
                            }),
                            _vm._v("同联系人")
                          ]
                        )
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "form-item-lie border-bottom-1px" }, [
                  _c("label", [_vm._v("类型")]),
                  _c(
                    "span",
                    {
                      staticClass: "down",
                      on: {
                        click: function($event) {
                          return _vm.changeTypeClick(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.type ? "企业" : "个人")), _c("i")]
                  )
                ]),
                item.type == 1
                  ? [
                      _c("div", { staticClass: "body-box" }, [
                        _vm._m(1, true),
                        _c(
                          "div",
                          {
                            staticClass: "upload-company-box border-bottom-1px"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "upload-company-show-image" },
                              [
                                item.certificationUrl
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "default-image",
                                        on: {
                                          click: function($event) {
                                            return _vm.see(
                                              item.certificationUrl
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "d",
                                          attrs: { src: item.certificationUrl }
                                        })
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "default-image" },
                                      [
                                        _c("img", {
                                          staticClass: "d",
                                          attrs: {
                                            src: require("../../assets/images/img/d-img.jpg")
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "see-btn",
                                            on: {
                                              click: function($event) {
                                                return _vm.see()
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("../../assets/images/img/see.png")
                                              }
                                            }),
                                            _c("span", [_vm._v("查看样本")])
                                          ]
                                        )
                                      ]
                                    )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "upload-company-btn " },
                              [
                                _c("OSSUpload", {
                                  ref: "upload1",
                                  refInFor: true,
                                  staticClass: "ossupload",
                                  attrs: {
                                    type: item.companyCard.uploadType,
                                    backgroundImgName: item.companyCard.bg,
                                    accept: item.companyCard.accept,
                                    classType: true,
                                    title: item.companyCard.title,
                                    files: item.companyCard.files,
                                    multiple: item.companyCard.multiple
                                  },
                                  on: {
                                    success: function(e) {
                                      return _vm.getCompanyUrl(
                                        e,
                                        "konggu",
                                        index
                                      )
                                    },
                                    "update:files": function($event) {
                                      return _vm.$set(
                                        item.companyCard,
                                        "files",
                                        $event
                                      )
                                    }
                                  }
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass: "on",
                                    on: { click: _vm.upload }
                                  },
                                  [_vm._v("上传")]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c("div", { staticClass: "faren-form" }, [
                          _c(
                            "div",
                            { staticClass: "form-item border-bottom-1px" },
                            [
                              _c("label", { staticClass: "on" }, [
                                _vm._v("企业名称")
                              ]),
                              _c("span", [_vm._v(_vm._s(item.enterpriseName))])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "form-item border-bottom-1px" },
                            [
                              _c("label", { staticClass: "on" }, [
                                _vm._v("企业类型")
                              ]),
                              _c("span", [_vm._v(_vm._s(item.enterpriseType))])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "form-item border-bottom-1px" },
                            [
                              _c("label", { staticClass: "on" }, [
                                _vm._v("统一社会信用代码")
                              ]),
                              _c("span", [_vm._v(_vm._s(item.creditCode))])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "form-item border-bottom-1px" },
                            [
                              _c("label", { staticClass: "on" }, [
                                _vm._v("成立时间")
                              ]),
                              _c("span", [_vm._v(_vm._s(item.foundTime))])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "form-item border-bottom-1px" },
                            [
                              _c("label", { staticClass: "on" }, [
                                _vm._v("经营地址")
                              ]),
                              _c("span", [_vm._v(_vm._s(item.address))])
                            ]
                          )
                        ])
                      ])
                    ]
                  : _vm._e(),
                item.type == 0
                  ? [
                      _c(
                        "div",
                        { staticClass: "info-faren-content" },
                        _vm._l(item.idCard, function(jtem, jndex) {
                          return _c("OSSUpload", {
                            key: jtem.bg,
                            ref: "upload1",
                            refInFor: true,
                            attrs: {
                              type: jtem.uploadType,
                              backgroundImgName: jtem.bg,
                              accept: jtem.accept,
                              title: jtem.title,
                              files: jtem.files,
                              multiple: jtem.multiple
                            },
                            on: {
                              success: function(e) {
                                return _vm.handleSuccess1(
                                  e,
                                  "konggu",
                                  index,
                                  jndex
                                )
                              },
                              "update:files": function($event) {
                                return _vm.$set(jtem, "files", $event)
                              }
                            }
                          })
                        }),
                        1
                      ),
                      _c("div", { staticClass: "tips" }, [
                        _vm._v("请确认以下信息是否正确，如有误请重新识别")
                      ]),
                      _c("div", { staticClass: "faren-form" }, [
                        _c(
                          "div",
                          { staticClass: "form-item border-bottom-1px" },
                          [
                            _c("label", [_vm._v("真实姓名")]),
                            _c("span", [_vm._v(_vm._s(item.name))])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "form-item border-bottom-1px" },
                          [
                            _c("label", [_vm._v("身份证号")]),
                            _c("span", [_vm._v(_vm._s(item.idCardNo))])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "form-item border-bottom-1px" },
                          [
                            _c("label", [_vm._v("签发机关")]),
                            _c("span", [_vm._v(_vm._s(item.signIssued))])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "form-item border-bottom-1px" },
                          [
                            _c("label", [_vm._v("有效期限")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(item.effectiveStartDate) +
                                  "-" +
                                  _vm._s(item.effectiveEndDate)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "form-item border-bottom-1px" },
                          [
                            _c("label", [_vm._v("联系电话")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.phone,
                                  expression: "item.phone"
                                }
                              ],
                              attrs: {
                                type: "text",
                                maxlength: 11,
                                placeholder: "请填写股东本人手机号"
                              },
                              domProps: { value: item.phone },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(item, "phone", $event.target.value)
                                }
                              }
                            })
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "form-item border-bottom-1px" },
                          [
                            _c("label", [_vm._v("电子邮箱")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.email,
                                  expression: "item.email"
                                }
                              ],
                              attrs: {
                                type: "text",
                                placeholder: "请填写电子邮箱"
                              },
                              domProps: { value: item.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(item, "email", $event.target.value)
                                }
                              }
                            })
                          ]
                        )
                      ])
                    ]
                  : _vm._e(),
                index == _vm.konggu.length - 1
                  ? _c(
                      "div",
                      { staticClass: "add-btn", on: { click: _vm.addKonggu } },
                      [_vm._v("+添加企业控股信息")]
                    )
                  : _vm._e()
              ],
              2
            )
          }),
          _vm._l(_vm.shouyiren, function(item, index) {
            return _c("div", { key: index, staticClass: "info-faren-main" }, [
              _c("div", { staticClass: "info-faren-title-box" }, [
                _c(
                  "p",
                  {
                    staticClass: "info-faren-title",
                    class: { on: index === 0 }
                  },
                  [_vm._v("企业最终受益人信息" + _vm._s(index + 1))]
                ),
                _c("div", { staticClass: "select-box" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.changeShouyiren(index, 1)
                        }
                      }
                    },
                    [
                      _c("i", { class: { yes: item.shareholderStatus == 1 } }),
                      _vm._v("同法人")
                    ]
                  ),
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.changeShouyiren(index, 2)
                        }
                      }
                    },
                    [
                      _c("i", { class: { yes: item.shareholderStatus == 2 } }),
                      _vm._v("同联系人")
                    ]
                  )
                ])
              ]),
              _c(
                "div",
                { staticClass: "info-faren-content" },
                _vm._l(item.idCard, function(jtem, jndex) {
                  return _c("OSSUpload", {
                    key: jtem.bg,
                    ref: "upload1",
                    refInFor: true,
                    attrs: {
                      type: jtem.uploadType,
                      backgroundImgName: jtem.bg,
                      accept: jtem.accept,
                      title: jtem.title,
                      files: jtem.files,
                      multiple: jtem.multiple
                    },
                    on: {
                      success: function(e) {
                        return _vm.handleSuccess1(e, "shouyiren", index, jndex)
                      },
                      "update:files": function($event) {
                        return _vm.$set(jtem, "files", $event)
                      }
                    }
                  })
                }),
                1
              ),
              _c("div", { staticClass: "tips" }, [
                _vm._v("请确认以下信息是否正确，如有误请重新识别")
              ]),
              _c("div", { staticClass: "faren-form" }, [
                _c("div", { staticClass: "form-item border-bottom-1px" }, [
                  _c("label", [_vm._v("真实姓名")]),
                  _c("span", [_vm._v(_vm._s(item.name))])
                ]),
                _c("div", { staticClass: "form-item border-bottom-1px" }, [
                  _c("label", [_vm._v("身份证号")]),
                  _c("span", [_vm._v(_vm._s(item.idCardNo))])
                ]),
                _c("div", { staticClass: "form-item border-bottom-1px" }, [
                  _c("label", [_vm._v("签发机关")]),
                  _c("span", [_vm._v(_vm._s(item.signIssued))])
                ]),
                _c("div", { staticClass: "form-item border-bottom-1px" }, [
                  _c("label", [_vm._v("有效期限")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(item.effectiveStartDate) +
                        "-" +
                        _vm._s(item.effectiveEndDate)
                    )
                  ])
                ]),
                _c("div", { staticClass: "form-item border-bottom-1px" }, [
                  _c("label", [_vm._v("联系电话")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.phone,
                        expression: "item.phone"
                      }
                    ],
                    attrs: {
                      type: "text",
                      maxlength: 11,
                      placeholder: "请填写股东本人手机号"
                    },
                    domProps: { value: item.phone },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "phone", $event.target.value)
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "form-item border-bottom-1px" }, [
                  _c("label", [_vm._v("电子邮箱")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.email,
                        expression: "item.email"
                      }
                    ],
                    attrs: { type: "text", placeholder: "请填写电子邮箱" },
                    domProps: { value: item.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "email", $event.target.value)
                      }
                    }
                  })
                ])
              ]),
              index == _vm.shouyiren.length - 1
                ? _c(
                    "div",
                    { staticClass: "add-btn", on: { click: _vm.addshouyiren } },
                    [_vm._v("+添加企业受益人信息")]
                  )
                : _vm._e(),
              index == _vm.shouyiren.length - 1
                ? _c(
                    "div",
                    { staticClass: "add-btn on", on: { click: _vm.commit } },
                    [
                      _vm.commiting ? _c("cube-loading") : _vm._e(),
                      _c("span", [_vm._v("下一步")])
                    ],
                    1
                  )
                : _vm._e()
            ])
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("企业补充信息")]),
      _c("p", { staticClass: "desc" }, [_vm._v("Hi，请填写以下资料吧~")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-faren-title-box" }, [
      _c("p", { staticClass: "info-faren-title" }, [_vm._v("营业执照")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }