import { riskAxios } from "@/utils/requestRisk";

/**
 * 风控准入
 *
 * @param data 
 * @returns {*}
 */
export function checkXsRule(data = {}) {
  return riskAxios({
    url: "/rule/checkXsRule.do",
    method: "post",
    data
  });
}