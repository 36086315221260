<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-09-29 15:33:11
 * @LastEditors: CZK
 * @LastEditTime: 2024-10-23 15:40:20
-->
<template>
  <div class="container">
    <div class="title-box">
        <p class="title">店铺信息</p>
        <p class="desc">Hi，请填写以下资料吧~</p>
      </div>

      <div class="info-faren-main">
        <div class="info-faren-title-box">
          <p class="info-faren-title">店铺信息</p>
        </div>
        <div class="info-faren-content">

          <div class="faren-form">
            <div class="form-item border-bottom-1px r" @click="selectTap">
              <label>电商平台</label>
              <span>{{info.platformName||'请选择电商平台'}}</span>
            </div>
            <!-- <div class="form-item border-bottom-1px r">
              <label>店铺授权</label>
              <span>去授权</span>
            </div> -->
            <div class="form-item border-bottom-1px">
              <label>店铺全称</label>
              <input type="text" v-model="info.name" placeholder="请填写店铺全称">
            </div>
            <div class="form-item border-bottom-1px">
              <label>店铺ID</label>
              <input type="text" v-model="info.platformShopId"  placeholder="请填写店铺ID">
            </div>

            <div class="form-item border-bottom-1px r" @click="selectTap1">
              <label>店铺类型</label>
              <span>{{info.shopTypeName||'请选择店铺类型'}}</span>
            </div>
            <div class="form-item border-bottom-1px" @click="showDatePicker">
              <label>开店时间</label>
              <input type="text" readonly v-model="info.foundTime"  placeholder="请填写开店时间">
            </div>
            <div class="form-item border-bottom-1px">
              <label>主营类目</label>
              <input type="text" v-model="info.businessType"  placeholder="请填写主营类目">
            </div>

            <div class="form-item border-bottom-1px">
              <label>店铺主账号ID</label>
              <input type="text" v-model="info.shopMasterAccountId"  placeholder="请填写店铺主账号ID">
            </div>
          </div>
         
        </div>

        <div class="add-btn on" @click="commit" ><cube-loading v-if="commiting"></cube-loading>
          <span>下一步</span></div>
    </div>
  </div>

</template>
<script type="text/ecmascript-6">
import {getSelectOption1,saveShopInfo} from "@api/user"
import {findNextFlowThenJump} from "./findNextFlow"
export default {
  name: "storeInfo",
  components: {
 
  },
  data(){
    return {
      plamteArr:[],
      typeArr:[],
      commiting:false,
      info:{
        businessType: "",
        foundTime: "",
        name: "",
        platformShopId: "",
        platformType: "",
        platformName:'',
        shopType:'',
        shopTypeName:'',
        shopMasterAccountId:""
      }
    }
  },
  methods: {
    async commit(){
      if(this.commiting) return
      const {name,platformShopId,foundTime,businessType,platformType,platformName,shopType,shopMasterAccountId} = this.info
      console.log(this.info,'000000000000000000000000000')
      if(!platformType){
        this.$createToast({txt: '请选择电商平台',type: "error",mask:true,time:1000}).show();
        return
      }

      if(!name){
        this.$createToast({txt: '请填写店铺名称',type: "error",mask:true,time:1000}).show();
        return
      }
      if(!platformShopId){
        this.$createToast({txt: '请填写店铺编号',type: "error",mask:true,time:1000}).show();
        return
      }
      if(!shopType){
        this.$createToast({txt: '请选择店铺类型',type: "error",mask:true,time:1000}).show();
        return
      }
      if(!foundTime){
        this.$createToast({txt: '请填写开店时间',type: "error",mask:true,time:1000}).show();
        return
      }
      if(!businessType){
        this.$createToast({txt: '请填写主营类目',type: "error",mask:true,time:1000}).show();
        return
      }
      if(!shopMasterAccountId){
        this.$createToast({txt: '请填写店铺主账号ID',type: "error",mask:true,time:1000}).show();
        return
      }
      this.commiting = true
      let res =await saveShopInfo({...this.info,token:''})
      if(res.success){
        findNextFlowThenJump()
      }
      this.commiting = false
    },

    showDatePicker() {
      if (!this.timePicker) {
        this.timePicker = this.$createDatePicker({
          itle: '开店时间',
          min: new Date(1950, 1, 1),
          max: new Date(2999, 12, 30),
          value: new Date(),
          format:{
            year: 'YYYY',
            month: 'MM',
            date: 'DD'
          },
          onSelect: this.selectHandle2,
        })
      }

      this.timePicker.show()
    },
    selectHandle2(date, selectedVal, selectedText) {
      this.info.foundTime = selectedText.join("-")
    },
    cancelHandle1() {
      this.$createToast({
        type: 'correct',
        txt: 'Picker canceled',
        time: 1000
      }).show()
    },
    selectTap(){
      if (!this.picker) {
        this.picker = this.$createPicker({
          title: '电商平台',
          data: [this.plamteArr],
          onSelect: this.selectHandle
        })
      }
      this.picker.show()
    },
    selectHandle(selectedVal, selectedIndex, selectedText) {
      this.info.platformType = selectedVal[0]
      this.info.platformName = selectedText[0]
    },


    selectTap1(){
      if (!this.picker1) {
        this.picker1 = this.$createPicker({
          title: '店铺类型',
          data: [this.typeArr],
          onSelect: this.selectHandle1
        })
      }
      this.picker1.show()
    },
    selectHandle1(selectedVal, selectedIndex, selectedText) {
      this.info.shopType = selectedVal[0]
      this.info.shopTypeName = selectedText[0]
    },
  },
  async created(){
   let res = await getSelectOption1({name:'AssetLeaguePlatformTypeEnum,AssetLeagueShopTypeEnum',token:''})
   this.plamteArr = (res.data.AssetLeaguePlatformTypeEnum||[]).map(item=>{ return {text:item.key_,value:item.value_}})

   this.typeArr = (res.data.AssetLeagueShopTypeEnum||[]).map(item=>{ return {text:item.key_,value:item.value_}})
  },
}
</script>
<style lang="less" scoped>
input{
  all: unset;
}
.container{
width: 100%;
background:#F5F7FB url("../../assets//images/img/bg1.png") no-repeat;
background-size: contain;
.title-box{
  margin-top: 27px;
  margin-left: 32px;
  .title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 18px;
  }
  .desc{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 22px;
    margin-top: 12px;
  }
}
.info-faren-main{
  width: 343px;
  // height: 500px;
  background: #FFFFFF;
  margin: 17px auto 0;
  border-radius: 8px 8px 8px 8px;
  padding: 14px 12px 12px;
  .info-faren-title-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    .select-box{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      display: flex;
      line-height: 18px;
      span{display: flex;align-items: center;}
      i{
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("../../assets/images/img/no.png") no-repeat;
        background-size: contain;
        margin-left: 10px;
        margin-right: 10px;
        &.yes{
          background: url("../../assets/images/img/yes.png") no-repeat;
        }
      }
    }
  }
  .info-faren-title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
    color: #1C2023;
    line-height: 20px;
    &::after{
      content: '*';
      color:#FA5151;
    }
  }
  .info-faren-content{
    display: flex;
    justify-content: space-between;
  }
  .tips{
    width: 321px;
    height: 40px;
    background: #FFFBE1;
    border-radius: 8px 8px 8px 8px;
    padding: 10px 0 10px 6px;
    text-align: left;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin: 14px 0;
    color: #FFA54C;
  }
  .faren-form{
    width: 100%;
    .form-item1{
      width: 100%;
      display: flex;
      justify-content: space-between;
      min-height: 50px;
      label{
        width: 56px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 50px;
        margin-right: 32px;
      }
      .types-box{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 18px;
        margin-top: 16px;
        p{
          display: flex;
          margin-bottom: 11px;
        }
        i{
          display: inline-block;
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          background: url("../../assets/images/img/no.png") no-repeat;
          background-size: contain;
          margin-right: 10px;
          &.yes{
            background: url("../../assets/images/img/yes.png") no-repeat;
          }
        }
      }
    }
    .form-item{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      position: relative;
      &.r{
        &::before{
          content: '';
          width: 24px;
          height: 24px;
          background: url("../../assets/images/img/right_icon.png") no-repeat;
          background-size: contain;
          display: block;
          position: absolute;
          right: 0;
        }
      }
      label{
        width: 90px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 20px;
        margin-right: 32px;
      }
      input{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        &::placeholder{
          color: #999999;
        }
      }
      span{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
    }
  }
  .add-btn{
    width: 321px;
    height: 42px;
    text-align: center;
    background: #CCCCCC;
    border-radius: 30px 30px 30px 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    line-height: 42px;
    margin: 22px auto 0;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    &.on{
      background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
      margin: 10px auto 16px;
    }
  }
}
}
</style>