<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-09-02 14:57:01
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-13 10:31:11
-->
<template>
  <div class="container" v-if="!loading">
    <div class="header-row">
      <div class="header-left">
        <img src="../../assets/images/avatar-default.png"/>
        <span>{{name}}</span>
      </div>
      <div class="header-right">
        <img src="../../assets/images/img/cutomer.png" />
      </div>
    </div>
    <div class="content">
      <div class="main-box">
        <p class="main-title" v-if="leagueInfo.leagueId">可用额度（元）</p>
        <p class="main-title" v-else>最高可申请金额（元）</p>
        <div class="main-box-content">
          <span class="main-money" v-if="leagueInfo.leagueId">{{(leagueInfo.useLoanAmount||0) | formatCurrency}}</span>
          <span class="main-money" v-else>{{10000000 | formatCurrency}}</span>
          <!--  @click="apply" -->
          <span class="main-btn" @click="apply()" :class="{btn5:!isUsebtn}">
            {{btnName}}
            <i v-if="isTip">{{btnDesc}}</i>
          </span>
          
        </div>
        <p class="main-desc-2"  v-if="leagueInfo.leagueId">总额度{{(totalLoanAmount||0) | formatCurrency}}元<span>申请调额<i></i></span></p>
        <p class="main-desc" v-else>透明｜合规｜安全｜高效</p>
      </div>
      <!-- 广播 -->
      <div class="broadcast"><i></i>
       <div class="broadcast-content">
        <div class="broadcast-title">
          <span>感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！</span>
          <span>感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！</span>
          <span>感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！</span>
          <span>感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！</span>
          <span>感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！</span>
          <span>感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！</span>
          <span>感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！</span>
          <span>感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！</span>
          <span>感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！</span>
          <span>感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！</span>
        </div>
       </div>
      </div>

      <!-- 常用服务 -->
       <div class="common-service">
        <p class="common-service-title">常用服务</p>
        <div class="common-service-content">
          <div class="common-service-item"  @click="toMenu('applyRecode')">
            <img src="../../assets/images/img/menu01.png"/>
            <p>申请记录</p>
          </div>
          <div class="common-service-item" @click="toMenu('mine')">
            <img src="../../assets/images/img/menu02.png"/>
            <p>还款记录</p>
          </div>
          <div class="common-service-item" @click="toMenu('receivingAccountList')">
            <img src="../../assets/images/img/menu03.png"/>
            <p>收款账户</p>
          </div>
          <div class="common-service-item" @click="toMenu('settlementAccount')">
            <img src="../../assets/images/img/menu04.png"/>
            <p>结算账户</p>
          </div>
        </div>
       </div>

       <div class="line-items mgt16" @click="toIntro('使用须知','useKnow')">
        <div class="line-item">
          <img src="../../assets/images/img/menu05.png"/>
          <span>使用须知</span>
        </div>
        <i></i>
       </div>

       <div class="line-items mgt10" @click="toIntro('帮助中心','problem')">
        <div class="line-item">
          <img src="../../assets/images/img/menu06.png"/>
          <span>帮助中心</span>
        </div>
        <i></i>
       </div>

       <div class="line-items mgt10" @click="toIntro('产品介绍','intro')">
        <div class="line-item">
          <img src="../../assets/images/img/menu07.png"/>
          <span>产品介绍</span>
        </div>
        <i></i>
       </div>

       <!-- <div class="line-items mgt10" @click="toMenu('helpCenter')">
        <div class="line-item">
          <img src="../../assets/images/img/menu06.png"/>
          <span>常见问题</span>
        </div>
        <i></i>
       </div> -->

       <div class="line-items mgt10" @click="toIntro('关于我们','aboutUs')">
        <div class="line-item">
          <img src="../../assets/images/img/menu08.png"/>
          <span>关于我们</span>
        </div>
        <i></i>
       </div>
    </div>

    <img src="../../assets/images/img/beian.png" class="beian"/>
  </div>
</template>


<script type="text/ecmascript-6">
import {getIndexInfo} from "@/api/user"
import {findNextFlowThenJump,findNextFlowThenJump1} from "./findNextFlow"
export default {
  name: "",
  components: {},
  data() {
    return {
      name:'欢迎使用豆易链',
      btnName:'立即申请',
      btnDesc:'可在30天后再次申请',
      leagueInfo:{},
      isTip:false,
      loading:true,
      leagueAdmitIntervalDays:0,
      isUsebtn:false,
      isAdmitOver:false,
      totalLoanAmount:0,
    };
  },
  computed: {},
  created () {
  },
  filters:{
    formatCurrency(number) {
      if(number>0){
         // 将数字转换为字符串并去除小数点前两位之前的0
        const numberStr = number.toFixed(2).replace(/^0+/, '');
        // 使用正则表达式将每三个数字分隔
        const formatted = numberStr.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
        // 如果小数部分为00，则去除小数点和零
        return formatted.replace(/\.00$/, '');
      }
      return 0
    },
  },
  mounted(){
    // const { code , state} = this.$route.query
    // if(code){ 
    //   console.log(window.location.host,'域名',state)
    //   let host = state || (window.location.host.indexOf('test') > -1 ? 'xiaoai.test.jdz360.cn' : 'xiaoai.jdz360.cn')
    //   window.location.replace(`https://${host}/repayment/login?code=`+code)
    //   return
    // }
    this.getInfo();
  },
  methods: {
    toIntro(title,name){
     
      let imgUrl = this.staticConfig.isTest?`https://ech5.test.jdz360.cn/agreement/${name}.jpg?t=${Math.floor(Math.random() * 100)}`:`https://ech5.test.jdz360.cn/agreement//${name}.jpg?t=${Math.random()}`
    
      this.$router.push({name:'showImage',query:{
        imgUrl,
        title
      }})
    },
    toMenu(name){
      this.$router.push({name})
    },
    test(){
      this.$createToast({type: "warn",txt: `未出接口或页面UI，敬请期待`,time: 1500,}).show();
    },
    apply(){

      // this.toMenu('maintenance')
      // return
      const shouldJumpToNextFlow = this.isUsebtn && (  
          this.isAdmitOver ?findNextFlowThenJump1(this.leagueInfo.useLoanAmount||0) : findNextFlowThenJump  
      );  
 
      if (typeof shouldJumpToNextFlow === 'function') {  
          shouldJumpToNextFlow();  
      }  
    },
    getInfo(){
      getIndexInfo({token:''}).then(res=>{
        if(res.success){
          this.leagueInfo = res.data||{}
          const{leagueName,totalLoanAmount,useLoanAmount,leagueStepStatus,orderStepStatus,leagueAdmitIntervalDays} = res.data
          if(leagueName) this.name = leagueName
          this.totalLoanAmount = (totalLoanAmount||0)

          switch(leagueStepStatus){
            case 'WaitAllowAudit':this.btnName = '审批中';this.isUsebtn = true;break;
            case 'ArtificialAudit':this.btnName = '审批中';this.isUsebtn = false;break;
            case 'CommitAdmitOrder':this.btnName = '立即申请';this.isUsebtn = true;break;
            case 'AllowAuditRejected':
            case 'ArtificialAuditRejected':
              this.btnName = '重新申请';
              this.isUsebtn = (leagueAdmitIntervalDays==0);
              this.isTip=leagueAdmitIntervalDays>0;
              this.leagueAdmitIntervalDays = leagueAdmitIntervalDays||0
              this.btnDesc = `可在${leagueAdmitIntervalDays||0}天后再次申请`
              break;
            default:
              this.btnName = '继续申请';this.isUsebtn = true;
              break;
          }

          if(leagueStepStatus == 'AdmitOver'){

            switch (orderStepStatus) {
              case 'CommitApply': // 提交申请
              case 'UpdateShopInfo': //"更新店铺信息"
              case 'UpdateSettlementAccount': //"更改结算账户"
              case 'BindShopAccount': // "绑定店铺账号"
              case 'LoanFinish':
                this.btnName = '继续申请';
                this.isUsebtn = ((useLoanAmount>0))?true:false;
                this.isAdmitOver = true;
                break;

              case 'TransactionAudit': //"交易审批
                this.btnName = '审批中';
                this.isUsebtn = true //((totalLoanAmount - useLoanAmount>0)&&leagueAdmitIntervalDays==0)?true:false;
                this.isAdmitOver = true;
                break;
              
              case 'PlatformAudit': //"平台审批"
              case 'LoanAudit': //"资方审批"
              case 'PreAudit': 
                this.btnName = '审批中';
                this.isUsebtn =((useLoanAmount>0))?true:false;
                this.isAdmitOver = true;
                break;
              case 'TransactionAuditFail':
              case 'PlatformAuditFail':
              case 'LoanAuditFail':
                this.btnName = '再次申请';
                this.isUsebtn = (( useLoanAmount>0))?true:false;
                // this.leagueAdmitIntervalDays = leagueAdmitIntervalDays||0
                // this.btnDesc = `可在${leagueAdmitIntervalDays||0}天后再次申请`
                this.isAdmitOver = true;
                // this.isTip=leagueAdmitIntervalDays>0;
                break;
              default:
                this.btnName = '立即请款';
                this.isUsebtn = ((useLoanAmount>0))?true:false;
                // this.leagueAdmitIntervalDays = leagueAdmitIntervalDays||0
                // this.btnDesc = `可在${leagueAdmitIntervalDays||0}天后再次申请`
                // this.isTip=leagueAdmitIntervalDays>0;
                this.isAdmitOver = true;
                break;
            }
            
          }
          this.loading = false
        }
      })
    },
  }
};
</script>
<style scoped lang="less">
.container {
  background: #F5F7FB url("../../assets/images/img/index_bg.png") no-repeat;
  background-size: 100% auto;
  display: flex;
  flex-direction: column;
  .header-row{
    margin-top: 23px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
    .header-left{
      display: flex;
      align-items:center;
      img{
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        object-fit: cover;
      }
      span{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #FFFFFF;
        margin-left: 10px;
        display: inline-block;
        max-width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        
      }
    }
    .header-right{
      img{
        width: 32px;
        height: 32px;
        object-fit: cover;
        flex-shrink: 0;
      }
    }
  }
  .content{
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    margin-top: 28px;
    .main-box{
      width: 343px;
      height: 154px;
      background: #FFFFFF;
      padding: 16px;
      display: flex;
      flex-direction: column;
      border-radius: 8px 8px 8px 8px;
      .main-title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
      .main-box-content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
        .main-money{
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 38px;
          color: #333333;
        }
        .main-btn{
          display: block;
          width: 84px;
          height: 30px;
          background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
          border-radius: 30px 30px 30px 30px;
          position: relative;
          text-align: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 30px;
          &.btn5{
            opacity: 0.5;
          }
          i{
            position: absolute;
            bottom: -40px;
            right: 0;
            display: block;
            width:max-content;
            font-style:unset;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #999999;
          }
        }
      }
      .main-desc{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin-top: 35px;
      }
      .main-desc-2{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin-top: 35px;
        display: flex;
        align-items: center;
        span{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          margin-left: 9px;
          display: flex;
          align-items: center;
          color: #0099FF;
          i{
            display: inline-block;
            background: url("../../assets/images/img/blue_right.png") no-repeat;
            background-size: contain;
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    .broadcast{
      width: 343px;
      height: 50px;
      background: #FFFFFF;
      border-radius: 8px 8px 8px 8px;
      margin-top: 16px;
      display: flex;
      align-items: center;
      padding:0 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      i{
        display: block;
        background: url("../../assets/images/img/lb.png") no-repeat;
        background-size: contain;
        width: 18px;
        flex-shrink: 0;
        margin-right: 8px;
        height: 18px;
      }
      .broadcast-content{
        flex: 1;
        overflow: hidden;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        .broadcast-title{
          display: flex;
          align-items: center;
          white-space: nowrap;
          animation:slide 130s linear infinite;
          span{

            &:before{
              content:'';
              display: inline-block;
              width: 80px;
              height: 100%;
            }

          }
        }
        // div{
        //   position: absolute;
        //   white-space: nowrap;
        //   left:0;
        //   animation:slide 13s linear infinite; 
        //   &:before{
        //     content:'';
        //     display: inline-block;
        //     width: 200px;
        //     height: 100%;
        //   }
        // }
      }
    }
    .common-service{
      width: 343px;
      height: 124px;
      margin-top: 16px;
      background: #FFFFFF;
      padding: 16px;
      border-radius: 8px 8px 8px 8px;
      .common-service-title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
      .common-service-content{
        display: flex;
        align-items: center;
        margin-top: 20px;
        justify-content: space-between;
        .common-service-item{
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          img{
            width: 30px;
            height: 30px;
            object-fit: cover;
          }
          p{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            margin-top: 6px;
          }
        }
      }
    }
    .line-items{
      width: 343px;
      height: 46px;
      background: #FFFFFF;
      border-radius: 8px 8px 8px 8px;
      display: flex;
      align-items: center;
      padding:0 10px 0 17px;
      justify-content: space-between;
      &.mgt16{
        margin-top: 16px;
      }
      &.mgt10{
        margin-top: 10px;
      }
      .line-item{
        display: flex;
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        img{
          width: 28px;
          height: 28px;
          margin-right: 9px;
          object-fit: cover;
          flex-shrink: 0;
        }
      }
      i{
        display: block;
        width: 24px;
        height: 24px;
        background: url("../../assets/images/img/right_icon.png") no-repeat;
        background-size: contain;
      }
    }
  }
  .beian{
    width: 198px;
    height: auto;
    margin: 20px auto;
  }
}
@keyframes slide {
  from{
    transform: translate(0,0);
  }
  to{
    transform: translate(-100%,0);
  }
}
</style>
