var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.info
    ? _c(
        "div",
        { staticClass: "container", class: _vm.info.orderStatus },
        [
          _c("p", { staticClass: "apply-status" }, [
            _vm._v(_vm._s(_vm.info.orderStatusName))
          ]),
          _c("div", { staticClass: "apply-container" }, [
            _c("div", { staticClass: "line-item border-bottom-1px" }, [
              _c("span", { staticClass: "line-item-title" }, [
                _vm._v("请款单号")
              ]),
              _c("span", { staticClass: "line-item-content" }, [
                _vm._v(_vm._s(_vm.info.orderNo)),
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.info.orderNo,
                        expression: "info.orderNo",
                        arg: "copy"
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onCopy,
                        expression: "onCopy",
                        arg: "success"
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onError,
                        expression: "onError",
                        arg: "error"
                      }
                    ]
                  },
                  [_vm._v("复制")]
                )
              ])
            ]),
            _c("div", { staticClass: "line-item border-bottom-1px" }, [
              _c("span", { staticClass: "line-item-title" }, [
                _vm._v("店铺名称")
              ]),
              _c("span", { staticClass: "line-item-content" }, [
                _vm._v(_vm._s(_vm.info.shopName))
              ])
            ]),
            _c("div", { staticClass: "line-item border-bottom-1px" }, [
              _c("span", { staticClass: "line-item-title" }, [
                _vm._v("所属平台")
              ]),
              _c("span", { staticClass: "line-item-content" }, [
                _vm._v(_vm._s(_vm.info.platformType))
              ])
            ]),
            _c("div", { staticClass: "line-item border-bottom-1px" }, [
              _c("span", { staticClass: "line-item-title" }, [
                _vm._v("申请时间")
              ]),
              _c("span", { staticClass: "line-item-content" }, [
                _vm._v(_vm._s(_vm.info.applyTime))
              ])
            ]),
            _c("div", { staticClass: "line-item border-bottom-1px" }, [
              _c("span", { staticClass: "line-item-title" }, [
                _vm._v("请款金额")
              ]),
              _c("span", { staticClass: "line-item-content" }, [
                _vm._v("¥" + _vm._s(_vm.info.loanAmount || 0))
              ])
            ]),
            _c("div", { staticClass: "line-item border-bottom-1px" }, [
              _c("span", { staticClass: "line-item-title" }, [
                _vm._v("请款期限")
              ]),
              _c("span", { staticClass: "line-item-content" }, [
                _vm._v(_vm._s(_vm.info.loanDateLimit || 0) + "天")
              ])
            ]),
            _vm.info.loanName
              ? _c("div", { staticClass: "line-item border-bottom-1px" }, [
                  _c("span", { staticClass: "line-item-title" }, [
                    _vm._v("出借方")
                  ]),
                  _c("span", { staticClass: "line-item-content" }, [
                    _vm._v(_vm._s(_vm.info.loanName))
                  ])
                ])
              : _vm._e(),
            _vm.info.paySuccessTime
              ? _c("div", { staticClass: "line-item border-bottom-1px" }, [
                  _c("span", { staticClass: "line-item-title" }, [
                    _vm._v("放款时间")
                  ]),
                  _c("span", { staticClass: "line-item-content" }, [
                    _vm._v(_vm._s(_vm.info.paySuccessTime))
                  ])
                ])
              : _vm._e(),
            _vm.info.signatureContractList &&
            _vm.info.signatureContractList.length > 0
              ? _c("div", { staticClass: "line-item border-bottom-1px" }, [
                  _c("span", { staticClass: "line-item-title" }, [
                    _vm._v("服务合同")
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "line-item-content look",
                      on: {
                        click: function($event) {
                          _vm.isAgreenVisibleRpMask = true
                        }
                      }
                    },
                    [_vm._v("查看"), _c("i")]
                  )
                ])
              : _vm._e(),
            _vm._m(0),
            _vm.info.continueApplyStatus == "1"
              ? _c("div", { staticClass: "btn main" }, [_vm._v("继续申请")])
              : _vm._e(),
            _vm.info.afterAuditStatus == "1"
              ? _c(
                  "div",
                  {
                    staticClass: "btn two",
                    on: {
                      click: function($event) {
                        _vm.isConfromVisibleRpMask = true
                      }
                    }
                  },
                  [_vm._v("取消申请")]
                )
              : _vm._e()
          ]),
          _c(
            "transition",
            { attrs: { name: "cube-action-sheet-fade" } },
            [
              _c(
                "cube-popup",
                {
                  attrs: {
                    type: "repayment-confrim",
                    position: "bottom",
                    visible: _vm.isAgreenVisibleRpMask
                  },
                  on: {
                    "mask-click": function($event) {
                      _vm.isAgreenVisibleRpMask = false
                    }
                  }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "cube-action-sheet-move" } },
                    [
                      _c(
                        "div",
                        { staticClass: "apply-container" },
                        [
                          _c(
                            "div",
                            { staticClass: "line-item1 border-bottom-1px" },
                            [
                              _c(
                                "span",
                                { staticClass: "line-item-title1 hot" },
                                [_vm._v("点击查看服务合同")]
                              )
                            ]
                          ),
                          _vm._l(_vm.info.signatureContractList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "line-item1 border-bottom-1px"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "line-item-title1 blue",
                                    on: {
                                      click: function($event) {
                                        return _vm.open(
                                          item.templateName,
                                          item.viewUrl
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "《" + _vm._s(item.templateName) + "》"
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "btn main",
                              on: {
                                click: function($event) {
                                  _vm.isAgreenVisibleRpMask = false
                                }
                              }
                            },
                            [_vm._v("关闭")]
                          )
                        ],
                        2
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "cube-action-sheet-fade" } },
            [
              _c(
                "cube-popup",
                {
                  attrs: {
                    type: "repayment-confrim",
                    position: "center",
                    visible: _vm.isConfromVisibleRpMask
                  },
                  on: {
                    "mask-click": function($event) {
                      _vm.isConfromVisibleRpMask = false
                    }
                  }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "cube-action-sheet-move" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isConfromVisibleRpMask,
                              expression: "isConfromVisibleRpMask"
                            }
                          ],
                          staticClass: "confrim-content",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [
                          _c("p", { staticClass: "title" }, [_vm._v("提示")]),
                          _c("p", { staticClass: "desc" }, [
                            _vm._v("确定要取消本次申请吗？")
                          ]),
                          _c("div", { staticClass: "footer-box" }, [
                            _c(
                              "span",
                              {
                                staticClass: "btn two",
                                on: {
                                  click: function($event) {
                                    _vm.isConfromVisibleRpMask = false
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "btn main",
                                on: { click: _vm.cancel }
                              },
                              [_vm._v("确定")]
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-item border-bottom-1px" }, [
      _c("span", { staticClass: "line-item-title" }, [_vm._v("还款记录")]),
      _c("span", { staticClass: "line-item-content look" }, [
        _vm._v("查看"),
        _c("i")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }