var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("div", { staticClass: "login-holder" }, [
        _vm.status == 1 && _vm.weChatType
          ? _c("div", { staticClass: "form-box" }, [
              _vm._m(0),
              _vm.isBind == 1
                ? _c("p", { staticClass: "tip_message" }, [
                    _vm._v("你的帐号" + _vm._s(_vm.phone) + "请求登录系统")
                  ])
                : _vm._e(),
              _vm.isBind == 0
                ? _c("div", { staticClass: "message-box1" }, [
                    _vm._m(1),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.loginForm.mobile,
                          expression: "loginForm.mobile",
                          modifiers: { trim: true }
                        },
                        { name: "fixedInput", rawName: "v-fixedInput" }
                      ],
                      ref: "username",
                      staticClass: "fill-input",
                      attrs: {
                        placeholder: "手机号",
                        name: "username",
                        type: "number",
                        maxlength: "11",
                        oninput:
                          "if(value.length>11) value = value.slice(0,11)",
                        onkeyup: "this.value=this.value.replace(/\\D/g,'')",
                        tabindex: "1"
                      },
                      domProps: { value: _vm.loginForm.mobile },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.loginForm,
                            "mobile",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _vm.isBind == 0
                ? _c("div", { staticClass: "sms-box" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "fill-item " }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.loginForm.captchaValue,
                            expression: "loginForm.captchaValue"
                          },
                          { name: "fixedInput", rawName: "v-fixedInput" }
                        ],
                        key: "captchaValue",
                        ref: "captchaValue",
                        staticClass: "fill-input",
                        attrs: {
                          type: "number",
                          placeholder: "图形验证码",
                          name: "captchaValue",
                          oninput:
                            "if(value.length>4) value = value.slice(0,4)",
                          onkeyup: "this.value=this.value.replace(/\\D/g,'')",
                          tabindex: "3",
                          maxlength: "4"
                        },
                        domProps: { value: _vm.loginForm.captchaValue },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.loginForm,
                              "captchaValue",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c("img", {
                        staticClass: "ico",
                        attrs: { src: _vm.imgCode, alt: "" },
                        on: { click: _vm.getCaptcha }
                      })
                    ])
                  ])
                : _vm._e(),
              _vm.isBind == 0
                ? _c("div", { staticClass: "sms-box" }, [
                    _vm._m(3),
                    _c("div", { staticClass: "fill-item" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.loginForm.authCode,
                            expression: "loginForm.authCode"
                          },
                          { name: "fixedInput", rawName: "v-fixedInput" }
                        ],
                        key: "authCode",
                        ref: "authCode",
                        staticClass: "fill-input",
                        attrs: {
                          type: "number",
                          placeholder: "短信验证码",
                          name: "authCode",
                          oninput:
                            "if(value.length>6) value = value.slice(0,6)",
                          onkeyup: "this.value=this.value.replace(/\\D/g,'')",
                          tabindex: "3",
                          disabled: !_vm.showCode
                        },
                        domProps: { value: _vm.loginForm.authCode },
                        on: {
                          blur: function($event) {
                            _vm.smsCodetip = false
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.loginForm,
                              "authCode",
                              $event.target.value
                            )
                          }
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          }
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "get-code",
                          class: {
                            disabled: _vm.isDisableSend || !_vm.showCode
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.sendSmsCode($event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.sendSmsCodeText))]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.isBind == 0
                ? _c("p", { staticClass: "small_message" }, [
                    _vm._v("为了你的账号安全，首次授权登录需要短信验证")
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "btn-box",
                  style: _vm.isBind == 0 ? "" : "margin-top:100px"
                },
                [
                  _c(
                    "c-button",
                    {
                      staticClass: "submit-button",
                      attrs: { primary: true },
                      on: { click: _vm.confirmTap }
                    },
                    [_vm._v("确认登录")]
                  ),
                  _c(
                    "c-button",
                    {
                      staticClass: "submit-button white",
                      staticStyle: { "margin-left": "0" },
                      attrs: { light: true },
                      on: { click: _vm.canncelTap }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ])
          : _vm.weChatType
          ? _c("div", { staticClass: "success-box" }, [
              _vm.status == 2
                ? _c("img", {
                    staticClass: "resutl-icon",
                    attrs: { src: require("@assets/images/success.png") }
                  })
                : _c("img", {
                    staticClass: "resutl-icon",
                    attrs: { src: require("@assets/images/error.png") }
                  }),
              _c("p", [
                _vm._v(_vm._s(["", "", "已成功登录", "已取消登录"][_vm.status]))
              ]),
              _c("p", { staticClass: "tip" }, [
                _vm._v(
                  _vm._s(
                    [
                      "",
                      "",
                      "你已成功登录，可以在网页上开始操作",
                      "如需登录，请返回登录页重新发起"
                    ][_vm.status]
                  )
                )
              ])
            ])
          : _vm._e()
      ])
    : _c("c-spin", {
        staticClass: "loading-box",
        attrs: { spinning: true, tip: "Loading...", size: "large" }
      })
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-mian-box" }, [
      _c("img", {
        staticClass: "empty-img",
        attrs: { src: require("@/assets/images/main/empty.png"), alt: "" }
      }),
      _c("h1", [_vm._v("豆易链")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-container" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/icons/mine_02.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-container" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/icons/mine_01.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-container" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/icons/mine_01.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }