<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-10-30 10:25:23
 * @LastEditors: CZK
 * @LastEditTime: 2024-10-30 10:25:26
-->
<template>
  <div class="container" v-if="info">
    <div class="card">
      <img src="../../assets/images/img/user.png" class="card-logo"/>
      <p class="danwei">{{info.sourceName}}</p>
      <p class="money">+{{info.payTotal||0}}</p>

      <div class="line border-bottom-1px">
        <span class="title">交易卡号</span><span class="val">{{formatNumber(info.receiverBankAccount)}}</span>
      </div>

      <div class="line border-bottom-1px">
        <span class="title">交易时间</span><span class="val">{{info.transactionTime}}</span>
      </div>
      <div class="line border-bottom-1px">
        <span class="title">摘要</span><span class="val">{{info.abstractName}}</span>
      </div>
      <div class="line border-bottom-1px">
        <span class="title">对方账户</span><span class="val">{{formatNumber(info.sourceBankAccount)}}</span>
      </div>
      <div class="line border-bottom-1px">
        <span class="title">业务状态</span><span class="val">{{info.payStatus}}</span>
      </div>
      <div class="line border-bottom-1px">
        <span class="title">交易编码</span><span class="val">{{info.transactionNo}}</span>
      </div>
      <div class="btn main" v-if="info.electronicReceiptUrl" @click="window.open(info.electronicReceiptUrl)">查看电子回单</div>
    </div>
  </div>
</template>
<script>
  import{getReceivingTransactionDetail} from '@/api/user'
  export default {
    name: "transactionDetails",
    data() {
      return {
        info:null
      }
    },
    methods:{
      formatNumber(numStr) {
          if(!numStr) return
          return numStr.replace(/^(\d{4})(\d+)(\d{4})$/,"$1" + "*".repeat(numStr.length - 8) + "$3");
      },
      async getInfo(){
        const spbId = this.$route.query.spbId
        let res = await getReceivingTransactionDetail({token:'',spbId})
        if(res.success) this.info = res.data
      }
    },
    mounted(){
      this.getInfo()
    }
  }
</script>
<style lang="less" scoped>
  .container {
    width: 100%;
    background: #F5F7FB url(../../assets/images/img/mine_bg.png) no-repeat top center;
    .card{
      width: 343px;
      background: #FFFFFF;
      margin:22px auto 0;
      padding:0 11px 0;
      box-shadow: 0px 3 8px 0px rgba(35,96,170,0.1);
      border-radius: 8px 8px 8px 8px;
      display:flex;
      flex-direction: column;
      .small-line{
        padding:0 6px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        .title{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
        }
        .val{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 18px;
        }
      }
      .line{
        padding:0 6px;
        // margin-top: 14px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        .title{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          line-height: 20px;
        }
        .val{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          line-height: 18px;
        }

      }
      .date-line{
        padding:0 6px;
        margin-top: 14px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        .title{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 18px;
        }
        .val{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 18px;
          display:flex;
          align-items:center;
          img{
            width:24px;
            height:24px;
            object-fit:contain;
          }
        }
      }
      .money{
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 36px;
        color: #333333;
        text-align: center;
        margin-top: 8px;
        margin-bottom:12px;
        line-height: 45px;
      }
      .danwei{
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        text-align:center;
        color: #333333;
        margin-top: 10px;
        line-height: 18px;
      }
      .card-logo{
        width:40px;
        height:40px;
        object-fit:contain;
        margin:22px auto 0;
      }


      .btn{
        width: 321px;
        height: 42px;
        border-radius: 30px 30px 30px 30px;
        display:flex;
        align-items:center;
        justify-content:center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        &.main{
          background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
          margin:22px auto;
          color: #FFFFFF;
        }
        &.two{
          border: 1px solid #009DFF;
          margin-top:16px;
          color: #009DFF;
        }
      }
      
    }
    
  }
</style>