var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("img", {
        staticClass: "loading-gif",
        attrs: { src: require("../../assets/images/loading.gif") }
      }),
      _c("span", { staticClass: "title" }, [_vm._v("系统维护中")]),
      _c("span", { staticClass: "desc" }, [
        _vm._v(
          "有需求请留下您的【联系方式+企业名称+称呼]发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接~"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }