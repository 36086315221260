var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("img", { staticClass: "bg", attrs: { src: _vm.bg, alt: "" } }),
    _vm.type == 1
      ? _c("p", { staticClass: "tips" }, [
          _vm._v("正在进行审批，预计需要" + _vm._s(_vm.ms) + "秒，请稍等…")
        ])
      : _vm._e(),
    _vm.type == 2
      ? _c("div", [
          _c("p", { staticClass: "title" }, [_vm._v("综合评估不符合")]),
          _vm._m(0)
        ])
      : _vm._e(),
    _vm.type == 3
      ? _c("div", [
          _c("p", { staticClass: "title" }, [_vm._v("资料已提交")]),
          _vm._m(1),
          _c("img", {
            staticClass: "code",
            attrs: { src: require("../../assets/images/img/code_border.png") }
          }),
          _c("div", { staticClass: "btn", on: { click: _vm.back } }, [
            _vm._v("返回首页")
          ])
        ])
      : _vm._e(),
    _c("img", {
      staticClass: "beian",
      class: { fid: _vm.type < 3 },
      attrs: { src: require("../../assets/images/img/beian.png") }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "desc" }, [
      _vm._v("很遗憾！您本次申请未获得通过。"),
      _c("br"),
      _vm._v(" 请保持良好信用记录，30天后可再次申请。")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "desc" }, [
      _vm._v("请添加官方企业微信，"),
      _c("br"),
      _vm._v(" 以便核实申请信息以及获取专属服务等")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }