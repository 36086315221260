var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm._m(0),
      _c("div", { staticClass: "info-faren-main" }, [
        _vm._m(1),
        _c("div", { staticClass: "info-faren-content" }, [
          _c("div", { staticClass: "faren-form" }, [
            _c("div", { staticClass: "form-item border-bottom-1px yuan" }, [
              _c("label", [_vm._v("申请金额")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.info.loanAmount,
                    expression: "info.loanAmount"
                  }
                ],
                attrs: { type: "number", placeholder: "请输入" },
                domProps: { value: _vm.info.loanAmount },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.info, "loanAmount", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("收款账户")]),
              _c(
                "div",
                {
                  staticClass: "bank-box b",
                  on: {
                    click: function($event) {
                      _vm.info.receiveAccountId ? "" : _vm.addBank()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.info.receiveAccountId
                          ? _vm.banks[_vm.currentBankIndex].bankName +
                              "（" +
                              _vm.banks[_vm.currentBankIndex].bankNo.slice(-4) +
                              "）"
                          : "请选择"
                      ) +
                      "\n            "
                  )
                ]
              )
            ]),
            _c(
              "div",
              {
                staticClass: "form-item border-bottom-1px r",
                on: { click: _vm.selectTap }
              },
              [
                _c("label", [_vm._v("用  途")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.info.loanPurposeName,
                      expression: "info.loanPurposeName"
                    }
                  ],
                  attrs: { type: "text", placeholder: "请选择", readonly: "" },
                  domProps: { value: _vm.info.loanPurposeName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.info, "loanPurposeName", $event.target.value)
                    }
                  }
                })
              ]
            ),
            _c("div", { staticClass: "form-item border-bottom-1px day" }, [
              _c("label", [_vm._v("期  限")]),
              _c("span", [_vm._v(_vm._s(_vm.info.loanDatelimit))])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "info-faren-main" }, [
        _c("div", { staticClass: "info-faren-content" }, [
          _c("div", { staticClass: "faren-form" }, [
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("综合服务费")]),
              _c("span", [_vm._v(_vm._s(_vm.totalFee) + "%")])
            ])
          ])
        ]),
        _vm._m(2),
        _c(
          "div",
          { staticClass: "add-btn on", on: { click: _vm.commit } },
          [
            _vm.commiting ? _c("cube-loading") : _vm._e(),
            _c("span", [_vm._v("提交订单")])
          ],
          1
        )
      ]),
      _c(
        "transition",
        { attrs: { name: "cube-action-sheet-fade" } },
        [
          _c(
            "cube-popup",
            {
              attrs: {
                type: "repayment-confrim",
                position: "bottom",
                visible: _vm.isConfromVisibleRpMask
              },
              on: {
                "mask-click": function($event) {
                  _vm.isConfromVisibleRpMask = false
                }
              }
            },
            [
              _c("transition", { attrs: { name: "cube-action-sheet-move" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isConfromVisibleRpMask,
                        expression: "isConfromVisibleRpMask"
                      }
                    ],
                    staticClass: "confrim-content",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "header border-bottom-1px" }, [
                      _c("i", {
                        on: {
                          click: function($event) {
                            _vm.isConfromVisibleRpMask = false
                          }
                        }
                      }),
                      _vm._v("收款账户\n          ")
                    ]),
                    _c(
                      "div",
                      { ref: "scrollContainer", staticClass: "content" },
                      [
                        _vm._l(_vm.banks, function(item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "bank-box border-bottom-1px",
                              class: { on: _vm.currentBankIndex == index },
                              on: {
                                click: function($event) {
                                  return _vm.selectBank(index)
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.bankImg[item.bankName]
                                    ? _vm.bankImg[item.bankName]
                                    : _vm.bankImg["默认"]
                                }
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.bankName) +
                                    "（" +
                                    _vm._s(item.bankNo.slice(-4)) +
                                    "）"
                                )
                              ])
                            ]
                          )
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "bank-box border-bottom-1px",
                            on: { click: _vm.addBank }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/img/add_icon.png")
                              }
                            }),
                            _c("span", [_vm._v("添加新账户")])
                          ]
                        )
                      ],
                      2
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("申请借款")]),
      _c("p", { staticClass: "desc" }, [_vm._v("Hi，请填写以下资料吧~")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "info-faren-title-box border-bottom-1px" },
      [_c("p", { staticClass: "info-faren-title" }, [_vm._v("申请信息")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "agreement-box" }, [
      _c("i"),
      _c("p", [
        _vm._v("本人已阅读并同意\n              "),
        _c("a", [_vm._v("《电商平台注册账户ID和密码授权委托书》")]),
        _vm._v("、\n              "),
        _c("a", [_vm._v("《征信查询授权书》")]),
        _vm._v("、\n              "),
        _c("a", [_vm._v("《电商业务合作协议》")]),
        _vm._v("、\n              "),
        _c("a", [_vm._v("《监管账户和清分协议》")]),
        _vm._v("、\n              "),
        _c("a", [_vm._v("《保理合同》")]),
        _vm._v("、\n              "),
        _c("a", [_vm._v("《保证合同》")]),
        _vm._v("、\n              "),
        _c("a", [_vm._v("《隐私保护授权书》")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }