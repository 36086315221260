var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.info
    ? _c("div", { staticClass: "container" }, [
        _c("p", { staticClass: "title" }, [_vm._v("全部应还总额(元)")]),
        _c("p", { staticClass: "num" }, [
          _vm._v(_vm._s(_vm.info.principalBalanceTotal))
        ]),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "header border-bottom-1px" }, [
            _c(
              "span",
              {
                class: { on: _vm.index === 0 },
                on: {
                  click: function($event) {
                    return _vm.changeIndex(0)
                  }
                }
              },
              [_vm._v("未还账单")]
            ),
            _c(
              "span",
              {
                class: { on: _vm.index === 1 },
                on: {
                  click: function($event) {
                    return _vm.changeIndex(1)
                  }
                }
              },
              [_vm._v("已还账单")]
            )
          ]),
          _c(
            "div",
            { staticClass: "scroll-box" },
            [
              _vm._l(_vm.list, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item border-bottom-1px",
                    on: {
                      click: function($event) {
                        return _vm.toMenu("billingDetails", item.applyId)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "line" }, [
                      _c("span", { staticClass: "money" }, [
                        _vm._v("¥" + _vm._s(item.loanAmount || 0))
                      ]),
                      item.principalBalance
                        ? _c("span", { staticClass: "money1" }, [
                            _vm._v("¥" + _vm._s(item.principalBalance || 0))
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "line" }, [
                      _c("span", { staticClass: "date" }, [
                        _vm._v(_vm._s(item.signDate) + " 请款")
                      ]),
                      item.principalBalance
                        ? _c("span", { staticClass: "status" }, [
                            _vm._v("剩余本金")
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              }),
              _vm.list.length === 0 ? _c("empty") : _vm._e()
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }