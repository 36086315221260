/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2023-03-06 15:26:24
 * @LastEditors: CZK
 * @LastEditTime: 2024-09-20 16:05:36
 */
// let logo1 = require('@/assets/images/old-logo.png')
// let logo = require('@/assets/images/logo.png')

let dou_question_banner =  require('@/assets/images/dou_question_banner.png')
let question_banner =  require('@/assets/images/question_banner.png')


let host = window.location.hostname

let isTest = window.location.host.indexOf('test') > -1 || host == 'localhost' || host == '127.0.0.1' || host == '192.168.1.166'

let isDev =  host == 'localhost' || host == '127.0.0.1' || host == '192.168.1.166'



// 配置
let config ={
  lt:{ // 绿通配置
    // logo:logo1,
    name:'豆才派',
    customer:'豆才派客服',
    customer_url:'https://work.weixin.qq.com/kfid/kfc9df55e1ff2741ea4',
    fdd_params:'xafq_lt_h5',
    serveName:'豆才派',
    question_banner:dou_question_banner,
    serviceAgreement: `https://huodong.jdz360.cn/agreement/豆才派信用赊购服务协议-标准版20201012.html?ts=${+new Date()}`, // 正式服务协议
    testServiceAgreement: `http://huodong.test.jdz360.cn/agreement/豆才派信用赊购服务协议-标准版20201012.html?ts=${+new Date()}`, // 测试服务协议
    assignmentAgreement: `https://huodong.jdz360.cn/agreement/豆才派应收账款转让协议-教育项目版本20201012.html?ts=${+new Date()}`, // 正式转让协议
    testAssignmentAgreement: `http://huodong.test.jdz360.cn/agreement/豆才派应收账款转让协议-教育项目版本20201012.html?ts=${+new Date()}`, // 服务转让协议
    isAssignment:false,
    Privacy:`https://huodong.jdz360.cn/agreement/豆才派隐私政策 -20210120.html?ts=${+new Date()}`,
    conceal: `https://huodong.jdz360.cn/agreement/豆才派隐私保护及授权书 20210129)(1).html?ts=${+new Date()}`, // 正式隐私协议
    testConceal: `http://huodong.test.jdz360.cn/agreement/豆才派隐私保护及授权书 20210129)(1).html?ts=${+new Date()}`, // 测试隐私协议
    noSchoolStudentAgreement:`https://huodong.jdz360.cn/agreement/非在校学生声明.html?ts=${+new Date()}`, // 非在校学生声明
  },
  xiaoai:{
    // logo:logo,
    name:'小爱专属',
    fdd_params:'xiaoai_h5',
    customer:'豆乐派客服',
    serveName:'豆乐派',
    customer_url:'https://work.weixin.qq.com/kfid/kfcc6b80439596b1c82',
    question_banner:question_banner,
    serviceAgreement: `https://huodong.jdz360.cn/agreement/1-豆乐派服务协议20230518.html?ts=${+new Date()}`, // 正式服务协议
    testServiceAgreement: `http://huodong.test.jdz360.cn/agreement/信用赊购服务协议-标准版20201012.html?ts=${+new Date()}`, // 测试服务协议
    assignmentAgreement: `https://huodong.jdz360.cn/agreement/应收账款转让协议-教育项目版本20201012.html?ts=${+new Date()}`, // 正式转让协议
    testAssignmentAgreement: `http://huodong.test.jdz360.cn/agreement/应收账款转让协议-教育项目版本20201012.html?ts=${+new Date()}`, // 服务转让协议
    isAssignment:true,
    Privacy:`https://huodong.jdz360.cn/agreement/豆乐派隐私政策 -20210120.html?ts=${+new Date()}`,
    conceal: `https://huodong.jdz360.cn/agreement/隐私保护及授权书 20210129)(1).html?ts=${+new Date()}`, // 正式隐私协议
    testConceal: `http://huodong.test.jdz360.cn/agreement/隐私保护及授权书 20210129)(1).html?ts=${+new Date()}`, // 测试隐私协议
    noSchoolStudentAgreement:`https://huodong.jdz360.cn/agreement/非在校学生声明.html?ts=${+new Date()}`, // 非在校学生声明
  },
  xafq:{
    // logo:logo,
    name:'豆乐派',
    fdd_params:'xafq_h5',
    customer:'豆乐派客服',
    serveName:'豆乐派',
    customer_url:'https://work.weixin.qq.com/kfid/kfcc6b80439596b1c82',
    question_banner:question_banner,
    serviceAgreement: `https://huodong.jdz360.cn/agreement/1-豆乐派服务协议20230518.html?ts=${+new Date()}`, // 正式服务协议
    testServiceAgreement: `http://huodong.test.jdz360.cn/agreement/信用赊购服务协议-标准版20201012.html?ts=${+new Date()}`, // 测试服务协议
    assignmentAgreement: `https://huodong.jdz360.cn/agreement/应收账款转让协议-教育项目版本20201012.html?ts=${+new Date()}`, // 正式转让协议
    testAssignmentAgreement: `http://huodong.test.jdz360.cn/agreement/应收账款转让协议-教育项目版本20201012.html?ts=${+new Date()}`, // 服务转让协议
    isAssignment:true,
    Privacy:`https://huodong.jdz360.cn/agreement/豆乐派隐私政策 -20210120.html?ts=${+new Date()}`,
    conceal: `https://huodong.jdz360.cn/agreement/隐私保护及授权书 20210129)(1).html?ts=${+new Date()}`, // 正式隐私协议
    testConceal: `http://huodong.test.jdz360.cn/agreement/隐私保护及授权书 20210129)(1).html?ts=${+new Date()}`, // 测试隐私协议
    noSchoolStudentAgreement:`https://huodong.jdz360.cn/agreement/非在校学生声明.html?ts=${+new Date()}`, // 非在校学生声明
  },
  ech5:{
    // logo:logo,
    name:'豆乐派',
    fdd_params:'xafq_h5',
    customer:'豆乐派客服',
    serveName:'豆乐派',
    customer_url:'https://work.weixin.qq.com/kfid/kfcc6b80439596b1c82',
    question_banner:question_banner,
    serviceAgreement: `https://huodong.jdz360.cn/agreement/1-豆乐派服务协议20230518.html?ts=${+new Date()}`, // 正式服务协议
    testServiceAgreement: `http://huodong.test.jdz360.cn/agreement/信用赊购服务协议-标准版20201012.html?ts=${+new Date()}`, // 测试服务协议
    assignmentAgreement: `https://huodong.jdz360.cn/agreement/应收账款转让协议-教育项目版本20201012.html?ts=${+new Date()}`, // 正式转让协议
    testAssignmentAgreement: `http://huodong.test.jdz360.cn/agreement/应收账款转让协议-教育项目版本20201012.html?ts=${+new Date()}`, // 服务转让协议
    isAssignment:true,
    Privacy:`https://huodong.jdz360.cn/agreement/豆乐派隐私政策 -20210120.html?ts=${+new Date()}`,
    conceal: `https://huodong.jdz360.cn/agreement/隐私保护及授权书 20210129)(1).html?ts=${+new Date()}`, // 正式隐私协议
    testConceal: `http://huodong.test.jdz360.cn/agreement/隐私保护及授权书 20210129)(1).html?ts=${+new Date()}`, // 测试隐私协议
    noSchoolStudentAgreement:`https://huodong.jdz360.cn/agreement/非在校学生声明.html?ts=${+new Date()}`, // 非在校学生声明
  }
}


let configKey = null

// 处理本地调试的时候的配置
if(host == 'localhost' || host == '127.0.0.1' || host == '192.168.1.166'){
  configKey = 'xafq'
} else {
  configKey = window.location.hostname.split(".")[0]
}

// 因huodong域名的测试域名没有ssl证书 无法跨域访问 所以不配置测试服
export const staticConfig = {
  // logo: config[configKey].logo, // 平台logo
  name: config[configKey].name, // 平台名称
  fdd_params: config[configKey].fdd_params, // 法大大授权的type参数
  serviceAgreement: config[configKey].serviceAgreement, // 信用赊购服务协议-标准版20201012
  assignmentAgreement: config[configKey].assignmentAgreement, // 转让协议
  conceal: config[configKey].conceal, // 隐私协议
  question_banner: config[configKey].question_banner, // 常见问题banner
  isAssignment: config[configKey].isAssignment, // 是否显示转让同意书
  customer: config[configKey].customer, // 微信客服名称
  customer_url: config[configKey].customer_url, // 微信客服链接
  serveName:config[configKey].serveName, // 服务协议名称
  Privacy:config[configKey].Privacy, // 隐私政策
  noSchoolStudentAgreement:config[configKey].noSchoolStudentAgreement, // 非在校学生声明
  isTest,
  isDev
}
