var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "info-faren-main" }, [
      _c("div", { staticClass: "info-faren-title-box" }, [
        _vm._v("\n        请将店铺后台结算账户，更改为下述监管账户\n      ")
      ]),
      _c("div", { staticClass: "info-faren-content" }, [
        _c("div", { staticClass: "faren-form" }, [
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("账 户 名")]),
            _c("span", [_vm._v(_vm._s(_vm.info.accountName))])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px" }, [
            _c("label", [_vm._v("账 户 号")]),
            _c("span", [_vm._v(_vm._s(_vm.info.accountNo))])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px " }, [
            _c("label", [_vm._v("开 户 行")]),
            _c("span", [_vm._v(_vm._s(_vm.info.bankName))])
          ]),
          _c("div", { staticClass: "form-item border-bottom-1px " }, [
            _c("label", [_vm._v("所属支行")]),
            _c("span", [_vm._v(_vm._s(_vm.info.branchName))])
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "info-faren-main" }, [
      _vm._m(1),
      _c(
        "div",
        { staticClass: "add-btn on", on: { click: _vm.commit } },
        [
          _vm.commiting ? _c("cube-loading") : _vm._e(),
          _c("span", [_vm._v("已更改，下一步")])
        ],
        1
      )
    ]),
    _c("img", {
      staticClass: "beian fid",
      attrs: { src: require("../../assets/images/img/beian.png") }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("更改结算账户")]),
      _c("p", { staticClass: "desc" }, [_vm._v("Hi，请填写以下资料吧~")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-faren-content" }, [
      _c("div", { staticClass: "faren-form" }, [
        _c("div", { staticClass: "form-item border-bottom-1px" }, [
          _c("label", { staticClass: "b" }, [_vm._v("更改确认")])
        ]),
        _c("div", { staticClass: "form-item border-bottom-1px" }, [
          _c("span", [
            _vm._v(
              "更改完成后，请从店铺后台提现0.23元到上述账户，然后点击“下一步”。"
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }