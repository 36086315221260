/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-10-06 16:38:25
 * @LastEditors: CZK
 * @LastEditTime: 2024-10-06 17:26:57
 */
import axios from "axios";
import { JSEncrypt } from "jsencrypt";
import CryptoJS from "crypto-js";
import router from "@/router";
import store from "@/store";
import {
  transformRequestFn,
  paramsSerializer,
  handleErrorMsg,
} from "./request";
import { Toast } from "cube-ui";
import { RSA_PUBLIC_KEY, DEBUG, ENV_KEY } from "@/config/config";
import { freshToken } from "@/api/user";

// 创建一个axios实例
const service = axios.create({
  baseURL: '/xafqApi', // 你的API基础URL
  timeout: 60000, // 请求超时时间
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

const request =[]
const errorMsgMap = {
  "401": "错误代码401，登录超时，请重新登录",
  "403": "错误代码403，登录超时，请重新登录",
  "404": "错误代码404，Not Found",
  "500": "错误代码500，服务器错误",
};

// 封装一个用于上传文件的函数
// const service = (data) => {
//   const formData = new FormData();
//   for (let key in data.data) {
//     formData.append(key, data.data[key]); // 'file'是你API接受的字段名
//   }
//   return instance.post(data.url, formData); // '/upload'是你的上传接口路径
// };


service.interceptors.request.use((config) => {
  if (config.method == "get") {
    //console.log("method get");
    
    //请求参数添加当前时间
    config.params.sendTime = getServerTime();
    //请求参数添加资管Id
    config.params.manageId = getManageId();
    //请求类型
    config.params.req = getRequestType();
    //添加token
    addTokenIfNeed(config);

    //加签
    config.params.sign = getParamSign(config.params);
    console.log("加签后参数: ", config.params);

  } else if (config.method == "post") {
    //console.log("method post");

    //如果参数为String，则反序列化
    if (typeof(config.data) === "string") {
      // config.data = JSON.parse(config.data);
      console.log("chenyh post 反序列化 : ", config.data);
    }
    console.log("chenyh post 原始参数 : ", config.data);
    //请求参数添加当前时间
    config.data.append('sendTime', getServerTime());
    //请求参数添加资管Id
    config.data.append('manageId', getManageId());
    //请求类型
    config.data.append('req', getRequestType());
    //添加token
    addTokenIfNeed(config);

    //加签
    config.data.append('sign' , getParamSign(config.data));
    console.log("加签后参数: ", config.data);

  }
  return config;
}, err);


const err = (error) => {
  
  console.log("接口出错了=>",error)
  if (error.response) {
    const { statusText, status } = error.response;
    const errorCode = errorMsgMap[status];
    const fullPath = window.location.pathname + window.location.search
    const loginPath ='/repayment/login'
    if (errorCode) {
      // if (status === 403) {
      //   console.log("403 ===============");
      //   window.localStorage.setItem("token", "");
      //   store.commit('SET_ISLOGIN', false);
      //   //403跳转登录页
      //   console.log(window.location.pathname,'路由',window.location.search);
      //   // 不是登录页才缓存
      //   if(loginPath!=fullPath) window.localStorage.setItem('redirect',fullPath);
      //   router.replace({
      //     name: "login"
      //   });
      // } else if (status === 401) {
        
      //   const config = error.config;
      //   console.log("401 ===============",config.headers.NeedAuthorization != false);
        
      //   console.log(window.location.pathname,'路由',window.location.search);
      //   // 不是登录页才缓存
      //   if(loginPath!=fullPath) window.localStorage.setItem('redirect',fullPath);
        
      //   if (config.headers.NeedAuthorization != false) {
      //     if (!isRefreshing) {
      //       isRefreshing = true;
           
      //       return refreshToken()
      //         .then((res) => {
      //           let token = res.data;
      //           console.log("chenyh get a new token : " + token);
      //           //保存token
      //           window.localStorage.setItem("token", token);
      //           //第二次调url会拼上baseURL导致路径不对
      //           if (config.baseURL) {
      //             config.baseURL = "";
      //           }
      //           window.localStorage.setItem("token", "");
      //           store.commit('SET_ISLOGIN', false);
                
      //           router.replace({ // 重新登录需传递原始路径
      //             name: "login"
      //           });
      //           return service(config);
      //         })
      //         .catch((res) => {
      //           console.error("refreshtoken error =>", res);
      //           //window.location.href = "/repayment/login";
      //           window.localStorage.setItem("token", "");
      //           store.commit('SET_ISLOGIN', false);
                
      //           router.replace({ // 重新登录需传递原始路径
      //             name: "login"
      //           });
      //         })
      //         .finally(() => {
      //           isRefreshing = false;
      //         });
      //     } else {
      //       return new Promise((resolve) => {
      //         requests.push(() => {
      //           //config.headers["X-Token"] = token;
      //           resolve(service(config));
      //         });
      //       });
      //     }
      //   }
      // } else {
      //   Toast.$create({
      //     txt: errorCode,
      //     type: "error",
      //     time: 2200,
      //   }).show();
      // }
    } else {
      let tipText = (statusText=='null'||statusText==null)?'操作失败':statusText
      Toast.$create({
        txt: "error",
        type: `error ${tipText}`,
        time: 1200,
      }).show();
    }
  } else {
    Toast.$create({
      txt: "error",
      type: `error`,
      time: 1200,
    }).show();
  }
  return Promise.reject(error);
};

//chenyh 20201010
/**
 * 添加token
*/
function addTokenIfNeed(obj) {
  
  if (obj.method == "get") {
    if (obj.params.token == undefined) {
      //console.log("chenyh token is undefined ");
      //无token字段，忽略
    } else {
      //console.log("chenyh token is not undefined ");
      //有token字段，添加token
      // 优化token的取值 如果没值或'undefined'时直接赋空
      let cacheToken = window.localStorage.getItem("token")
      if (cacheToken && cacheToken !== 'undefined') {
       obj.params.token = cacheToken
      }else{
        obj.params.token = ''
      }
    }
  } else if (obj.method == "post") {
    // if (obj.data.token == undefined) {
    //   //console.log("chenyh token is undefined ");
    //   //无token字段，忽略
    // } else {
      //console.log("chenyh token is not undefined ");
      //有token字段，添加token
      // 优化token的取值 如果没值或'undefined'时直接赋空
      let cacheToken = window.localStorage.getItem("token")
      if (cacheToken && cacheToken !== 'undefined') {
        obj.data.append('token' , cacheToken)
      }else{
        obj.data.append('token', '')
      }
    // }
  }
}

/**
 * 排序
 */
function objKeySort(obj) {
  var newkey = Object.keys(obj).sort();
  var newObj = {};
  for (var i = 0; i < newkey.length; i++) {
    newObj[newkey[i]] = obj[newkey[i]];
  }
  return newObj;
}

/**
 * MD5并大写
*/
function getMd5Str(obj) {
  return CryptoJS.MD5(JSON.stringify(obj)).toString().toUpperCase();
}

/**
 * RSA
*/
function getRsaStr(obj, publicKey) {
  let encrypt = new JSEncrypt();
  if (null == publicKey) {
    //console.log("Rsa publicKey is null");
  } else {
    //console.log("Rsa publicKey is " + publicKey)
  }
  encrypt.setPublicKey(publicKey);
  return encrypt.encrypt(obj);
}

/**
 * 接口签名
 * 
*/
function getParamSign(obj) {
  //1.剔除sign字段
  delete obj.sign;
  //console.log("加签前参数: ", obj);

  //2.请求参数排序
  const sortData = objKeySort(obj);
  //console.log("chenyh 参数排序: ", sortData);

  //3.md5
  const md5Str = getMd5Str(sortData);
  //console.log("chenyh md5: ", md5Str);

  //4.RSA
  const rsaStr = getRsaStr(md5Str, RSA_PUBLIC_KEY);
  //console.log("chenyh RSA: ", rsaStr);

  return rsaStr;
}

/**
 * 获取本地与服务器的时间差
 * 
*/
function getTimeDiff() {
  const currentTimeDiff = window.localStorage.getItem("currentTimeDiff");
  //console.log("chenyh currentTimeDiff is " + currentTimeDiff);
  return null == currentTimeDiff ? 0 : Number(currentTimeDiff);
}

/**
 * 获取服务器时间
 * 
*/
function getServerTime() {
  return (Date.now() - getTimeDiff()).toString();
}

/**
 * 获取资管Id
 * 
*/
function getManageId() {
  // if (DEBUG) {
  //   return ENV_KEY[0].debugKey;
  // } else {
  //   return ENV_KEY[0].releaseKey;
  // }
  if (process.env.NODE_ENV === 'production') {
    return ENV_KEY[0].releaseKey;
  } else {
    return ENV_KEY[0].debugKey;
  }
}

/**
 * 请求类型
*/
function getRequestType() {
  return "h5";
}

export { service as fontAxios, getParamSign, getServerTime, addTokenIfNeed, getManageId };
