<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-09-30 13:55:01
 * @LastEditors: CZK
 * @LastEditTime: 2024-10-16 10:46:12
-->
<template>
  <div class="container">
      <div class="title-box">
        <p class="title">添加收款账户</p>
        <p class="desc">Hi，请填写以下资料吧~</p>
      </div>

      <div class="info-faren-main">
        <div class="info-faren-title-box">
          请添加资金方放款的收款账户
        </div>
        <div class="info-faren-content">

          <div class="faren-form">
            <div class="form-item border-bottom-1px">
              <label>账&nbsp;户&nbsp;名</label>
              <input type="text" v-model="info.name"  placeholder="请输入">
            </div>
            <div class="form-item border-bottom-1px">
              <label>账&nbsp;户&nbsp;号</label>
              <input type="text" v-model="info.bankNo"  placeholder="请输入">
            </div>
            <div class="form-item border-bottom-1px " @click="selectTap">
              <label>开&nbsp;户&nbsp;行</label>
              <div class="bank-box b"  :class="{on:info.banklistName}">{{info.banklistName||'请选择开户行'}}</div>
            </div>

            <div class="form-item border-bottom-1px " @click="selectCity">
              <label>所属城市</label>
              <div class="bank-box b"  :class="{on:info.cityName}">{{info.cityName||'请选择所属城市'}}</div>
            </div>

            <div class="form-item border-bottom-1px ">
              <label>所属支行</label>
              <div class="bank-box b" @click="selectTap1" :class="{on:info.openBranchName}">{{info.openBranchName||'请选择所属支行'}}</div>
            </div>
          </div>
        </div>

        <div class="add-btn on" @click="commit">
          <cube-loading v-if="commiting"></cube-loading>
          <span>确定</span>
        </div>
      </div>
      <img src="../../assets/images/img/beian.png" class="beian fid"/>
  </div>

</template>
<script type="text/ecmascript-6">
import{addIncomeAccount,getBankList,getOpenBranchList} from "@/api/user"
// import {} from ""
import store from "@/store";
export default {
  name: "receivingAccount",
  components: {
 
  },
  data(){
    return {
      commiting:false,
      currentBankIndex:0,
      info:{
        name:'',
        "bankNo": "",
        "banklistId": '',
          banklistName:'',
          openBranchName:'',
          cityCode:'',
          cityName:'',
        "openBranchId": '',
      },
      banks:[]
    }
  },
  methods: {
    async getBankList(){
      let res = await getBankList({token:''})
      if(res.success){
        // { text: '剧毒', value: '剧毒'}
        this.banks = (res.data||[]).map(item=>{return {text:item.bankName,value:item.bankId}})
      }
    },
    // async getOpenBranchList(value){
    //  let res = await getOpenBranchList({headOfficeName:value})
    // },
    selectTap1(){
      const {banklistName,cityCode} = this.info
      if(!banklistName) return this.$createToast({txt: '请先选择开户行',type: "error"}).show();
      if(!cityCode) return this.$createToast({txt: '请先选择所在城市',type: "error"}).show();

      this.$ls.set("bankInfo",JSON.stringify(this.info))
      setTimeout(()=>{
        this.$router.push({name:'SelectTheBranchYouBelongTo',query:{banklistName,cityCode}})
      },500)  


    },
   async commit(){
      if(this.commiting) return
      let _that = this
      function showToast(message) {  
        _that.$createToast({txt: message,type: "error"}).show();
      }  
        
      if (!this.info.name) {  
          showToast('请填写账户名');  
          return;  
      }  
        
      if (!this.info.bankNo) {  
          showToast('请填写账户号');  
          return;  
      }  
        
      if (!this.info.banklistId) {  
          showToast('请选择开户行');  
          return;  
      }  
        
      // if (!this.info.openBranchId) {  
      //     showToast('请填写所属支行');  
      //     return;  
      // }
      this.commiting = true
      let res = await addIncomeAccount({...this.info,token:''})
      this.commiting = false
      if(res.success){
        this.$ls.set("bankInfo",null)
        this.$router.back()
      }
    },
    selectTap(){
      this.$ls.set("bankInfo",JSON.stringify(this.info))
      setTimeout(()=>{
        this.$router.push({name:'selectOpenBlank'})
      },500)  
    },
    selectCity(){

      this.$ls.set("bankInfo",JSON.stringify(this.info))
      setTimeout(()=>{
        this.$router.push({name:'selectCity'})
      },500)  
    },
  },
  created(){
    const bankInfo = this.$ls.get("bankInfo")
    if(bankInfo){
      this.info  = JSON.parse(bankInfo)
      console.log(this.info,'..................')
    }else {
      this.$ls.set("bankInfo",JSON.stringify(this.info))
    }

    this.getBankList()
  }
}
</script>
<style lang="less" scoped>
input{
  all: unset;
}
.container{
width: 100%;
background:#F5F7FB url("../../assets//images/img/bg1.png") no-repeat;
background-size: contain;
display: flex;
flex-direction: column;

.beian{
  width: 198px;
  height: auto;
  display: block;
  margin: 20px auto;
  &.fid{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.title-box{
  margin-top: 27px;
  margin-left: 32px;
  .title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 18px;
  }
  .desc{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 22px;
    margin-top: 12px;
  }
}
.info-faren-main{
  width: 343px;
  // height: 500px;
  background: #FFFFFF;
  margin: 17px auto 0;
  border-radius: 8px 8px 8px 8px;
  .info-faren-title-box{
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 40px;
    padding-left: 17px;
    background: #FFFBE1;
    border-radius: 8px 8px 0px 0px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #FFA54C;
  }
  .agreement-box{
    display: flex;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 18px;
    margin: 16px 0;
    text-align: justify;
    a{
      color:#3399FF;
    }
    i{
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      flex-shrink: 0;
      background: url("../../assets/images/img/check_on.png") no-repeat;
      background-size: contain;
    }
  }
  .info-faren-title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
    color: #1C2023;
    line-height: 20px;
    &::after{
      content: '*';
      color:#FA5151;
    }
  }
  .info-faren-content{
    display: flex;
    justify-content: space-between;
  }
  .tips{
    width: 321px;
    height: 40px;
    background: #FFFBE1;
    border-radius: 8px 8px 8px 8px;
    padding: 10px 0 10px 6px;
    text-align: left;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin: 14px 0;
    color: #FFA54C;
  }
  .faren-form{
    width: 100%;
    padding:0 10px;
    .form-item1{
      width: 100%;
      display: flex;
      justify-content: space-between;
      min-height: 50px;
      label{
        width: 56px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 50px;
        margin-right: 32px;
      }
      .types-box{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 18px;
        margin-top: 16px;
        p{
          display: flex;
          margin-bottom: 11px;
        }
        i{
          display: inline-block;
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          background: url("../../assets/images/img/no.png") no-repeat;
          background-size: contain;
          margin-right: 10px;
          &.yes{
            background: url("../../assets/images/img/yes.png") no-repeat;
          }
        }
      }
    }
    .form-item{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      position: relative;
      .bank-box{
        // background: #F5F5F5;
        width: 232px;
        min-height: 38px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #C9C9C9;
        padding: 0 20px 0 6px;
        display: flex;
        line-height: 18px;
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        &.on{
          color: #333333;
        }
        &.b{
          &::before{
            content: '';
            width: 24px;
            height: 24px;
            background: url("../../assets/images/img/b_icon.png") no-repeat;
            background-size: contain;
            display: block;
            position: absolute;
            right: 0;
          }
        }
      }
      &.r{
        &::before{
          content: '';
          width: 24px;
          height: 24px;
          background: url("../../assets/images/img/right_icon.png") no-repeat;
          background-size: contain;
          display: block;
          position: absolute;
          right: 0;
        }
      }
      &.yuan{
        &::before{
          content: '元';
          position: absolute;
          right: 6px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
        }
      }
      &.day{
        &::before{
          content: '天';
          position: absolute;
          right: 6px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
        }
      }
      label{
        width: 70px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 20px;
        margin-right: 32px;
      }
      input{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        &::placeholder{
          color: #999999;
        }
      }
      span{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
    }
  }
  .add-btn{
    width: 321px;
    height: 42px;
    text-align: center;
    background: #CCCCCC;
    border-radius: 30px 30px 30px 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 42px;
    margin: 22px auto 22px;
    color: #FFFFFF;
    &.on{
      background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
      margin: 10px auto 16px;
    }
  }
}
}
.confrim-content{
  width: 100%;
  height: 374px;
  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
  padding: 0 12px;
  .header{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    position: relative;
    i{
      display: block;
      width: 24px;
      height: 24px;
      background: url("../../assets/images/img/×.png") no-repeat;
      background-size: contain;
      position: absolute;
      left: 10px;
    }
  }
  .content{
    width: 100%;
    height: calc(374px - 50px);
    overflow-y: scroll;
    .bank-box{
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #F5F5F5;
      padding: 0 12px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      &.on{
        font-weight: 600;
        position: relative;
        &::before{
          content: '';
          display: block;
          width: 24px;
          height: 24px;
          background: url("../../assets/images/img/gou.png") no-repeat;
          background-size: contain;
          position: absolute;
          right: 12px;
        }
      }
      img{
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
      span{
        text-align: left;
    }
  }
}


}
</style>