<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-10-11 15:30:22
 * @LastEditors: CZK
 * @LastEditTime: 2024-10-11 15:30:22
-->
<!--
 * @Descripttion: 用于风控审核流程耗时等待页面
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-03-13 15:37:32
 * @LastEditors: CZK
 * @LastEditTime: 2024-05-27 15:48:29
-->
<template>
  <div class="container">
    <img src="../../assets/images/loading.gif" class="loading-gif"/>
  </div>
</template>

<script type="text/ecmascript-6">
import { findNextFlowThenJump,findNextFlowThenJump1 } from "./findNextFlow.js";
export default {
  name: "",
  components: {},
  data() {
    return {

    };
  },
  computed: {},
  methods: {

  },
  mounted () {
    const {type} = this.$route.query;
    if(type == "1"){
      findNextFlowThenJump();
    }else{
      findNextFlowThenJump1();
    }
  },
  beforeDestroy() {

  }
};
</script>

<style scoped lang="less">
.container {
  .loading-gif{
    width: 150px;
    height: auto;
    display: block;
    margin: 60px auto 0;
  }
  .time-tip{
    text-align: center;
    margin: 60px 0 30px;
    color: #666;
    span{
      color: #579af1;
    }
  }
  .tips-small{
    font-size: 12px;
    margin-top: 30px;
    line-height: 1.5;
    color: #666;
  }
  .tips-title{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 20px 0 0;
    color: #666;
    &::before{
      content: "";
      display: block;
      width: 100px;
      height: 1px;
      margin-right: 10px;
      background: #666;
    }
    &::after{
      content: "";
      display: block;
      width: 100px;
      height: 1px;
      margin-left: 10px;
      background: #666;
    }
  }
    padding-top: 16px;
    .aboutus-pic {
        display: block;
        width: 100%;
        margin:0 auto 50px;
    }

    .w340 {
        width: 340px;
    }
}
</style>
