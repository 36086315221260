<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-12-13 11:48:03
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-13 11:56:59
-->
<template>
  <div class="container">
    <div class="title-box">
      <p class="title">企业开户</p>
      <p class="desc">请填写以下资料吧~</p>
    </div>

    <div class="info-faren-main">
      <div class="body-box">
        <div class="up-box">
          <img src="@/assets/images/img/step_2.png"/>
          <span class="t">经办人确认</span>
          <span class="d">当前操作需要经办人确认，请点击“获取<br/>验证码”，并回填接收到的短信验证码</span>
        </div>

        <div class="faren-form">
          <div class="form-item border-bottom-1px">
            <label>经&nbsp;办&nbsp;人</label>
            <span>广州市启蒙玩具有限公司</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>手&nbsp;机&nbsp;号</label>
            <span>6226********1234</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>验&nbsp;证&nbsp;码</label>
            <input type="text" placeholder="短信验证码" />
            <div>获取验证码</div>
          </div>
        </div>
      </div>


      <div class="add-btn on"  @click="commit">
          <cube-loading v-if="commiting"></cube-loading>
          <span>下一步</span>
        </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import OSSUpload from "@comp/Upload/OSSUpload";
import { resetImageBlob } from "@/utils/getOssImageBlobUrl";
// import fontCard from '@comp/fontCard/index'
import {findNextFlowThenJump} from "./findNextFlow"
import moment from "moment";
import {uploadIdCard,saveEnterpriseInfo,getEnterpriseAuthInfo,recognizeBusinessLicense} from "@api/user";
export default {
  name: "SupplementaryInformation",
  components: {
    // fontCard,
    OSSUpload
  },
  // 受益方式 1-直接或间接25%(含)以上 2-通过人事、财务等其他方对公司进行控制 3-高级管理员 4-法人或公司负责人 5-其他
  data(){
    return {
      form:{
        "address": "",
        "businessLicenseUrl": "",
        "creditCode": "",
        "enterpriseType": "",
        "foundTime": "",
        "name": "",
        interCode:"",
        "shortName": "",
        idCard:
          {
            uploadType: 1,
            bg: "font_bg.png",
            accept: "image/png,image/jpeg,image/gif,image/bmp",
            // accept: ".xls,.xlsx,.csv",
            title: "上传营业执照",
            multiple: false,
            files: []
          },
        
      },
      identify:false,
      commiting:false
    }
  },
  methods: {
    async getInfo(){
     let res =  await getEnterpriseAuthInfo({token:""})
     if(res.success){
      /**
       * 
        address
        : 
        "广东省惠来县前詹镇铭东管区铭东新村十横巷7之1号"
        backgroundIdCardUrl
        : 
        "https://testapi.fadada.com:8443/api/get_file.api?app_id=403133&v=2.0&timestamp=20241009182130&uuid=984ec13596ec4f158f3943ef1c46e356&msg_digest=RjI5RTk2NjFDOTg3MjBERDVCNTY2Mzg0RkI0MEQzRDNCQTY2ODJCNA=="
        expiresDate
        : 
        "2036-01-28"
        headPhotoUrl
        : 
        "https://testapi.fadada.com:8443/api/get_file.api?app_id=403133&v=2.0&timestamp=20241009182130&uuid=fc60d881346b44d596c905d27bb11963&msg_digest=QTk5OUNDNjdBQzk1RkZFMTE4NkY0NTQ4QUQwOTAxRkJCNEVERDkwNQ=="
        idCard
        : 
        "445224198903090962"
        issueAuthority
        : 
        "惠来县公安局"
        name
        : 
        "林喜旋"
        phone
        : 
        "15918648674"
        startDate
        : 
        "2016-01-28"
        type
        : 
        "1"
       */
      // let img = 'http://test-011.oss-cn-shenzhen.aliyuncs.com/app_file_prd_1237509448591884288_1728469748911.jpg'
        const {address,backgroundIdCardUrl,expiresDate,headPhotoUrl,idCard,issueAuthority,name,phone,startDate,type} = res.data
        let keyName = type=='1'?'faren':'lianxiren'
        this[keyName].phone = phone
        this[keyName].signIssued  = issueAuthority
        this[keyName].idCardNo = idCard
        this[keyName].name = name
        this[keyName].identityFrontUrl = headPhotoUrl 
        this[keyName].identityReverseUrl = backgroundIdCardUrl
        this[keyName].effectiveStartDate = startDate
        this[keyName].effectiveEndDate = expiresDate
        if(headPhotoUrl){
          let imgurl = headPhotoUrl.replace('http://test-011.oss-cn-shenzhen.aliyuncs.com','')
          let utl = await resetImageBlob(imgurl)
          this.$set(this[keyName].idCard[0].files, 0, utl)
        }
        if(backgroundIdCardUrl){
          let imgurl1 = backgroundIdCardUrl.replace('http://test-011.oss-cn-shenzhen.aliyuncs.com','')
          let utl1 = await resetImageBlob(imgurl1)
          this.$set(this[keyName].idCard[1].files, 0, utl1)
        }
        
     }
    },
    see(url){
      const d_img = require('../../assets/images/img/d-img.jpg')
      url=url||d_img
      this.$createImagePreview({
        imgs: [url]
      }).show()
    },
    getCompanyUrl(e,KeyName,index){
      const {filenameUrl}=e
      this[KeyName].businessLicenseUrl=filenameUrl
      this.identify= false
      this.upload()
    },
    async upload(){

      // console.log(new Date('2017-12-12'),'时间')
      // return
      if(!this.form.businessLicenseUrl) return
      const toast = this.$createToast({txt: '识别中...',type: "loading",mask:true,time:999});
      toast.show()
      let res =  await recognizeBusinessLicense({businessLicenseUrl:this.form.businessLicenseUrl,token:''})
      if(res.success){
        const{data} = res
        const {businessAddress,businessScope,companyForm,companyName,companyType,creditCode,issueDate,
          legalPerson,registeredCapital,registrationDate,title,validFromDate,validPeriod,validToDate} = data

          /**
           * address": "",
        "businessLicenseUrl": "",
        "creditCode": "",
        "enterpriseType": "",
        "foundTime": "",
        "name": "",
        "sendTime": "",
        "shortName": "",
           */

           this.form.address = businessAddress
           this.form.creditCode = creditCode
           this.form.enterpriseType = companyType
           this.form.foundTime = registrationDate
          //  this.form.foundTime = moment().format('YYYY-MM-DD')
           this.form.name = companyName
           this.identify= true
           setTimeout(() => {
            toast.hide()
           },1000)
        }
    },
    //法人 联系人
    async handleSuccess(e,KeyName,index){
      if(e.status == 'success'){
        const {filenameUrl}=e
        const toast = this.$createToast({txt: '识别中...',type: "loading",mask:true,time:999});
        toast.show()
        let res = await uploadIdCard({url:filenameUrl,token:''})
        toast.hide()
        if(res.success){
          const{data} = res
          if(data){
            const {address,birthDate,ethnicity,idNumber,issueAuthority,name,sex,type,validPeriod}=data
            if(type=='face'){
              this[KeyName].idCardNo = idNumber
              this[KeyName].name = name
              this[KeyName].identityFrontUrl = filenameUrl
            }else{
              const validPeriodDate = validPeriod.split('-')
              this[KeyName].effectiveStartDate = validPeriodDate[0].replace(/\./g,'-')
              this[KeyName].effectiveEndDate = validPeriodDate[1].replace(/\./g,'-')
              this[KeyName].signIssued = issueAuthority
              this[KeyName].identityReverseUrl = filenameUrl
            }
          }else{
            if(index==0){
              this[KeyName].idCardNo = ''
              this[KeyName].name = ''
              this[KeyName].identityFrontUrl = ''
            }else{
              this[KeyName].effectiveStartDate = ''
              this[KeyName].effectiveEndDate = ''
              this[KeyName].signIssued = ''
              this[KeyName].identityReverseUrl = ''
            }
            this.$createToast({txt: res.message,type: "error"}).show();
          }
        }else{
          this.$createToast({txt: '识别失败，请重试~',type: "error"}).show();
        }
        console.log(res,'识别结果')
      }else{
        this.$createToast({txt: '上传失败，请重试~',type: "error"}).show();
      }
    },
    async commit(){
      if(this.commiting) return;
      const{form}=this
      if(!form.businessLicenseUrl){
        return this.$createToast({txt: "请上传营业执照",type: "error"}).show();
      }

      if(!form.shortName){
        return this.$createToast({txt: "请输入企业简称",type: "error"}).show();
      }

      if(!form.interCode){
        return this.$createToast({txt: "请输入国际行业代码",type: "error"}).show();
      }

      const companyInfo =  Object.keys(form).filter(key => key !== 'idCard').reduce((acc, key) => {
        acc[key] = form[key];
        return acc;
      }, {});

      // //检查手机号的合法性
      // if(!/^1[34578]\d{9}$/.test(faren.phone)){
      //   return this.$createToast({txt: "请输入正确的联系电话",type: "error"}).show();
      // }

      // if(!faren.email){
      //   return this.$createToast({txt: "请输入法人电子邮箱",type: "error"}).show();
      // }


      // // 检查邮箱的合法性
      // if(!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(faren.email)){
      //   return this.$createToast({txt: "请输入正确的电子邮箱",type: "error"}).show();
      // }
      

     let res =  await saveEnterpriseInfo({
        ...companyInfo,token:''
      })

      if(res.success){
        findNextFlowThenJump()
      }
      this.commiting = false
    }
  },
  created(){
    // this.getInfo()
  }
}
</script>
<style lang="less" scoped>
input{
all: unset;
}
.container{
width: 100%;
background:#F5F7FB url("../../assets//images/img/bg1.png") no-repeat;
background-size: contain;
.agreement-box{
    display: flex;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 18px;
    margin: 16px 0;
    text-align: justify;
    a{
      color:#3399FF;
    }
    i{
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      flex-shrink: 0;
      background: url("../../assets/images/img/check_on.png") no-repeat;
      background-size: contain;
    }
  }
.title-box{
  margin-top: 27px;
  margin-left: 32px;
  .title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 18px;
  }
  .desc{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
   
    line-height: 22px;
    margin-top: 12px;
  }
}
.info-faren-main{
  width: 343px;
  // height: 500px;
  background: #FFFFFF;
  margin: 17px auto 0;
  border-radius: 8px 8px 8px 8px;
  // padding: 14px 12px 12px;
  display:flex;
  flex-direction: column;
  .upload-company-box{
    display:flex;
    flex-direction: column;
    align-items: center;
    // padding-bottom: 14px;
    .upload-company-btn{
      position: relative;
      width: 321px;
      height: 42px;
      display:flex;
      flex-direction: column;
      align-items: center;
      margin: 14px auto 14px;
      .ossupload{
        width:100%;
        height:100%;
        position:absolute;
        left:0;
        opacity: 0;
        top:0;
      }
      button{
        all:unset;
        width: 321px;
        height: 42px;
        text-align: center;
        background: #CCCCCC;
        border-radius: 30px 30px 30px 30px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 42px;
        color: #FFFFFF;
        &.on{
          background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
          margin: 0 auto 0;
        }
      }
    }
    .upload-company-show-image{
      width: 321px;
      height: 124px;
      border-radius: 8px 8px 8px 8px;
      border: 2px dashed #C0DBFF;
      display:flex;
      justify-content:center;
      align-items: center;
      .default-image{
        width:159px;
        height:107px;
        overflow:hidden;
        display:flex;
        justify-content:center;
        align-items: center;
        position:relative;
        .d{
          width:100%;
          height:100%;
          object-fit:cover;
        }
        .see-btn{
          display:flex;
          flex-direction: column;
          justify-content:center;
          align-items: center;
          position:absolute;
          img{
            width:40px;
            height:40px;
            object-fit:contain;
          }
          span{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #3399FF;
            line-height: 18px;
            margin-top:6px;
          }
        }
      }
    }
  }
  
  .tips{
    width: 100%;
    height: 60px;
    background: #FFFBE1;
    border-radius: 8px 8px 0px 0px;
    padding: 17px 12px;
    text-align: left;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #FFA54C;
    text-align: left;
    line-height:1.2;
  }
  .body-box{
    padding: 14px 12px 12px;
    .up-box{
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        width:183px;
        height:141px;
        object-fit:contain;
        margin-top: 8px
      }
      .t{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #333333;
        line-height: 19px;
        margin-top: 4px;
      }
      .d{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 18px;
        margin-top: 18px;
        text-align: center;
      }
    }
  }
  .info-faren-title-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 13px;
    .select-box{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      display: flex;
      line-height: 18px;
      span{display: flex;align-items: center;}
      i{
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("../../assets/images/img/no.png") no-repeat;
        background-size: contain;
        margin-left: 10px;
        margin-right: 10px;
        &.yes{
          background: url("../../assets/images/img/yes.png") no-repeat;
          background-size: contain;
        }
      }
    }
  }
  .info-faren-title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
    color: #1C2023;
    line-height: 20px;
    &.on{
      &::after{
        content: '*';
        color:#FA5151;
      }
    }
    
  }
  .info-faren-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    button{
      all:unset;
      width: 321px;
      height: 42px;
      text-align: center;
      background: #CCCCCC;
      border-radius: 30px 30px 30px 30px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 42px;
      margin: 12px auto 0;
      color: #FFFFFF;
      &.on{
        background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
        margin: 10px auto 16px;
      }
    }
    
  }
  // .tips{
  //   width: 321px;
  //   height: 40px;
  //   background: #FFFBE1;
  //   border-radius: 8px 8px 8px 8px;
  //   padding: 10px 0 10px 6px;
  //   text-align: left;
  //   font-family: PingFang SC, PingFang SC;
  //   font-weight: 500;
  //   font-size: 14px;
  //   margin: 14px 0;
  //   color: #FFA54C;
  // }
  .faren-form{
    margin-top: 20px;
    .form-item1{
      width: 100%;
      display: flex;
      justify-content: space-between;
      min-height: 50px;
      label{
        width: 70px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 50px;
        margin-right: 32px;
      }
      .empty-img{
        width: 50px;
        margin-left: 6px;
        height: 50px;
        img{
          width:100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .down{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #3399FF;
        line-height: 18px;
        display:flex;
        align-items: center;
        i{
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url("../../assets/images/img/blue_right.png") no-repeat;
          background-size: contain;
        }
      }
      .types-box{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 18px;
        margin-top: 16px;
        p{
          display: flex;
          margin-bottom: 11px;
        }
        i{
          display: inline-block;
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          background: url("../../assets/images/img/no.png") no-repeat;
          background-size: contain;
          margin-right: 10px;
          &.yes{
            background: url("../../assets/images/img/yes.png") no-repeat;
            background-size: contain;
          }
        }
      }
    }
    .form-item{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      div{
        flex-shrink: 0;
        white-space: nowrap;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 18px;
      }
      label{
        width: 56px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 20px;
        margin-right: 32px;
        
        &.on{
          position:relative;
          &::after{
            content: '*';
            position:absolute;
            right:-6px;
            top:0;
            color:#FA5151;
          }
        }
      }
      input{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        &::placeholder{
          color: #999999;
        }
      }
      span{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
    }
  }
  
}

.add-btn{
    width: 321px;
    height: 42px;
    text-align: center;
    background: #CCCCCC;
    border-radius: 30px 30px 30px 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 42px;
    margin: 12px auto 18px;
    color: #FFFFFF;
    &.on{
      background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
      margin: 10px auto 16px;
    }
  }


  .confrim-content{
    width: 316px;
    height: 178px;
    background: #FFFFFF;
    display:flex;
    flex-direction: column;
    border-radius: 8px 8px 8px 8px;
    .title{
      text-align:center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #3D3D3D;
      margin-top:22px;
    }
    .desc{
      text-align:center;
      font-family: PingFangSC-Medium, PingFangSC-Medium;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      padding: 0 45px;
      color: #353535;
      margin-top:14px;
    }
    .footer-box{
      margin-top:25px;
      display:flex;
      justify-content: space-around;
      .btn{
        width: 108px;
        height: 36px;
        text-align:center;
        line-height:36px;
        font-family: PingFangSC-Semibold, PingFangSC-Semibold;
        font-weight: 400;
        font-size: 16px;
        border-radius: 30px 30px 30px 30px;
        &.main{
          background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
          color: #FFFFFF;
        }
        &.two{
          border: 1px solid #009DFF;
          color:#009DFF;
        }
      }
    }
  }
}
</style>