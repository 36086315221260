const getters = {
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    nickname: state => state.user.name,
    welcome: state => state.user.welcome,
    userInfo: state => state.user.info,
    bankInfo: state => state.user.bankInfo,
    cacheView: state => state.cacheView.cacheView,
    isLogin: state => state.user.isLogin,
    leagueId: state => state.user.leagueId,
  }
  
  export default getters
  