/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2021-12-06 09:20:52
 * @LastEditors: CZK
 * @LastEditTime: 2024-06-04 11:17:31
 */
import Vue from "vue";
import App from "./App.vue";
import { getCustomH5Platform } from "@/utils/isDev";
import { staticConfig } from "@/utils/staticConfig";
import router from "./router";
import store from "./store";

import "@/assets/styles/index.less";

import "@/core/use";

import { VueAxios } from "@/utils/request";

import bootstrap from "@/core/bootstrap";
import "@/permission.js";


// import * as fundebug from "fundebug-javascript";
// import fundebugVue from "fundebug-vue";
// import "@/config/fundebugConfig";

// // bug监控
// if (process.env.NODE_ENV === "development") {
//   fundebugVue(fundebug, Vue);
// }
// // 视频bug回放
// require("fundebug-revideo");

import * as filters from "@/filters"; // global filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

console.log("当前运行环境", process.env);
/* eslint-disable no-unused-vars */
import VConsole from "vconsole";
/* eslint-disable */
if (process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development") {
  var vConsole = new VConsole();
}

// console.log('配置信息',staticConfig)
// 事件总线
const $EventBus = new Vue();

Object.defineProperties(Vue.prototype, {
  $bus: {
    get() {
      return $EventBus;
    }
  }
});

Vue.config.productionTip = false;
Vue.prototype.staticConfig = staticConfig


Vue.use(VueAxios, router);

new Vue({
  router,
  store,
  created() {
    document.title = staticConfig.name
    console.log("app create");
    bootstrap();
    window.customH5Platform = getCustomH5Platform();
  },
  render: (h) => h(App),
}).$mount("#app");
