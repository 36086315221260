/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-09-02 14:57:01
 * @LastEditors: CZK
 * @LastEditTime: 2024-09-23 09:42:56
 */
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import repaymentRouter from "./modules/repayment";



const pcWechatLogin = resolve => {
  import("@views/pcWechatLogin").then(module => {
    resolve(module);
  });
};

const NotFoundPage = resolve => {
  import("@views/NotFoundPage").then(module => {
    resolve(module);
  });
};

const TokenExpirePage = resolve => {
  import("@views/TokenExpirePage").then(module => {
    resolve(module);
  });
};

// import Blank from "@views/application/BlankPage";

export default new Router({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes: [
    // {
    //   path: "/",
    //   redirect: "/repayment"
    // },
    repaymentRouter,
    {
      path: "/pcWechatLogin",
      name: "pcWechatLogin",
      component: pcWechatLogin,
      meta: {
        title: "微信登录"
      }
    },
    {
      path: "/expire",
      name: "expire",
      component: TokenExpirePage,
      meta: {
        title: "登录失效"
      }
    },
    {
      path: "/404",
      name: "404",
      component: NotFoundPage,
      meta: {
        title: "页面不存在"
      }
    },
    // {
    //   path: "/blank",
    //   name: "blank",
    //   meta: { title: "空白页" },
    //   component: Blank
    // },
    { path: "*", redirect: "/404" }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
