<script>
import RouteView from "./RouteView";
import GuideButton from "../GuideButton";

export default {
  name: "GuideButtonRouterView",
  components: {
    GuideButton,
  },
  data() {
    return {};
  },
  computed: {
    showGuideButton() {
      return !["login", "mine", "mines", "xaIndex2"].includes(this.$route.name);
    },
  },
  render() {
    return (
      <div class="relative">
        {/** {this.showGuideButton ? <GuideButton /> : null}*/}
        <GuideButton
          style={{ display: this.showGuideButton ? "block" : "none" }}
        />
        <RouteView />
      </div>
    );
  },
};
</script>



