import axios from "axios";
import router from "@/router";
import store from "@/store";
import {
  transformRequestFn,
  paramsSerializer,
  handleErrorMsg,
} from "./request";
import { Toast } from "cube-ui";

import { getParamSign, getServerTime, addTokenIfNeed, getManageId } from "@/utils/requestXafqApp";

const service = axios.create({
  baseURL: "/xafqApi",
  timeout: 15000,
  withCredentials: true,
  transformRequest: [transformRequestFn],
  paramsSerializer,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

const errorMsgMap = {
  "401": "错误代码401，登录超时，请重新登录",
  "403": "错误代码403，登录超时，请重新登录",
  "404": "错误代码404，Not Found",
  "500": "错误代码500，服务器错误",
};

const err = (error) => {
  if (error.response) {
    const { statusText, status } = error.response;
    const errorCode = errorMsgMap[status];

    if (errorCode) {
      if (status === 403 || status === 401) {
        console.log("403 401===============");
        window.localStorage.setItem("token", "");
        //403跳转登录页
        store.commit('SET_ISLOGIN', false);
        router.replace({
          name: "login",
        });
      } else {
        Toast.$create({
          txt: errorCode,
          type: "error",
          time: 2200,
        }).show();
      }
    } else {
     let tipText = (statusText=='null'||statusText==null)?'操作失败':statusText
      Toast.$create({
        txt: "error",
        type: `error ${tipText}`,
        time: 1200,
      }).show();
    }
  } else {
    Toast.$create({
      txt: "error",
      type: `error`,
      time: 1200,
    }).show();
  }
  return Promise.reject(error);
};

service.interceptors.request.use((config) => {
  if (config.method == "get") {
    //console.log("method get");
    
    //请求参数添加当前时间
    config.params.sendTime = getServerTime();
    //请求参数添加资管Id
    config.params.manageId = getManageId();
    //添加token
    addTokenIfNeed(config);

    //加签
    config.params.sign = getParamSign(config.params);
    //console.log("加签后参数: ", config.params);

  } else if (config.method == "post") {
    //console.log("method post");

    //请求参数添加当前时间
    config.data.sendTime = getServerTime();
    //请求参数添加资管Id
    config.data.manageId = getManageId();
    //添加token
    addTokenIfNeed(config);

    //加签
    config.data.sign = getParamSign(config.data);
    //console.log("加签后参数: ", config.data);

  }
  return config;
}, err);

service.interceptors.response.use((response) => {
  const res = response.data.response || response.data;

  console.log("xafq ajax 返回: ", res);

  if (res.success === true) {
    return res;
  } else if (res.success === false) {

    if(!res.errMsg=='freshToken不能为空'){ //freshToken为空时不提示
      Toast.$create({
        txt: handleErrorMsg(res),
        type: "warn",
        time: 4000,
      }).show();
    }
    //其他状态
    window.localStorage.setItem("token", "");
    store.commit('SET_ISLOGIN', false);
    router.replace({
      name: "login",
    });
    return res;
  } else {
    // TODO 图片流 文件流捕获
    if (res instanceof Blob) {
      return res;
    }
    return Promise.reject(new Error(res.message || "Error"));
  }
}, err);

export { service as axiosss };
