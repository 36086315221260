<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-09-30 14:31:06
 * @LastEditors: CZK
 * @LastEditTime: 2024-10-17 14:41:47
-->
<template>
  <div class="container">
      <div class="title-box">
        <p class="title">绑定店铺账号</p>
        <p class="desc">Hi，请填写以下资料吧~</p>
      </div>

      <div class="info-faren-main">
        <div class="info-faren-title-box">
          请将店铺管理员及主账号手机号修改为下方手机号
        </div>
        <div class="info-faren-content">

          <div class="faren-form">
            <div class="form-item border-bottom-1px">
              <label>新手机号</label>
              <span>{{phoneInfo.phoneNum}}</span>
            </div>
          </div>
        </div>
      </div>


      <div class="info-faren-main">
        <div class="info-faren-content">
          <div class="faren-form">
            <div class="form-item border-bottom-1px">
              <span class="b">店铺后台绑定手机号验证码</span>
              
            </div>
            <div class="form-item border-bottom-1px">
              <label>验&nbsp;证&nbsp;码</label>
              <span class='btn'>获取验证码</span>
            </div>
          </div>
        </div>

        <div class="add-btn on" @click="commit">
          <cube-loading v-if="commiting"></cube-loading>
          <span>已绑定，下一步</span>
        </div>
      </div>

      
      <img src="../../assets/images/img/beian.png" class="beian fid"/>
  </div>

</template>
<script type="text/ecmascript-6">
import {bindShopAccount,getLatestAvailablePhone} from '@/api/user'
  import {findNextFlowThenJump1} from "./findNextFlow"
export default {
  name: "",
  components: {
 
  },
  data(){
    return {
      commiting:false,
      phoneInfo:{}
    }
  },
  methods: {
    async getLatestAvailablePhone(){
      let res = await getLatestAvailablePhone({token:''})
      if(res.success){
        this.phoneInfo = res.data||{}
      }
    },
    async commit(){
      if(this.commiting) return
      if(!this.phoneInfo.phoneNum) return  this.$createToast({txt: '暂无可用手机号,请联系客服',type: "error"}).show();
      this.commiting = true
      let res = await bindShopAccount({token:'',operationPhoneId:this.phoneInfo.id})
      if(res.success){
        findNextFlowThenJump1()
      }
      this.commiting = false
    }
  },
  mounted () {
    this.getLatestAvailablePhone()
  }
}
</script>
<style lang="less" scoped>
input{
  all: unset;
}
.container{
width: 100%;
background:#F5F7FB url("../../assets//images/img/bg1.png") no-repeat;
background-size: contain;
display: flex;
flex-direction: column;

.beian{
  width: 198px;
  height: auto;
  display: block;
  margin: 20px auto;
  &.fid{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.title-box{
  margin-top: 27px;
  margin-left: 32px;
  .title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 18px;
  }
  .desc{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 22px;
    margin-top: 12px;
  }
}
.info-faren-main{
  width: 343px;
  // height: 500px;
  background: #FFFFFF;
  margin: 17px auto 0;
  border-radius: 8px 8px 8px 8px;
  .info-faren-title-box{
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 40px;
    padding-left: 17px;
    background: #FFFBE1;
    border-radius: 8px 8px 0px 0px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #FFA54C;
  }
  .agreement-box{
    display: flex;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 18px;
    margin: 16px 0;
    text-align: justify;
    a{
      color:#3399FF;
    }
    i{
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      flex-shrink: 0;
      background: url("../../assets/images/img/check_on.png") no-repeat;
      background-size: contain;
    }
  }
  .info-faren-title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
    color: #1C2023;
    line-height: 20px;
    &::after{
      content: '*';
      color:#FA5151;
    }
  }
  .info-faren-content{
    display: flex;
    justify-content: space-between;
  }
  .tips{
    width: 321px;
    height: 40px;
    background: #FFFBE1;
    border-radius: 8px 8px 8px 8px;
    padding: 10px 0 10px 6px;
    text-align: left;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin: 14px 0;
    color: #FFA54C;
  }
  .faren-form{
    width: 100%;
    padding:0 10px;
    .form-item1{
      width: 100%;
      display: flex;
      justify-content: space-between;
      min-height: 50px;
      label{
        width: 56px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 50px;
        margin-right: 32px;
      }
      .types-box{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 18px;
        margin-top: 16px;
        p{
          display: flex;
          margin-bottom: 11px;
        }
        i{
          display: inline-block;
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          background: url("../../assets/images/img/no.png") no-repeat;
          background-size: contain;
          margin-right: 10px;
          &.yes{
            background: url("../../assets/images/img/yes.png") no-repeat;
          }
        }
      }
    }
    .form-item{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      position: relative;
      .bank-box{
        background: #F5F5F5;
        width: 232px;
        height: 38px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #C9C9C9;
        padding: 0 6px;
        display: flex;
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        &.b{
          &::before{
            content: '';
            width: 24px;
            height: 24px;
            background: url("../../assets/images/img/b_icon.png") no-repeat;
            background-size: contain;
            display: block;
            position: absolute;
            right: 0;
          }
        }
      }
      &.r{
        &::before{
          content: '';
          width: 24px;
          height: 24px;
          background: url("../../assets/images/img/right_icon.png") no-repeat;
          background-size: contain;
          display: block;
          position: absolute;
          right: 0;
        }
      }
      &.yuan{
        &::before{
          content: '元';
          position: absolute;
          right: 6px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
        }
      }
      &.day{
        &::before{
          content: '天';
          position: absolute;
          right: 6px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
        }
      }
      label{
        width: 70px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 20px;
        margin-right: 32px;
        &.b{
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #1C2023;
        }
      }
      input{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        &::placeholder{
          color: #999999;
        }
      }
      span{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #3D3D3D;
        line-height: 20px;
        &.btn{
          color:#3399FF;
          cursor: pointer;
        }
        &.b{
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #1C2023;
        }
      }
    }
  }
  .add-btn{
    width: 321px;
    height: 42px;
    text-align: center;
    background: #CCCCCC;
    border-radius: 30px 30px 30px 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    line-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 22px auto 22px;
    color: #FFFFFF;
    &.on{
      background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
      margin: 10px auto 16px;
    }
  }
}
}
.confrim-content{
  width: 100%;
  height: 374px;
  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
  padding: 0 12px;
  .header{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    position: relative;
    i{
      display: block;
      width: 24px;
      height: 24px;
      background: url("../../assets/images/img/×.png") no-repeat;
      background-size: contain;
      position: absolute;
      left: 10px;
    }
  }
  .content{
    width: 100%;
    height: calc(374px - 50px);
    overflow-y: scroll;
    .bank-box{
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #F5F5F5;
      padding: 0 12px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      &.on{
        font-weight: 600;
        position: relative;
        &::before{
          content: '';
          display: block;
          width: 24px;
          height: 24px;
          background: url("../../assets/images/img/gou.png") no-repeat;
          background-size: contain;
          position: absolute;
          right: 12px;
        }
      }
      img{
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
      span{
        text-align: left;
    }
  }
}


}
</style>