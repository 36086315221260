
<!--
 * @Autor: CPB
 * @Date: 2020-11-25 09:56:40
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-13 10:35:30
 * @Description: 默认用于阿里云直传方案, 通过action type 更改自定义上传
-->

<template>
  <div class="upload-container" :class="{on:classType}">

    <div class="upload-box-main" :class="{on:classType}">
      <div class='upload-box-btn-main'>
        <Upload
          ref="upload"
          class="upload-box"
          v-model="files"
          :action="action"
          :process-file="processFile"
          @files-added="addedHandler"
          @file-error="errHandler"
          :type="type"
          :successBg="successBg"
          @file-success="successHandler"
        >
          <div class="clear-fix upload-box-wrapper">
            <UploadFile
              :class="[{ 'multiple-upload-file': multiple }, 'upload-box-file']"
              v-for="(file, i) in files"
              :file="file"
              :type="type"
              :successBg="successBg"
              :key="i"
            ></UploadFile>
            <UploadBtn
              class="upload-box-btn"
              :style="{
                'background-image':
                  `url(` + require(`@/assets/images/img/${backgroundImgName}`) + ')',
                'background-size': 'contain'
              }"
              :multiple="multiple"
              :simultaneousUploads="simultaneousUploads"
              :accept="accept"
              :capture="capture"
            >
              <div></div>
            </UploadBtn>
          </div>
        </Upload>
      </div>
      <span class="uploading-tips" :class="{on:need==1}">{{title}}</span>
    </div>
    
  </div>
</template>

<script type="text/ecmascript-6">
import { Upload, UploadBtn, UploadFile } from "@/components/Upload";
import compress from "@/utils/compress";
import {staticConfig} from "@/utils/staticConfig";
export default {
  name: "",
  props: {
    type:{
      type: Number,
      default: 1,
    },
    need:{
      type:Number,
      default:0,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    classType:{
      type: Boolean,
      default: false,
    },
    // todo 上传框背景图，约定放在asset/images/下
    backgroundImgName: {
      type: String,
      default: "idcard-back.png",
    },
    successBg:{
      type:String,
      default:''
    },
    maxSize: {
      type: [String, Number],
      default: 300 * 1024 * 1024,
    },
    // 并发上传数
    simultaneousUploads: {
      type: Number,
      default: 1,
    },
    accept: {
      type: String,
      default: "image/*",
    },
    capture: {
      type: String,
    },
    title: {
      type: String,
      default() {
        return '';
      },
    },
    files: {
      type: Array,
      default() {
        return [];
      },
    },
    action: {
      type: Object,
      default() {
        return {
          // target: `/oss`,
          target:`${staticConfig.isDev?'/ossUpdata':'https://wfwloan-app.oss-cn-shenzhen.aliyuncs.com/'}`,
          // target:'https://wfwloan-app.oss-cn-shenzhen.aliyuncs.com/',
          // target:`http://test-011.oss-cn-shenzhen.aliyuncs.com`,
          type: "oss",
          headers: {},
          // 用于检验请求成功与否
          // eslint-disable-next-line no-unused-vars
          checkSuccess(res, file) {
            // if (res.success) {
            //   return true;
            // } else {
            //   return false;
            // }
            if (res.Status === "OK") {
              return true;
            } else {
              return false;
            }
          },
        };
      },
    },
  },
  components: {
    Upload,
    UploadBtn,
    UploadFile,
  },
  data() {
    return {};
  },
  computed: {
    typeStr() {
      console.log(this.successBg,'0000000000000')
      return this.type === 1? "image" : "video";
    }
  },
  methods: {
    vaildMaxSize(files) {
      let hasIgnore = false;
      const maxSize = this.maxSize;
      for (let k in files) {
        const file = files[k];

        if (file.size > maxSize) {
          file.ignore = true;
          hasIgnore = true;
        }
      }
      hasIgnore &&
        this.$createToast({
          type: "warn",
          time: 1000,
          txt: `请选择小于 ${this.maxSize / 1024 / 1024}M 文件`,
        }).show();
    },

    async addedHandler(files) {
      console.log("addedHandler", files);
      this.vaildMaxSize(files);

      if (!this.multiple) {
        const file = this.files[0];
        file && this.$refs.upload.removeFile(file);
      }
      this.$emit("update:files", this.files);
    },
    successHandler(file){
      console.log("successHandler", file);
      this.$emit("success", file);
    },

    errHandler(file) {
      console.log("errHandler", file);
      const { _xhr } = file;

      //   todo 阿里云错误返回xml string
      const responseText = _xhr.response || _xhr.responseText;

      const startIndex = responseText.indexOf("<Message>") + 9;
      const endIndex = responseText.indexOf("</Message>");
      const message = responseText.substring(startIndex, endIndex);

      this.$createToast({
        type: "warn",
        txt: `Upload fail : ${message}`,
        time: 2500,
      }).show();
    },
    processFile(file, next) {
      console.log("图片上传 == processFile", file.type);
      // console.log(navigator.userAgent, "navigator.userAgent");
      
      //不做压缩处理
      if(this.type==2||this.type==3){
         next(file);
      }else{
         if (!(navigator.userAgent.indexOf("CPU iPhone OS 10_") > -1)) {
          compress(
            file,
            {
              compress: {
                width: 1600,
                height: 1600,
                quality: 0.5
              },
              type: "image"
            },
            (file) => {
              console.log("已压缩", file);
              next(file)
            }
          );
          
        } else {
          console.log("未压缩", file);
          next(file);
        }
      }
     
    },
  },
  mounted() {
    
  },
};
</script>



<style scoped lang="less">
.upload-container{
  &.on{
    width:100%
  }
 
}
/deep/ .upload-box {
  padding: 0;
  width: 117px;
  height: 78px;
  .upload-box-file,
  .upload-box-btn {
    margin: 0px auto 0;
    height: 100%;
    width: 100%;
  }

  .upload-box-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
  }

  .upload-box-btn {
    background-repeat: no-repeat;
    background-size: 100% contain;
  }

  // 针对单个上传样式替换 上传btn定位
  .cube-upload-file {
    + .cube-upload-btn {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto !important;
      background-size: cover;
    }
  }

  .cube-upload-file-def {
    width: 100%;
    height: 100%;

    .cubeic-wrong {
      display: none;
    }
  }

  // 多选显示删除按钮 上传input 显示
  .multiple-upload-file {
    .cubeic-wrong {
      display: block;
      margin: 10px 10px;
      font-size: 20px;
      color: #2c3e50;
    }

    + .cube-upload-btn {
      opacity: 1 !important;
      position: relative !important;
    }
  }

  // btn插槽样式
  .cube-upload-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      text-align: center;
    }

    i {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      font-size: 16px;
      line-height: 1;
      font-style: normal;
      color: #fff;
      background-color: #ccc;
      border-radius: 50%;
      opacity: 0;
    }
  }
}

.upload-tips {
  margin-top: 10px;
  font-size: 12px;
  color: #999;
  span {
    margin-left: 3px;
    color: #579af1;
  }
}

.upload-box-main{
  width: 154px;
  height: 134px;
  border-radius: 8px 8px 8px 8px;
  border: 2px dashed #C0DBFF;
  &.on{
    width:100%;
    height: 100%;
    .upload-box-btn-main{
      width:100%;
      height: 100%;
      .upload-box {
        width:100%;
        height: 100%;
      }
    }
  }
  .upload-box-btn-main{
    width: 100%;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uploading-tips{
    width: 100%;
    text-align: center;
    height: calc(134px - 104px);
    display: block;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #3399FF;
    line-height: calc(134px - 104px);
    background: #DEEFFF;
    border-radius: 0px 0px 8px 8px;
    &.on{
      &::before{
        content: "*";
        color: #FA5151 ;
      }
    }
  }

}
</style>




