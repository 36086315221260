/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2021-12-06 09:20:52
 * @LastEditors: CZK
 * @LastEditTime: 2022-04-28 16:56:40
 */
import axios from "axios";
import router from "@/router";
import {
  transformRequestFn,
  paramsSerializer,
  handleErrorMsg,
} from "./request";
import { Toast } from "cube-ui";

const service = axios.create({
  baseURL: "/api",
  timeout: 20000,
  withCredentials: true,
  transformRequest: [transformRequestFn],
  paramsSerializer,
});

const errorMsgMap = {
  "401": "错误代码401，登录超时，请重新登录",
  "403": "错误代码403，登录超时，请重新登录",
  "404": "错误代码404，Not Found",
  "500": "错误代码500，服务器错误",
};

const err = (error) => {
  if (error.response) {
    const { statusText, status } = error.response;
    const errorCode = errorMsgMap[status];

    if (errorCode) {
      if (status === 401) {
        router.replace({
          name: "login",
        });
      } else {
        Toast.$create({
          txt: errorCode,
          type: "error",
          time: 2200,
        }).show();
      }
    } else {
      let tipText = (statusText=='null'||statusText==null)?'操作失败':statusText
      Toast.$create({
        txt: "error",
        type: `error ${tipText}`,
        time: 1200,
      }).show();
    }
  } else {
    Toast.$create({
      txt: "error",
      type: `error`,
      time: 1200,
    }).show();
  }
  return Promise.reject(error);
};

service.interceptors.request.use((config) => {
  return config;
}, err);

service.interceptors.response.use((response) => {
  const res = response.data.response || response.data;

  console.log("carFinance ajax 返回 ", res);

  if (res.success === true) {
    return res;
  } else if (res.success === false) {
    Toast.$create({
      txt: handleErrorMsg(res),
      type: "warn",
      time: 4000,
    }).show();
    return res;
  } else {
    // TODO 图片流 文件流捕获
    if (res instanceof Blob) {
      return res;
    }
    return Promise.reject(new Error(res.message || "Error"));
  }
}, err);

export { service as axios };
