var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.info
    ? _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "list-container" },
          [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "title" }, [
                _c("img", {
                  attrs: {
                    src: _vm.bankImg[_vm.info.receivingAccount.bankName]
                      ? _vm.bankImg[_vm.info.receivingAccount.bankName]
                      : _vm.bankImg["默认"]
                  }
                }),
                _vm._v(_vm._s(_vm.info.receivingAccount.accountName))
              ]),
              _c("p", { staticClass: "num" }, [
                _vm._v(
                  _vm._s(_vm.formatNumber(_vm.info.receivingAccount.bankNo))
                )
              ]),
              _c("p", { staticClass: "desc" }, [
                _vm._v(_vm._s(_vm.info.receivingAccount.openBranchName))
              ])
            ]),
            _vm._l(_vm.info.receivedRecordGroupList, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "card" },
                [
                  index === 0
                    ? _c("div", { staticClass: "title border-bottom-1px" }, [
                        _vm._v("\n        收款记录\n      ")
                      ])
                    : _vm._e(),
                  _c("p", { staticClass: "date" }, [
                    _vm._v(_vm._s(item.receivedDate))
                  ]),
                  _vm._l(item.receivedRecordList, function(jtem, jndex) {
                    return _c(
                      "div",
                      {
                        key: jndex,
                        staticClass: "card-item border-bottom-1px",
                        on: {
                          click: function($event) {
                            return _vm.toMenu("transactionDetails", jtem.spbId)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "line" }, [
                          _c("span", { staticClass: "title1" }, [
                            _vm._v(_vm._s(jtem.sourceName))
                          ]),
                          _c("span", { staticClass: "val" }, [
                            _vm._v("+" + _vm._s(jtem.payTotal || 0) + "元"),
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/img/right_icon.png"),
                                alt: ""
                              }
                            })
                          ])
                        ]),
                        _c("p", { staticClass: "line-date" }, [
                          _vm._v(_vm._s(jtem.successTime))
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            })
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }