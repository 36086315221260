/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2021-08-30 09:36:32
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-06 17:23:51
 */
import Vue from "vue";

import CryptoJS from 'crypto-js';

const secretKey = '20240110'; // 替换成你的密钥，保持安全

// 加密方法
export function encryptText(text) {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
}

// 解密方法
export function decryptText(ciphertext) {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}



/**
 * 借款token
 */

import { ACCESS_TOKEN } from "@/store/mutation-types";

export function getToken() {
  return Vue.ls.get(ACCESS_TOKEN);
}

export function setToken(token, maxAge = null) {
  return Vue.ls.set(ACCESS_TOKEN, token, maxAge);
}

export function removeToken() {
  return Vue.ls.remove(ACCESS_TOKEN);
}

/**
 * 付款登录
 */

const REPAYMENT_TOKEN = "Repayment-Access-Token";

export function getRepaymentToken() {
  return Vue.ls.get(REPAYMENT_TOKEN);
}

export function setRepaymentToken(token, maxAge = null) {
  return Vue.ls.set(REPAYMENT_TOKEN, token, maxAge);
}

export function removeRepaymentToken() {
  return Vue.ls.remove(REPAYMENT_TOKEN);
}

/**签约登录 */

const CERT_TOKEN = "cert-Access-Token";

export function getCertToken() {
  return Vue.ls.get(CERT_TOKEN);
}

export function setCertToken(token, maxAge = null) {
  return Vue.ls.set(CERT_TOKEN, token, maxAge);
}

export function removeCertToken() {
  return Vue.ls.remove(CERT_TOKEN);
}

// 微信授权登录
export  function  wxlogin(callbackurl,state) {
  let host = window.location.hostname
  let isTest = window.location.host.indexOf('test') > -1 || host == 'localhost' || host == '127.0.0.1' || host == '192.168.1.10'
  let appid = isTest ? 'wx4f9879d31db01e5b' :'wx0a6d42e99b2b1efd' //正式appid wx03e5a61c4b2f8498  测试appid wx4f9879d31db01e5b
  // let appid = 'wx03e5a61c4b2f8498'
  let redirectUri = callbackurl
  redirectUri = encodeURIComponent(redirectUri)

  window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + redirectUri + '&response_type=code&scope=snsapi_base&state='+state+'#wechat_redirect'
}