import baseRequest from "axios";

/**
 * ali oss 获取签名
 * @param {*} params
 */
export async function osspolicy(params) {
  return baseRequest({
    url: "/express_api/policy",
    method: "get",
    params,
    headers: {},
  });
}



