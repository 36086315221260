var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty" }, [
      _c("img", {
        staticClass: "empty-img",
        attrs: { src: require("../../assets/images/empty.png") }
      }),
      _c("span", [_vm._v("暂无记录")]),
      _c("img", {
        staticClass: "beian",
        attrs: { src: require("../../assets/images/img/beian.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }