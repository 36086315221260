/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2021-12-06 09:20:52
 * @LastEditors: CZK
 * @LastEditTime: 2024-10-11 10:42:29
 */
import router from "@/router";

import NProgress from "nprogress";
import "nprogress/nprogress.css";

import { Dialog } from "cube-ui";

NProgress.inc(0.2);
NProgress.configure({ easing: "ease", speed: 500, showSpinner: false });

import{checkEditInfo} from "@/api/user"
import store from "@/store";

const routerAllow = {
  realNameAuthentication: ["installmentLoan"],
  improveInformation: ["installmentLoan", "addBankCard", "result"],
  addBankCard: ["installmentLoan", "supportBank", "result"],
  result: ["installmentLoan", "result"],
  fillBaseInfo:["xaIndex2", "installmentLoan", "fillContactsInfo", "additionalInfo", "bindCard", "loanResult"],
  fillContactsInfo:["xaIndex2", "installmentLoan", "additionalInfo", "bindCard", "loanResult"],
  additionalInfo:["xaIndex2", "installmentLoan", "bindCard", "loanResult",'additionalInfo'],
  bindCard:["xaIndex2", "installmentLoan", "supportBank", "loanResult",'additionalInfo']
  //有则放行

};

const info=['loanDetails','fillBaseInfo','fillContactsInfo','additionalInfo','reBindCard'] //需要更换编辑信息提示的路由
router.beforeEach((to, from, next) => {
  console.log(to,'----',from)
  NProgress.start();
  const routerAllowArray = routerAllow[from.name];
  let u = navigator.userAgent;
  let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  // XXX: 修复iOS版微信HTML5 History兼容性问题
  // console.log(location.pathname,'--------',to.path)
  if (isiOS && to.path !== location.pathname) {
    // 此处不可使用location.replace
    location.assign(to.fullPath)
    return
  }
  // 如果是pc端来授权直接放过
  if(to.name==='pcWechatLogin') {
    next()
    return
  }
  
  
  if (
    !routerAllowArray ||
    (routerAllowArray && routerAllowArray.includes(to.name))
  ) {
    
    if(to.meta && to.meta.keepAlive) {
      store.commit('ADD_CACHE_VIEW', to)
      // store.dispatch('addCachedView', to)
    }
    next();
  } else {
    if(store.state.user.isFinish){ //如果是提交完成跳转则不进行流程判断
      next();
      store.state.user.isFinish=false;
      return
    }
    next();
    NProgress.done();
    // let title='',desc='';
    // let inArr=['fillBaseInfo','fillContactsInfo']
    // if(inArr.includes(from.name)){
    //   checkEditInfo({token:''}).then(res=>{
    //     if(res.data.checkEdit == '1'){
    //       if(info.includes(from.name)){
    //         title='您确定离开编辑信息？'; desc="离开将返回上一页，取消将继续编辑信息"
    //       }
      
    //       Dialog.$create({
    //         type: "confirm",
    //         icon: "cubeic-alert",
    //         title: title||"您确定离开申请流程吗？",
    //         content: desc||"离开将返回首页,继续点击申请按钮将恢复申请流程",
    //         confirmBtn: {
    //           text: "是",
    //           active: true,
    //           disabled: false,
    //           href: "javascript:;",
    //         },
    //         cancelBtn: {
    //           text: "否",
    //           active: false,
    //           disabled: false,
    //           href: "javascript:;",
    //         },
    //         onConfirm: () => {
    //           if(info.includes(from.name)){
    //             next()
    //             router.back()
    //           } else next({ name: "xaIndex2" });
              
    //         },
    //         onCancel: () => {},
    //       }).show();
    //     }else{
    //       next()
    //     }
    //   })
    // }else{
    //   if(info.includes(from.name)){
    //     title='您确定离开编辑信息？'; desc="离开将返回上一页，取消将继续编辑信息"
    //   }
  
    //   Dialog.$create({
    //     type: "confirm",
    //     icon: "cubeic-alert",
    //     title: title||"您确定离开申请流程吗？",
    //     content: desc||"离开将返回首页,继续点击申请按钮将恢复申请流程",
    //     confirmBtn: {
    //       text: "是",
    //       active: true,
    //       disabled: false,
    //       href: "javascript:;",
    //     },
    //     cancelBtn: {
    //       text: "否",
    //       active: false,
    //       disabled: false,
    //       href: "javascript:;",
    //     },
    //     onConfirm: () => {
    //       if(info.includes(from.name)){
    //         next()
    //         router.back()
    //       } else next({ name: "xaIndex2" });
          
    //     },
    //     onCancel: () => {},
    //   }).show();
    // }
  }
});

router.afterEach(() => {
  NProgress.done();
});
