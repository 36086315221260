export const BASEURL = '/apis';

export const faceRedirectUrl = 'https://api.megvii.com/faceid/lite/do?token='

export const TOKEN_REPAYMENT_NAME = 'token'

export const APP_VERSION = '1.0.1';    //软件版本

export const RSA_PUBLIC_KEY = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDlGgksV3nm/D/MyHhNFLpybcg6XXUR+CqWV+kvbAcVZfL1InCL1OROBgWvvyDYSRfKhqaDycICOrE7bLKCsTH5EqIGRrlh/155rPZFy7eixOKeUd847t2eKqox+7Krx4dmfvClvc4PE+v6ekCs9P3VeSvk54XTvABSVVT8VJgwQQIDAQAB';

export const DEBUG = true;

export const ENV_KEY = [
    {name : 'GUOTOU', debugKey : '609548695230365696', releaseKey : '633133943050354688'}
];



