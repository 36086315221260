var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "info-faren-main" }, [
      _c("div", { staticClass: "tips" }, [
        _vm._v("请上传以下照片或文件，照片要求清晰无反光，文件小于10M。")
      ]),
      _c(
        "div",
        { staticClass: "info-faren-content" },
        _vm._l(_vm.filesArray, function(item, index) {
          return _c("OSSUpload", {
            key: index,
            ref: "upload1",
            refInFor: true,
            staticStyle: { "margin-bottom": "14px" },
            attrs: {
              type: item.uploadType,
              backgroundImgName: item.bg,
              accept: item.accept,
              need: item.need,
              successBg: item.successBg,
              title: item.title,
              files: item.files,
              multiple: item.multiple
            },
            on: {
              success: function(e) {
                return _vm.handleSuccess(e, index)
              },
              "update:files": function($event) {
                return _vm.$set(item, "files", $event)
              }
            }
          })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "add-btn on", on: { click: _vm.commit } },
        [
          _vm.commiting ? _c("cube-loading") : _vm._e(),
          _c("span", [_vm._v("下一步")])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("补充资料")]),
      _c("p", { staticClass: "desc" }, [_vm._v("Hi，请填写以下资料吧~")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }