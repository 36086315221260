import { render, staticRenderFns } from "./puzzleVerification.vue?vue&type=template&id=bbe0fec0&scoped=true&"
import script from "./puzzleVerification.vue?vue&type=script&lang=js&"
export * from "./puzzleVerification.vue?vue&type=script&lang=js&"
import style0 from "./puzzleVerification.vue?vue&type=style&index=0&id=bbe0fec0&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbe0fec0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/workspace/ecommerce_h5_dev_front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('bbe0fec0', component.options)
    } else {
      api.reload('bbe0fec0', component.options)
    }
    module.hot.accept("./puzzleVerification.vue?vue&type=template&id=bbe0fec0&scoped=true&", function () {
      api.rerender('bbe0fec0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/vue-puzzle-verification/src/puzzleVerification.vue"
export default component.exports